import {
  Component,
  ElementRef,
  EventEmitter,
  HostListener,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
  SimpleChanges,
  ViewChild,
} from '@angular/core';
import { DocumentViewerFileTypes } from 'src/app/shared/utils';
import { DocumentService } from 'src/app/core/services/document.service';
import { timer } from 'rxjs';
import { GonativeService } from 'src/app/core/services';
import { ModalComponent } from '../modal/modal.component';
import { ModalConfig } from '../modal/modal.model';

export interface DocumentViewerConfig {
  name: string;
  key: string;
  fileType: DocumentViewerFileTypes | string;
}

@Component({
  selector: 'app-document-viewer',
  templateUrl: './document-viewer.component.html',
  styleUrls: ['./document-viewer.component.scss'],
})
export class DocumentViewerComponent implements OnInit, OnChanges, OnDestroy {
  @ViewChild('headerSlotRef') headerSlot: ElementRef;
  hasHeaderSlotContent = false;

  @ViewChild('modal') private readonly modalComponent: ModalComponent;
  @ViewChild('iframePrintContainer') private readonly iframePrintContainer: ElementRef;

  @Input() config: DocumentViewerConfig;
  @Input() isOpen = false;
  @Output() isOpenChange = new EventEmitter<boolean>();
  @Output() onClose = new EventEmitter();

  isMobileView = false;
  isNative = this.gonativeService.detectIfNativeApp();
  url: string;
  blobUrls: string[] = [];

  documentViewerFileTypes = DocumentViewerFileTypes;
  modalConfig: ModalConfig;

  constructor(private readonly globalDocsService: DocumentService, private readonly gonativeService: GonativeService) {}

  @HostListener('window:resize', ['$event'])
  onResize(event) {
    // Bootstrap's md devices below
    this.isMobileView = event.target.innerWidth <= 768;
  }

  ngOnInit(): void {
    this.modalConfig = {
      name: 'Document Viewer Modal',
      hideHeader: true,
      modalDialogClass: 'document-viewer-modal',
      beforeClose: async () => {
        this.revokeBlobs(this.blobUrls);
        this.isOpen = false;

        this.isOpenChange.emit(this.isOpen);
        this.onClose.emit();
        return true;
      },
    };

    window.dispatchEvent(new Event('resize'));
  }

  ngOnChanges(changes: SimpleChanges): void {
    const { isOpen, config } = changes;

    if (config?.currentValue) {
      this.configureViewer(config.currentValue);
    }

    if (isOpen?.currentValue !== undefined) {
      if (isOpen?.currentValue) {
        this.modalComponent?.open();
      } else {
        this.modalComponent?.close();
      }
    }

    timer(500).subscribe(() => {
      this.hasHeaderSlotContent = this.headerSlot?.nativeElement?.childNodes.length > 1;
    });
  }

  ngOnDestroy(): void {
    this.iframeCleanup();
  }

  configureViewer(config: DocumentViewerConfig) {
    const { key } = config;
    this.url = `/static/${key}`;
  }

  onCloseBtnClick() {
    this.modalComponent.close();
  }

  onPdfInit() {
    window.dispatchEvent(new Event('resize'));
  }

  onDownloadClick() {
    const { key, name } = this.config;
    this.globalDocsService.download(key, name).subscribe();
  }

  onPrintClick() {
    this.globalDocsService
      .print(this.config.key, this.iframePrintContainer.nativeElement, this.isMobileView)
      .subscribe((blobUrl) => {
        this.blobUrls.push(blobUrl);
      });
  }

  iframeCleanup() {
    this.removeIframePrintContainer();
    this.revokeBlobs(this.blobUrls);
  }

  removeIframePrintContainer() {
    const iframeEl = this.iframePrintContainer?.nativeElement;
    if (iframeEl && iframeEl.parentNode === document.body) {
      this.iframePrintContainer.nativeElement.onload = null;
      document.body.removeChild(iframeEl);
    }
  }

  revokeBlobs(blobUrls: string[]) {
    if (!blobUrls.length) {
      return;
    }

    this.blobUrls.forEach((url) => URL.revokeObjectURL(url));
    this.blobUrls = [];
  }
}
