import { Component, EventEmitter, Input, OnDestroy, OnInit, Output, SimpleChanges } from '@angular/core';
import { interval, Subject, timer, race } from 'rxjs';
import { switchMap, takeUntil, takeWhile } from 'rxjs/operators';
import { AutoUnsubscribe } from 'ngx-auto-unsubscribe';
import { OnboardingService } from 'src/app/core/services';

@AutoUnsubscribe()
@Component({
  selector: 'app-verification-link-sent',
  templateUrl: './verification-link-sent.component.html',
  styleUrls: ['../prove-verification-modal/prove-verification-modal.component.scss'],
})
export class VerificationLinkSentComponent implements OnInit, OnDestroy {
  @Output() onSkipToManualEntry = new EventEmitter();
  @Output() onSuccess = new EventEmitter();
  @Input() phoneEnding: string;
  @Input() session: string;

  private destroy$ = new Subject<void>();
  private maximumDuration$ = timer(300000); // Timer that completes after 5 minutes

  constructor(private readonly onboardingService: OnboardingService) {}

  ngOnInit(): void {
    if (this.session) {
      const polling$ = interval(5000).pipe(
        switchMap(() => this.onboardingService.getOnboardingStatus(this.session)),
        takeWhile((data: any) => data.status !== 'verified', true)
      );

      race(polling$, this.maximumDuration$)
        .pipe(takeUntil(this.destroy$))
        .subscribe({
          next: (data: any) => {
            if (data && data.status === 'verified') {
              this.onSuccess.emit();
            }
          },
          complete: () => {
            this.destroy$.next(); // Complete the destroy$ subject to clean up
            this.destroy$.complete();
          },
        });
    }
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }

  ngOnChanges(changes: SimpleChanges): void {}
}
