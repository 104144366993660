import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { HelpCenterRoutingModule } from './help-center-routing.module';
import { HelpCenterComponent } from './help-center.component';
import { CallSupportComponent } from './call-support/call-support.component';
import { GiveFeedbackComponent } from './give-feedback/give-feedback.component';

@NgModule({
  declarations: [HelpCenterComponent, CallSupportComponent, GiveFeedbackComponent],
  imports: [CommonModule, HelpCenterRoutingModule],
})
export class HelpCenterModule {}
