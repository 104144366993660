class MapLocationModel {
  formattedAddress = '';
  locationName = '';
  address = '';
  city = '';
  state = '';
  stateShortname = '';
  zipcode = '';
  geometry: {
    latitude: number;
    longitude: number;
  };

  isStateLevel?: boolean;

  constructor(private readonly data: any = {}) {
    Object.assign(this, data);
    if (!this.geometry) {
      this.geometry = {
        latitude: data.geometry && data.geometry.latitude ? data.geometry.latitude : null,
        longitude: data.geometry && data.geometry.longitude ? data.geometry.longitude : null,
      };
    }
  }
}

export { MapLocationModel };
