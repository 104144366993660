import { Component, EventEmitter, Input, OnInit, Output, SimpleChanges, ViewChild } from '@angular/core';
import { ModalConfig } from '../modal/modal.model';
import { ModalComponent } from '../modal/modal.component';

@Component({
  selector: 'app-modal-deal-now-explained',
  templateUrl: './modal-deal-now-explained.component.html',
  styleUrls: ['./modal-deal-now-explained.component.scss'],
})
export class ModalDealNowExplainedComponent implements OnInit {
  @ViewChild('modal') private readonly modalComponent: ModalComponent;
  @Input() isOpen = false;
  @Output() isOpenChange = new EventEmitter<boolean>();
  @Output() onGetStartedChange = new EventEmitter<boolean>();

  @Output() onClose = new EventEmitter();

  modalConfig: ModalConfig = {};

  constructor() {}

  ngOnInit(): void {
    this.modalConfig = {
      name: 'DealNow explained',
      beforeClose: async () => {
        this.isOpen = false;
        this.isOpenChange.emit(this.isOpen);
        this.onClose.emit();
        return true;
      },
    };
  }

  ngOnChanges(changes: SimpleChanges): void {
    const { isOpen } = changes;

    if (isOpen?.currentValue) {
      this.modalComponent?.open();
    }
  }

  closeModal() {
    this.modalComponent.close();
  }

  onGetStarted() {
    this.onGetStartedChange.emit();
    this.closeModal();
  }
}
