import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ReactiveFormsModule } from '@angular/forms';
import { ModalFailedPayLaterComponent } from './modal-failed-pay-later.component';
import { StripeFormModule } from '../stripe-form/stripe-form.module';
import { WidgetsModule } from '../widgets.module';

@NgModule({
  declarations: [ModalFailedPayLaterComponent],
  exports: [ModalFailedPayLaterComponent],
  imports: [CommonModule, ReactiveFormsModule, StripeFormModule, WidgetsModule],
})
export class ModalFailedPayLaterModule {}
