import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { VerificationListComponent } from './verification-list.component';

@NgModule({
  declarations: [VerificationListComponent],
  exports: [VerificationListComponent],
  imports: [CommonModule],
})
export class VerificationListModule {}
