import { Injectable } from '@angular/core';
import { AbilityBuilder, Ability } from '@casl/ability';
import { BehaviorSubject, Observable } from 'rxjs';
import { filter, take } from 'rxjs/operators';

export enum AuthorizedAction {
  verify = 'verify',
  approve = 'approve',
  manage = 'manage',
  read = 'read',
  create = 'create',
  update = 'update',
  delete = 'delete',
}

export enum AuthorizedSubject {
  all = 'all',
  listing = 'listing',
  deal = 'deal',
  banking = 'banking',
  chat = 'chat',
  user = 'user',
  organization = 'organization',
}

export interface Permission {
  resource: AuthorizedSubject;
  action: AuthorizedAction;
}

@Injectable({
  providedIn: 'root'
})
export class AuthorizationService {
  private abilityInitialized = new BehaviorSubject<boolean>(false);

  constructor(private readonly ability: Ability) {}

  updateAbility(permissions: Permission[]): void {
    this.ability.update(this.buildAbility(permissions).rules);
    this.abilityInitialized.next(true);
  }

  private buildAbility(permissions: Permission[]): Ability {
    permissions = permissions ?? [{ resource: AuthorizedSubject.all, action: AuthorizedAction.manage }];
    const builder = new AbilityBuilder(Ability);

    permissions.forEach(permission => {
      builder.can(permission.action, permission.resource);
    });

    return builder.build();
  }

  can(action: AuthorizedAction, subject: AuthorizedSubject): boolean {
    return this.ability.can(action, subject);
  }

  waitForAbilityInitialization(): Promise<boolean> {
    return this.abilityInitialized.pipe(
      filter(initialized => initialized),
      take(1)
    ).toPromise();
  }
}