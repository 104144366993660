import { Component, EventEmitter, Input, OnDestroy, OnInit, Output, SimpleChanges, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { AutoUnsubscribe } from 'ngx-auto-unsubscribe';
import { ModalComponent } from 'src/app/widgets/modal/modal.component';
import { ModalConfig } from 'src/app/widgets/modal/modal.model';
import { NotificationService, OnboardingService } from 'src/app/core/services';

@AutoUnsubscribe()
@Component({
  selector: 'app-invite-business-owner-modal',
  templateUrl: './invite-business-owner-modal.component.html',
  styleUrls: ['../prove-verification-modal/prove-verification-modal.component.scss'],
})
export class InviteBusinessOwnerModal implements OnInit, OnDestroy {
  @ViewChild('modal') private readonly modalComponent: ModalComponent;
  @Input() isOpen = false;
  @Output() isOpenChange = new EventEmitter<boolean>();
  @Output() onSuccess = new EventEmitter();

  submitted = false;

  form: FormGroup;

  constructor(
    private readonly fb: FormBuilder,
    private readonly onboardingService: OnboardingService,
    private readonly notificationService: NotificationService
  ) {
    this.form = this.fb.group({
      email: ['', [Validators.required, Validators.email]],
    });
  }

  modalConfig: ModalConfig = {};
  ngOnInit(): void {
    this.modalConfig = {
      hideHeader: true,
      modalDialogClass: 'invite-business-owner-modal',
      name: 'Invite business owner modal',
      beforeClose: async () => {
        this.isOpen = false;
        this.isOpenChange.emit(this.isOpen);
        this.onboardingService.setInviteBusinessOwnerModalIsVisible(false);
        return true;
      },
    };
  }

  ngOnDestroy(): void {}

  ngOnChanges(changes: SimpleChanges): void {
    const { isOpen } = changes;

    if (isOpen?.currentValue) {
      this.modalComponent?.open();
    }
  }

  get f() {
    return this.form.controls;
  }

  closeModal() {
    this.modalComponent.close();
  }

  sendInvite() {
    this.submitted = true;
    if (this.form.invalid) {
      return;
    }

    this.onboardingService.sendInviteToBusinessOwner(this.form.value).subscribe({
      next: () => {
        this.notificationService.notification('success', 'Invite sent successfully');
        this.onSuccess.emit();
        this.closeModal();
      },
      error: (error) => {
        this.notificationService.notification('error', error.message);
      },
    });
  }
}
