import { Component, EventEmitter, Input, OnInit, Output, SimpleChanges, ViewChild } from '@angular/core';
import { ChatKittyService, NotificationService } from 'src/app/core/services';
import { ModalComponent } from '../modal/modal.component';
import { ModalConfig } from '../modal/modal.model';

@Component({
  selector: 'app-modal-leave-chat',
  templateUrl: './modal-leave-chat.component.html',
  styleUrls: ['./modal-leave-chat.component.scss'],
})
export class ModalLeaveChatComponent implements OnInit {
  @ViewChild('modal') private readonly modalComponent: ModalComponent;
  @Input() isOpen = false;
  @Input() chatId: number;
  @Output() isOpenChange = new EventEmitter<boolean>();

  @Output() onClose = new EventEmitter<any>();

  modalConfig: ModalConfig = {};

  constructor(
    private readonly chatKittyService: ChatKittyService,
    private readonly notificationService: NotificationService
  ) {}

  ngOnInit(): void {
    this.modalConfig = {
      name: 'Leave Chat Modal',
      trackevent: true,
      beforeClose: async () => {
        this.isOpen = !this.isOpen;

        this.isOpenChange.emit(this.isOpen);
        this.onClose.emit();
        return true;
      },
    };
  }

  ngOnChanges(changes: SimpleChanges): void {
    const { isOpen } = changes;

    if (isOpen?.currentValue) {
      this.modalComponent.open();
    }
  }

  leaveChat() {
    this.chatKittyService.leaveChannel(this.chatId).then((succeeded) => {
      if (succeeded) {
        this.notificationService.notification('success', 'Successfully left chat');
        window.location.reload();
      } else {
        this.notificationService.defaultErrorNotification(
          'An error has occured leaving this channel, please try agian.'
        );
      }
    });
  }
}
