import { ErrorHandler, Inject, Injectable } from '@angular/core';
import { Notifier } from '@airbrake/browser';

import { APP_CONFIG, IAppConfig } from './config/config';

@Injectable({
  providedIn: 'root',
})
export class AirbrakeErrorHandler implements ErrorHandler {
  airbrake: Notifier;

  constructor(@Inject(APP_CONFIG) private readonly config: IAppConfig) {
    const { airbrakeProject, airbrakeKey, env, failed } = this.config;
    if (!failed) {
      this.airbrake = new Notifier({
        projectId: parseInt(airbrakeProject, 10),
        projectKey: airbrakeKey,
        environment: this.config.appDomain,
      });
    }
  }

  handleError(error: any): void {
    if (this.config.env === 'local') {
      console.error(error);
      return;
    }
    this.airbrake?.notify(error);
  }
}
