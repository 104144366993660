import { ElementRef, Injectable } from '@angular/core';
import { take } from 'rxjs/operators';
import { BehaviorSubject, Observable, timer } from 'rxjs';
import { formatListing } from 'src/app/shared/utils-listing';
import { NgbAccordionDirective } from '@ng-bootstrap/ng-bootstrap';
import { IListingStepsNames, ISellerListing } from '../../models';

@Injectable({
  providedIn: 'root',
})
export class ListingStepsAccordionService {
  private readonly listingStepsActivePanelSubject = new BehaviorSubject<IListingStepsNames>(null);
  get listingStepsActivePanel() {
    return this.listingStepsActivePanelSubject.asObservable();
  }

  constructor() {}

  initActivePanel(listing$: Observable<ISellerListing>) {
    this.initTogglingSteps(listing$);
    this.openInitialPanel(listing$);
  }

  openInitialPanel(listing$: Observable<ISellerListing>) {
    listing$.pipe(take(1)).subscribe((listing) => {
      const { stepOneComplete, stepTwoComplete, stepThreeComplete } = listing;

      if (!stepOneComplete) {
        this.setActivePanel('step-one');
        return;
      }

      if (!stepTwoComplete) {
        this.setActivePanel('step-two');
        return;
      }

      if (!stepThreeComplete) {
        this.setActivePanel('step-three');
      }
    });
  }

  initTogglingSteps(rawNewListing$: Observable<ISellerListing>) {
    let oldListing: ISellerListing = null;

    rawNewListing$.subscribe((rawNewListing: ISellerListing) => {
      const newListing = formatListing(rawNewListing);
      oldListing = oldListing || newListing;
      const nextIncompleteStep = [
        { isStepComplete: newListing.stepOneComplete, stepName: 'step-one' },
        { isStepComplete: newListing.stepTwoComplete, stepName: 'step-two' },
        { isStepComplete: newListing.stepThreeComplete, stepName: 'step-three' },
      ].find((step) => !step.isStepComplete);

      if (!nextIncompleteStep) {
        oldListing = newListing;
        return;
      }

      const isStepOneJustCompleted = newListing.stepOneComplete && !oldListing.stepOneComplete;
      if (isStepOneJustCompleted) {
        this.setActivePanel(nextIncompleteStep.stepName as IListingStepsNames);
        oldListing = newListing;
        return;
      }

      const isStepTwoJustCompleted = newListing.stepTwoComplete && !oldListing.stepTwoComplete;
      if (isStepTwoJustCompleted) {
        this.setActivePanel(nextIncompleteStep.stepName as IListingStepsNames);
        oldListing = newListing;
        return;
      }

      const isStepThreeJustCompleted = newListing.stepThreeComplete && !oldListing.stepThreeComplete;
      if (isStepThreeJustCompleted) {
        this.setActivePanel(nextIncompleteStep.stepName as IListingStepsNames);
        oldListing = newListing;
        return;
      }

      oldListing = newListing;
    });
  }

  setActivePanel(name: IListingStepsNames) {
    if (this.listingStepsActivePanelSubject.getValue() === name) {
      return;
    }

    this.listingStepsActivePanelSubject.next(name);
  }

  manageAccordion(
    activeStep: IListingStepsNames,
    stepId: IListingStepsNames,
    accordionComponent: NgbAccordionDirective,
    focusEl: ElementRef
  ) {
    const isCurrentStep = activeStep === stepId;
    const isExpanded = accordionComponent.isExpanded(stepId);

    if (!isCurrentStep && isExpanded) {
      accordionComponent.collapse(stepId);
    }

    if (isCurrentStep) {
      timer(350).subscribe(() => {
        accordionComponent.expand(stepId);
        this.focusStepPanel(focusEl);
      });
    }
  }

  /**
   * Scrolls to the given element
   * @param topEl - div element on top of an accordion
   */
  focusStepPanel(topEl: ElementRef) {
    timer(350).subscribe(() => {
      const pageContainerEl: HTMLElement = document.querySelector('#page-content');
      const elementPosition = topEl.nativeElement.offsetTop;
      const containerPosition = pageContainerEl.offsetTop;
      const offsetPosition = elementPosition - containerPosition - 76;

      pageContainerEl.scrollTo({
        top: offsetPosition,
        behavior: 'smooth',
      });
    });
  }
}
