import { Component, Input, ViewEncapsulation } from '@angular/core';

@Component({
  selector: 'app-navigation-card',
  templateUrl: './navigation-card.component.html',
  styleUrls: ['./navigation-card.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class NavigationCardComponent {
  @Input()
  links: {
    label: string;
    link: string;
  }[];
}
