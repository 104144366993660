import { Component, Input, OnInit } from '@angular/core';
import * as d3 from 'd3';
import { Observable } from 'rxjs';

@Component({
  selector: 'app-step-visual',
  templateUrl: './step-visual.component.html',
  styleUrls: ['./step-visual.component.scss'],
})
export class StepVisualComponent implements OnInit {
  @Input() stepNames: string[];
  @Input() onOpen: Observable<null>;
  @Input() completeNumber: Observable<number>;
  totalSteps: number;
  barWidths = ['0%', '59%', '21%', '9%']; // add to this as seen fit
  drawn = false;

  constructor() {
    // Empty constructor
  }

  ngOnInit(): void {
    this.totalSteps = this.stepNames.length;
    this.onOpen.subscribe(() => {
      this.drawSvg();
    });
    this.completeNumber.subscribe((step) => {
      this.completeStep(step);
    });
  }

  drawSvg() {
    if (this.drawn) {
      return;
    }
    this.drawn = true;

    this.totalSteps = this.stepNames.length;

    const svg = d3.select('#d3-svg');
    const groups = svg
      .selectAll('g')
      .data(this.stepNames)
      .join('g')
      .attr('class', (d, i) => `group${i}`);

    groups
      .append('circle')
      .attr('r', 20)
      .attr('cx', (d, i) => `${i * (75 / (this.totalSteps - 1)) + 15}%`)
      .attr('cy', 30)
      .classed('step-circle', true);

    this.addRectangles(groups);
    this.addText(groups);
    this.activateStep(1);
  }

  addRectangles(groups: d3.Selection<d3.BaseType | SVGGElement, string, d3.BaseType, unknown>) {
    groups
      .filter((d, i) => i < this.totalSteps - 1)
      .append('rect')
      .attr('x', (d, i) => `${i * (75 / (this.totalSteps - 1)) + 23}%`)
      .attr('y', 28)
      .attr('rx', 3)
      .attr('ry', 3)
      .attr('height', 5)
      .attr('width', this.barWidths[this.totalSteps - 1])
      .classed('gray-bar', true);

    groups
      .filter((d, i) => i < this.totalSteps - 1)
      .append('rect')
      .attr('x', (d, i) => `${i * (75 / (this.totalSteps - 1)) + 23}%`)
      .attr('y', 28)
      .attr('rx', 3)
      .attr('ry', 3)
      .attr('height', 5)
      .attr('width', 0)
      .classed('blue-bar', true);
  }

  addText(groups: d3.Selection<d3.BaseType | SVGGElement, string, d3.BaseType, unknown>) {
    groups
      .append('text')
      .text((d) => d)
      .attr('y', 70)
      .attr('x', (d, i) => `${i * (75 / (this.totalSteps - 1)) + 15}%`)
      .attr('text-anchor', 'middle')
      .classed('name-text', true);

    groups
      .append('text')
      .text((d, i) => i + 1)
      .attr('y', 35)
      .attr('x', (d, i) => `${i * (75 / (this.totalSteps - 1)) + 15}%`)
      .attr('text-anchor', 'middle')
      .classed('number-text', true);
  }

  activateStep(number: number) {
    const completeGroup = d3.selectAll(`.group${number - 1}`);
    completeGroup.select('circle').classed('active', true);
    completeGroup.select('.number-text').classed('active', true);
  }

  completeStep(number: number) {
    const completeGroup = d3.selectAll(`.group${number - 1}`);
    completeGroup.select('.number-text').remove();
    completeGroup
      .append('image')
      .attr('xlink:href', 'assets/check-white.svg')
      .attr('y', 20)
      .attr('x', (d, i) => `${(number - 1) * (75 / (this.totalSteps - 1)) + 12}%`)
      .attr('height', 20)
      .attr('width', 20);
    completeGroup
      .select('.blue-bar')
      .transition()
      .duration(1000)
      .attr('width', this.barWidths[this.totalSteps - 1]);
    this.activateStep(number + 1);
  }
}
