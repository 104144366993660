import { Injectable } from '@angular/core';
import type { PostHog } from 'posthog-js';

declare const posthog: PostHog;

@Injectable({
  providedIn: 'root',
})
export class PosthogService {
  posthog: PostHog;

  constructor() {
    this.posthog = posthog;
  }
}
