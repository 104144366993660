import { IChatListing } from '../marketing-inbox/marketing-inbox.model';
import { MapLocationModel } from './map-location.model';
import { IUser } from './user.model';

export enum TestDriveStatus {
  Proposed = 'proposed',
  Confirmed = 'confirmed',
  Declined = 'declined',
  Created = 'created',
}

interface BaseTestDrive {
  chatId: number;
  location: MapLocationModel;
  listing: IChatListing;
  initiator: IUser;
  confirmer: IUser;
  status: TestDriveStatus;

  scheduleDate: Date;

  closingDetails: {
    closedBy: IUser;
    closingDate: Date;
    reason: string;
  };

  createdAt: Date;
}

export interface ITestDrive extends BaseTestDrive {
  _id: string;
}

export interface TestDriveCreateRequest extends Omit<BaseTestDrive, 'initiator' | 'confirmer' | 'listing'> {
  initiator: string;
  confirmer: string;
  listing: string;
}

export interface TestDriveUpdateRequest extends Partial<Omit<BaseTestDrive, 'initiator' | 'confirmer' | 'listing'>> {
  _id: string;
  initiator?: string;
  confirmer?: string;
  listing?: string;
}
