import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ListingImageComponent } from './listing-image.component';

@NgModule({
  declarations: [ListingImageComponent],
  exports: [ListingImageComponent],
  imports: [CommonModule],
})
export class ListingImageModule {}
