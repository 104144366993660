import { Inject, Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { APP_CONFIG, IAppConfig } from 'src/app/config/config';

export interface USAFedNowParticipant {
  routing: string;
  name: string;
  receiveOnly: boolean;
  sendReceive: boolean;
  rFPReceive: boolean;
  online: boolean;
  found: boolean;
}

export interface GetSupportedBanksResponseData {
  accountId: string;
  accountNumber: string;
  routingNumber: string;
  participant: USAFedNowParticipant;
}

@Injectable({ providedIn: 'root' })
export class UsallianceFedNowService {
  private readonly baseUrl: string;

  constructor(private readonly http: HttpClient, @Inject(APP_CONFIG) private readonly config: IAppConfig) {
    this.baseUrl = `${this.config.apiUrl}/bank/usalliance/fednow`;
  }

  getSupportedBanks(accountIds: string[]) {
    return this.http.post<{ data: GetSupportedBanksResponseData[] }>(`${this.baseUrl}/get-supported-banks`, {
      accountIds,
    });
  }

  isBankFedNowSupported(participant: USAFedNowParticipant) {
    const { found, receiveOnly, sendReceive } = participant;
    return found && (receiveOnly || sendReceive);
  }
}
