import { Injectable } from '@angular/core';
import { IndividualConfig, ToastrService } from 'ngx-toastr';
import { ToastButton, ToastComponent } from 'src/app/widgets/toast/toast.component';
import _ from 'lodash';

interface NotificationOptions extends Partial<IndividualConfig> {
  title: string;
  titleButton?: ToastButton;
  message?: string;
  buttons?: ToastButton[];
}

@Injectable({
  providedIn: 'root',
})
export class NotificationService {
  private debounceToast: (type: string, msg: string, title: string, defaultOptions: NotificationOptions) => void;
  constructor(private readonly toastr: ToastrService) {
    this.debounceToast = _.debounce(this.displayToast, 1000, {
      leading: true,
      trailing: false,
    });
  }

  /**
   *
   * @param type success | info | warning | error
   * @param messageOpt message or full notification options
   * @param bypassDebounce
   * @returns
   */
  public notification(type: string, messageOpt: NotificationOptions | string, bypassDebounce = false) {
    let opt = messageOpt;
    if (typeof messageOpt === 'string') {
      try {
        opt = JSON.parse(messageOpt);
      } catch (e) {
        opt = messageOpt;
      }
    }

    let msg = '';
    let title = '';
    let defaultOptions: NotificationOptions = {
      title: '',
      timeOut: 8000,
      closeButton: true,
      enableHtml: true,
      positionClass: 'toast-bottom-left',
      messageClass: 'toast-message',
      toastComponent: ToastComponent,
    };

    if (typeof opt === 'string') {
      title = opt;
    } else {
      title = opt?.title;
      msg = opt.message;
      defaultOptions = { ...defaultOptions, ...opt };
    }

    if (!title) {
      return;
    }
    if (bypassDebounce) {
      this.displayToast(type, msg, title, defaultOptions);
    } else {
      this.debounceToast(type, msg, title, defaultOptions);
    }
  }

  private displayToast(type: string, msg: string, title: string, defaultOptions: NotificationOptions) {
    switch (type) {
      case 'success':
        this.toastr.success(msg, title, defaultOptions);
        break;
      case 'info':
        this.toastr.info(msg, title, defaultOptions);
        break;
      case 'warning':
        this.toastr.warning(msg, title, defaultOptions);
        break;
      case 'error':
        this.toastr.error(msg, title, defaultOptions);
        break;
      default:
        break;
    }
  }

  public defaultErrorNotification(error) {
    const message = (error as Error).message || error;
    this.notification('error', message);
  }
}
