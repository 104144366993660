import { Pipe, PipeTransform } from '@angular/core';

@Pipe({ name: 'asterisk', pure: true })
export class AsteriskPipe implements PipeTransform {
  transform(value: string, active: boolean, length = 4) {
    if (active) {
      return value
        .split('')
        .map((item, index, arr) => {
          return index <= arr.length - length ? '*' : item;
        })
        .join('');
    }

    return value;
  }
}
