import { Injectable } from '@angular/core';
import { HttpRequest, HttpHandler, HttpEvent, HttpInterceptor, HttpResponse } from '@angular/common/http';
import { Observable } from 'rxjs';
import { LoadingService } from '../services';

@Injectable()
export class LoadingInterceptor implements HttpInterceptor {
  private requests: HttpRequest<any>[] = [];

  constructor(private loadingService: LoadingService) {}

  removeRequest(req: HttpRequest<any>) {
    const i = this.requests.indexOf(req);
    if (i >= 0) {
      this.requests.splice(i, 1);
    }
    this.loadingService.setLoading(this.requests.length > 0);
  }

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    const hideLoader = req.headers.has('X-No-Loader');
    if (hideLoader) {
      return next.handle(req);
    }

    this.requests.push(req);
    this.loadingService.setLoading(true);

    // We create a new observable which we return instead of the original
    return Observable.create((observer) => {
      // And subscribe to the original observable to ensure the HttpRequest is made
      const subscription = next.handle(req).subscribe(
        (ev) => {
          if (ev instanceof HttpResponse) {
            this.removeRequest(req);
            observer.next(ev);
          }
        },
        (err) => {
          this.removeRequest(req);
          observer.error(err);
        },
        () => {
          this.removeRequest(req);
          observer.complete();
        }
      );
      // return teardown logic in case of cancelled requests
      return () => {
        this.removeRequest(req);
        subscription.unsubscribe();
      };
    });
  }
}
