// multiple-email-input.component.ts
import { Component, EventEmitter, Input, Output } from '@angular/core';
import { ControlValueAccessor } from '@angular/forms';
import { getValueAccessorProvider } from 'src/app/shared/functions';

@Component({
  selector: 'app-multiple-email-input',
  templateUrl: './multiple-email-input.component.html',
  styleUrls: ['./multiple-email-input.component.scss'],
  providers: [getValueAccessorProvider(MultipleEmailInputComponent)],
})
export class MultipleEmailInputComponent implements ControlValueAccessor {
  @Input() placeholder = 'Enter email address';
  @Input() isInvalid = false;
  @Input() invalidFeedback = 'At least one email is required';
  @Output() emailsChange = new EventEmitter<string[]>();

  emails: string[] = [];
  currentEmail = '';
  disabled = false;

  // ControlValueAccessor implementation
  private onChange: (value: string[]) => void = () => {};
  private onTouched: () => void = () => {};

  onEmailInput(event: any) {
    const input = event.target.value;
    const lastChar = input[input.length - 1];
    
    if (lastChar === ',' || event.key === 'Tab') {
      event.preventDefault();
      this.addEmail();
    }
  }

  onEmailKeydown(event: KeyboardEvent) {
    if (event.key === 'Tab') {
      event.preventDefault();
      this.addEmail();
    }
  }

  addEmail() {
    const email = this.currentEmail.trim();
    if (email && this.validateEmail(email) && !this.emails.includes(email)) {
      this.emails.push(email);
      this.currentEmail = '';
      this.onChange(this.emails);
      this.emailsChange.emit(this.emails);
    }
  }

  removeEmail(email: string) {
    this.emails = this.emails.filter(e => e !== email);
    this.onChange(this.emails);
    this.emailsChange.emit(this.emails);
  }

  validateEmail(email: string): boolean {
    const re = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
    return re.test(email);
  }

  // ControlValueAccessor methods
  writeValue(emails: string[]): void {
    this.emails = emails || [];
  }

  registerOnChange(fn: (value: string[]) => void): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: () => void): void {
    this.onTouched = fn;
  }

  setDisabledState(isDisabled: boolean): void {
    this.disabled = isDisabled;
  }
}