import {
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges,
  ViewChild,
} from '@angular/core';
import { NgbPopover } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-popover',
  templateUrl: './popover.component.html',
  styleUrls: ['./popover.component.scss'],
})
export class PopoverComponent implements OnInit, OnChanges {
  @ViewChild('target') target: NgbPopover;
  @ViewChild('targetContainer') targetContainer: ElementRef;

  @Input() el: HTMLElement;
  @Input() masked: boolean = false;
  @Input() isOpen: boolean;
  @Output() isOpenChange = new EventEmitter<boolean>();

  @Output() onClose = new EventEmitter<boolean>();

  ngOnInit(): void {}

  ngOnChanges(changes: SimpleChanges): void {
    const { isOpen } = changes;

    if (isOpen?.currentValue !== undefined) {
      this.openClosePopover(isOpen.currentValue);
    }
  }

  openClosePopover(isOpen: boolean) {
    if (isOpen) {
      this.target?.open();
    } else {
      this.target?.close();
    }
  }

  onShown() {
    if (!this.targetContainer || !this.el) {
      return;
    }

    const { width, height } = this.el.getBoundingClientRect();
    this.targetContainer.nativeElement.style.display = 'block';
    this.targetContainer.nativeElement.style.width = `${width}px`;
    this.targetContainer.nativeElement.style.top = `${height}px`;
  }

  onHidden() {
    this.isOpen = false;
    this.targetContainer.nativeElement.style.display = 'none';
    this.isOpenChange.emit(this.isOpen);
    this.onClose.emit(this.isOpen);
  }

  onCloseClick() {
    this.target.close();
  }
}
