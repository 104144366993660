import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ReactiveFormsModule } from '@angular/forms';
import { StripeFormComponent } from './stripe-form.component';

@NgModule({
  declarations: [StripeFormComponent],
  exports: [StripeFormComponent],
  imports: [CommonModule, ReactiveFormsModule],
})
export class StripeFormModule {}
