<div class="progress-bar-steps mt-2">
  <div class="progress-bar-item-container d-flex justify-content-between">
    @for (item of steps; track item; let i = $index) {
    <div
      class="progress-bar-item flex-fill {{item.isDone? item.color : ''}}"
      [ngClass]="{ 'start': i === 0, 'end': i === steps.length, 'checked': item.isDone }"
    >
      @if (item.icon) {
      <img [src]="item.icon" alt="step finished icon" class="icon {{item.iconClass}}" />
      }
    </div>
    }
  </div>
</div>
