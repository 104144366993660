import { ActivatedRouteSnapshot, RouterStateSnapshot, Router } from '@angular/router';
import { Injectable } from '@angular/core';
import { Auth } from 'aws-amplify';

@Injectable()
export class LogoutGuard  {
  constructor(private readonly router: Router) {}

  async canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    try {
      if (state.url.includes('/auth/confirm-user')) {
        return true;
      }
      if (state.url.includes('/auth/initialize')) {
        return true;
      }
      const queryParams = new URLSearchParams(window.location.search);
      let redirect = '/account';
      if (queryParams.get('redirectTo')) {
        redirect = decodeURIComponent(queryParams.get('redirectTo'));
      }
      await Auth.currentAuthenticatedUser({ bypassCache: true });
      if (redirect.includes('?')) {
        this.router.navigateByUrl(redirect);
      } else {
        this.router.navigate([redirect]);
      }
      return false;
    } catch (error) {
      return true;
    }
  }
}
