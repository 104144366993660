import { Directive, ElementRef, HostListener } from '@angular/core';

@Directive({
  selector: '[dateMask]',
})
export class DateMaskDirective {
  constructor(private el: ElementRef) {}

  @HostListener('input', ['$event']) onInput(event: any) {
    const value = event.target.value;
    const slashCount = value?.split('/').length - 1;
    if (value?.length > 10) {
      event.target.value = value.slice(0, 10);
      return;
    }
    if (slashCount === 2) {
      return;
    }
    const input = event.target.value.replace(/\D/g, ''); // Remove all non-digit characters
    if (input.length <= 2) {
      if (Number(input) > 12) {
        event.target.value = `0${input.slice(0, 1)}/${input.slice(1)}`;
      } else {
        event.target.value = input;
      }
    } else if (input.length <= 4) {
      if (Number(input.slice(2)) > 31) {
        event.target.value = `${input.slice(0, 2)}/0${input.slice(2)}`;
      } else {
        event.target.value = `${input.slice(0, 2)}/${input.slice(2)}`;
      }
    } else {
      event.target.value = `${input.slice(0, 2)}/${input.slice(2, 4)}/${input.slice(4, 8)}`;
    }
  }
}
