import { Component, Input, OnInit } from '@angular/core';

export interface ProgressBarStepsItem {
  // Icon shown at the right side of the bar
  icon: string | null;
  iconClass?: string;
  color: 'green' | 'blue' | null;
  isDone: boolean;
}

@Component({
  selector: 'app-progress-bar-steps',
  templateUrl: './progress-bar-steps.component.html',
  styleUrls: ['./progress-bar-steps.component.scss'],
})
export class ProgressBarStepsComponent implements OnInit {
  @Input() steps: ProgressBarStepsItem[] = [];

  constructor() {}

  ngOnInit(): void {}
}
