import {
  Component,
  ElementRef,
  EventEmitter,
  HostListener,
  Input,
  OnInit,
  Output,
  SimpleChanges,
  ViewChild,
} from '@angular/core';
import { WebcamImage, WebcamUtil } from 'ngx-webcam';
import { Observable, Observer, Subject } from 'rxjs';
import { NotificationService } from 'src/app/core/services';
import { ModalComponent } from '../modal/modal.component';
import { ModalConfig } from '../modal/modal.model';

@Component({
  selector: 'app-modal-camera',
  templateUrl: './modal-camera.component.html',
  styleUrls: ['./modal-camera.component.scss'],
})
export class ModalCameraComponent implements OnInit {
  @ViewChild('modal') private readonly modalComponent: ModalComponent;
  @ViewChild('camContainer') private readonly camEl: ElementRef;

  @Input() isOpen = false;
  @Output() isOpenChange = new EventEmitter<boolean>();

  @Output() onClose = new EventEmitter<any>();
  @Output() onCapture = new EventEmitter<File>();

  modalConfig: ModalConfig = {};
  multipleWebcamsAvailable = false;
  width = 0;

  nextCamera = new Subject<boolean | string>();
  get nextCameraObservable() {
    return this.nextCamera.asObservable();
  }

  trigger = new Subject<void>();
  get triggerObservable() {
    return this.trigger.asObservable();
  }

  constructor(private readonly notificationsService: NotificationService) {}

  ngOnInit(): void {
    window.dispatchEvent(new Event('resize'));

    this.modalConfig = {
      size: 'xl',
      beforeClose: async () => {
        this.isOpen = !this.isOpen;

        this.isOpenChange.emit(this.isOpen);
        this.onClose.emit();
        return true;
      },
    };

    WebcamUtil.getAvailableVideoInputs().then((mediaDevices: MediaDeviceInfo[]) => {
      this.multipleWebcamsAvailable = mediaDevices && mediaDevices.length > 1;
    });
  }

  ngOnChanges(changes: SimpleChanges) {
    const { isOpen } = changes;

    if (isOpen?.currentValue) {
      this.modalComponent?.open();
    }
  }

  @HostListener('window:resize', ['$event'])
  resizeCanvas() {
    this.resizeCamera();
  }

  onModalShown() {
    this.resizeCamera();
  }

  resizeCamera() {
    this.width = this.camEl?.nativeElement?.clientWidth;
  }

  onInitError(event) {
    this.notificationsService.notification('error', 'Please allow camera access.');
  }

  onSwitchCamera() {
    this.nextCamera.next(true);
  }

  onImageCapture(image: WebcamImage) {
    const { imageAsBase64: url } = image;
    this.dataUrlToFile(url, `camera-${+new Date()}`).subscribe((file) => {
      this.onCapture.emit(file);
      this.modalComponent.close();
    });
  }

  onTrigger() {
    this.trigger.next();
  }

  onCancel() {
    this.modalComponent.close();
  }

  dataUrlToFile(dataURI: string, fileName: string): Observable<File> {
    return new Observable((observer: Observer<File>) => {
      const byteString: string = window.atob(dataURI);
      const arrayBuffer: ArrayBuffer = new ArrayBuffer(byteString.length);
      const int8Array: Uint8Array = new Uint8Array(arrayBuffer);

      for (let i = 0; i < byteString.length; i++) {
        int8Array[i] = byteString.charCodeAt(i);
      }

      const option = { type: 'image/jpeg' };
      const blob = new Blob([int8Array], option);
      const file = new File([blob], fileName, option);
      observer.next(file);
      observer.complete();
    });
  }
}
