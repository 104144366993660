import { Pipe, PipeTransform } from '@angular/core';
import * as moment from 'moment';

@Pipe({ name: 'NGBDateToDate', pure: true })
export class NGBDateToDatePipe implements PipeTransform {
  transform(ngbDate: any): any {
    const mdt = moment([ngbDate.year, ngbDate.month - 1, ngbDate.day]);

    return !mdt.isValid() ? '' : mdt.format('MMMM DD, YYYY');
  }
}

@Pipe({ name: 'dateToNGBDate', pure: true })
export class DateToNGBDatePipe implements PipeTransform {
  transform(date: Date) {
    const dob = new Date(date);

    return { year: dob.getFullYear(), month: dob.getMonth() + 1, day: dob.getDate() + 1 };
  }
}
