import { ISellerListing, ListingCategoryTypes } from 'src/app/models';
import { get } from 'lodash';
import { ListingStepsConfig } from './model';
import {
  defaultIsStepFiveComplete,
  defaultIsStepFourComplete,
  defaultIsStepOneComplete,
  defaultIsStepSixComplete,
  defaultIsStepTwoComplete,
} from './utils';

export enum MotorcycleDriveTypes {
  ShaftDrive = 'Shaft drive',
  BeltDrive = 'Belt drive',
  ChainDrive = 'Chain drive',
}

export enum MotorcycleCategory {
  Cruiser = 'Cruiser',
  DirtBike = 'Dirt Bike',
  Moped = 'Moped',
  Sportbike = 'Sportbike',
  Touring = 'Touring',
  Trike = 'Trike',
}

const isStepThreeComplete = (listing: ISellerListing) => {
  const { Cylinders, Engine, Fuel, Transmission, vehicleDescription, hasDamage, TitleType } = listing;
  const category = get(listing, 'additionalDetails.motorcycle.category', false);
  const primaryColor = get(listing, 'additionalDetails.motorcycle.primaryColor', false);
  const driveType = get(listing, 'additionalDetails.motorcycle.driveType', false);

  return !!(
    vehicleDescription &&
    Cylinders &&
    Engine &&
    Fuel &&
    Transmission &&
    TitleType &&
    category &&
    primaryColor &&
    driveType &&
    hasDamage !== null
  );
};

export const MOTORCYCLE_FIELDS_CONFIG: ListingStepsConfig = {
  type: ListingCategoryTypes.Motorcycle,

  stepOne: {
    fields: {
      RegistrationYear: true,
      CarMake: true,
      CarModel: true,
      vinNumber: true,
      Mileage: true,
      Zip: true,
      Price: true,
      condition: true,
      auction: true,
    },
    isComplete: defaultIsStepOneComplete,
  },

  stepThree: {
    fields: {
      vehicleDescription: true,
      Cylinders: true,
      Engine: true,
      Fuel: true,
      Transmission: true,
      TitleType: true,
      hasDamage: true,
      'additionalDetails.motorcycle.category': true,
      'additionalDetails.motorcycle.primaryColor': true,
      'additionalDetails.motorcycle.driveType': true,
    },
    isComplete: isStepThreeComplete,
  },

  // Default
  stepTwo: {
    fields: null,
    isComplete: defaultIsStepTwoComplete,
  },
  stepFour: {
    fields: null,
    isComplete: defaultIsStepFourComplete,
  },
  stepFive: {
    fields: null,
    isComplete: defaultIsStepFiveComplete,
  },
  stepSix: {
    fields: null,
    isComplete: defaultIsStepSixComplete,
  },
};
