<app-widgets-phone-verify
  [title]="titleVerify"
  [(isOpen)]="showModalVerify"
  [enableEditPhone]="enableEditPhone"
  (onClose)="onClose()"
  (onVerify)="onPhoneVerify()"
  (onChangePhone)="showModalEdit = true"
></app-widgets-phone-verify>

<app-widgets-phone-edit
  [title]="editTitle"
  [(isOpen)]="showModalEdit"
  (onClose)="onClose()"
  [skipButton]="skipButton"
  (onSkip)="onPhoneSkip()"
  (onSuccess)="handleEditSuccess()"
>
</app-widgets-phone-edit>
