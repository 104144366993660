import { ListingCategoryTypes } from 'src/app/models';
import { ListingStepsConfig } from './model';
import {
  defaultIsStepFiveComplete,
  defaultIsStepFourComplete,
  defaultIsStepOneComplete,
  defaultIsStepSixComplete,
  defaultIsStepThreeComplete,
  defaultIsStepTwoComplete,
} from './utils';

export enum AutoDriveTypes {
  AWD = 'AWD/4WD',
  FWD = 'Front Wheel Drive',
  RWD = 'Rear Wheel Drive',
}

export const AUTO_FIELDS_CONFIG: ListingStepsConfig = {
  type: ListingCategoryTypes.Auto,
  stepOne: {
    fields: {
      RegistrationYear: true,
      CarMake: true,
      CarModel: true,
      vinNumber: true,
      Trim: true,
      Mileage: true,
      Zip: true,
      Price: true,
      condition: true,
      auction: true,
    },
    isComplete: defaultIsStepOneComplete,
  },

  stepThree: {
    fields: {
      vehicleDescription: true,
      DriveType: true,
      Cylinders: true,
      Engine: true,
      Fuel: true,
      Transmission: true,
      BodyStyle: true,
      Doors: true,
      ExteriorColor: true,
      InteriorColor: true,
      TitleType: true,
      hasDamage: true,
      mpg: true,
    },
    isComplete: defaultIsStepThreeComplete,
  },

  // Default
  stepTwo: {
    fields: null,
    isComplete: defaultIsStepTwoComplete,
  },
  stepFour: {
    fields: null,
    isComplete: defaultIsStepFourComplete,
  },
  stepFive: {
    fields: null,
    isComplete: defaultIsStepFiveComplete,
  },
  stepSix: {
    fields: null,
    isComplete: defaultIsStepSixComplete,
  },
};
