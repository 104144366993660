import { ISellerListing, ListingCategoryTypes } from 'src/app/models';
import { get } from 'lodash';
import { ListingStepsConfig } from './model';
import {
  defaultIsStepFiveComplete,
  defaultIsStepFourComplete,
  defaultIsStepOneComplete,
  defaultIsStepSixComplete,
  defaultIsStepTwoComplete,
} from './utils';

export enum ATVDriveTypes {
  ShaftDrive = 'Shaft drive',
  BeltDrive = 'Belt drive',
  ChainDrive = 'Chain drive',
}

export enum ATVStarters {
  Electric = 'Electric',
  KickStarter = 'Kick starter',
}

export enum ATVCategories {
  Utility = 'Utility',
  Sport = 'Sport',
  Youth = 'Youth',
  FourWheeler = '4 wheeler',
  SideBySide = 'Side by side',
  GolfCart = 'Golf cart',
  GoCart = 'Go cart',
}

const isStepThreeComplete = (listing: ISellerListing) => {
  const { Engine, Fuel, Transmission, vehicleDescription, hasDamage, TitleType } = listing;
  const driveType = get(listing, 'additionalDetails.atv.driveType', false);
  const category = get(listing, 'additionalDetails.atv.category', false);
  const starter = get(listing, 'additionalDetails.atv.starter', false);
  const weight = get(listing, 'additionalDetails.atv.weight', false);
  const primaryColor = get(listing, 'additionalDetails.atv.primaryColor', false);

  return !!(
    vehicleDescription &&
    Engine &&
    Fuel &&
    Transmission &&
    TitleType &&
    starter &&
    primaryColor &&
    driveType &&
    category &&
    weight &&
    hasDamage !== null
  );
};

export const ATV_FIELDS_CONFIG: ListingStepsConfig = {
  type: ListingCategoryTypes.ATVOHV,

  stepOne: {
    fields: {
      RegistrationYear: true,
      CarMake: true,
      CarModel: true,
      vinNumber: true,
      Trim: true,
      Mileage: true,
      Zip: true,
      Price: true,
      condition: true,
      auction: true,
    },
    isComplete: defaultIsStepOneComplete,
  },

  stepThree: {
    fields: {
      vehicleDescription: true,
      Engine: true,
      Fuel: true,
      Transmission: true,
      TitleType: true,
      hasDamage: true,
      'additionalDetails.atv.driveType': true,
      'additionalDetails.atv.category': true,
      'additionalDetails.atv.starter': true,
      'additionalDetails.atv.weight': true,
      'additionalDetails.atv.primaryColor': true,
    },
    isComplete: isStepThreeComplete,
  },

  // Default
  stepTwo: {
    fields: null,
    isComplete: defaultIsStepTwoComplete,
  },
  stepFour: {
    fields: null,
    isComplete: defaultIsStepFourComplete,
  },
  stepFive: {
    fields: null,
    isComplete: defaultIsStepFiveComplete,
  },
  stepSix: {
    fields: null,
    isComplete: defaultIsStepSixComplete,
  },
};
