import { ISellerListing, ListingCategoryTypes } from 'src/app/models';

// Manually add the nested properties
export interface ListingsFieldMapping extends Partial<Record<keyof ISellerListing, boolean>> {
  // Motorcycle
  'additionalDetails.motorcycle.primaryColor'?: boolean;
  'additionalDetails.motorcycle.category'?: boolean;
  'additionalDetails.motorcycle.driveType'?: boolean;

  // ATV
  'additionalDetails.atv.driveType'?: boolean;
  'additionalDetails.atv.category'?: boolean;
  'additionalDetails.atv.starter'?: boolean;
  'additionalDetails.atv.weight'?: boolean;
  'additionalDetails.atv.primaryColor'?: boolean;
}

// When null, the default determining if a particular step is completed.
type ListingStepFields = ListingsFieldMapping | null;

interface ListingStepConfig {
  fields: ListingStepFields;
  isComplete: (listing: ISellerListing) => boolean;
}

export type ListingStepNames = 'stepOne' | 'stepTwo' | 'stepThree' | 'stepFour' | 'stepFive' | 'stepSix';
export type ListingSteps = { [key in ListingStepNames]: ListingStepConfig };
export interface ListingStepsConfig extends ListingSteps {
  type: ListingCategoryTypes;
}
