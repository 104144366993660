import { Component, Input, OnDestroy, OnInit, ViewEncapsulation } from '@angular/core';
import { AutoUnsubscribe } from 'ngx-auto-unsubscribe';
import { NavbarService } from 'src/app/core/services/navbar.service';

@AutoUnsubscribe()
@Component({
  selector: 'app-navigation-header',
  templateUrl: './navigation-header.component.html',
  styleUrls: ['./navigation-header.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class NavigationHeaderComponent implements OnInit, OnDestroy {
  constructor(private readonly navbarService: NavbarService) {}

  closed: boolean;

  ngOnDestroy(): void {
    this.closed = true;
    this.navbarService.show();
  }

  ngOnInit(): void {
    this.navbarService.isMobileChanged$.subscribe((isMobile) => {
      if (this.closed) {
        return;
      }
      if (isMobile) {
        this.navbarService.hideIfMobile();
      } else {
        this.navbarService.show();
      }
    });
  }

  @Input()
  title: string;

  @Input()
  link: string;
}
