import { Injectable } from '@angular/core';

declare let fbq: Function;

@Injectable({
  providedIn: 'root',
})
export class FacebookService {
  constructor() {}

  trackCustomEvent(eventName, properties: object) {
    if (typeof fbq === 'undefined') {
      return;
    }
    fbq('trackCustom', eventName, properties);
  }
}
