import { Inject, Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { BehaviorSubject, Subject } from 'rxjs';
import { APP_CONFIG, IAppConfig } from 'src/app/config/config';
import { IDeal } from 'src/app/deals/deals.models';
import { ISellerListing } from 'src/app/models';
import { map, tap } from 'rxjs/operators';
import { UsallianceLoanDocumentTypes, UsallianceLoanStatuses } from './usalliance.service';

export interface ILoanDocument {
  type: UsallianceLoanDocumentTypes;
  key: string;
  filename: string;
  size: number;
  mimetype: string;
}
export interface ILoanStatusBankReponse {
  loan_number: string;
  loan_status: string;
  loan_term: string;
  loan_type: string;
  loan_entry_date: string;
  loan_decision_date: string;
  last_modified_date: string;
  loan_notes: string;
  booked_to_core: boolean;
  amount_approved: string;
  amount_pre_approved: string;
  rate_pre_approved: string;
  estimated_payment: string;
  counter_offer: boolean;
  first_payment_date: string;
  funding_apr: string;
  exact_monthly_payment: string;
  funding_date: string;
  funding_status: string;
  stipulations: [
    {
      stipulation_name: string;
      stipulation_completed: boolean;
    }
  ];
}

export interface IBaseLoan {
  _id: string;
  userId: string;
  dealId?: string;
  collateralListingId?: string;
  collateralListing?: ISellerListing;
  deal?: IDeal;
  bankLoanId?: string;
  amount: any;
  application: { [key: string]: any };
  status: UsallianceLoanStatuses;
  documents: ILoanDocument[];
  createdAt: Date;
  bankResponse?: ILoanStatusBankReponse;
}

export interface ILoan extends IBaseLoan {
  isProofOfInsuranceUploaded: boolean;
}

interface IShowLeavePageModal {
  show: boolean;
  route: string;
}

interface IUploadDocumentRequest extends ILoanDocument {}

@Injectable({ providedIn: 'root' })
export class LoanService {
  private readonly baseUrl: string;

  private loanAmount = new BehaviorSubject<number>(10000);
  private readonly showLeavePageModalSubject = new BehaviorSubject<IShowLeavePageModal>({
    show: false,
    route: null,
  });

  get showLeavePageModal() {
    return this.showLeavePageModalSubject.asObservable();
  }

  loanAmount$ = this.loanAmount.asObservable();

  private readonly loanApplicationInfo = new Subject<any>();

  private readonly isLoanApplicationFreshSubject = new BehaviorSubject<boolean>(true);
  get isLoanApplicationFresh() {
    return this.isLoanApplicationFreshSubject.asObservable();
  }

  get applicationInfo() {
    return this.loanApplicationInfo.asObservable();
  }

  constructor(private readonly http: HttpClient, @Inject(APP_CONFIG) private readonly config: IAppConfig) {
    this.baseUrl = `${this.config.apiUrl}/bank`;
  }

  setApplicationInfo(application: object) {
    this.loanApplicationInfo.next(application);
  }

  setLoanAmount(amount: number) {
    this.loanAmount.next(amount);
  }

  setIsLoanApplicationFresh(value: boolean) {
    this.isLoanApplicationFreshSubject.next(value);
  }

  setShowLeavePageModal(value) {
    this.showLeavePageModalSubject.next(value);
  }

  all = () => this.http.get<ILoan[]>(`${this.baseUrl}/loans`).pipe(map((rawLoans) => rawLoans.map(this.formatLoan)));

  store = (data) => this.http.post(`${this.baseUrl}/loans`, data);

  saveLoanApplication = (data) => this.http.post(`${this.baseUrl}/loans/application`, data);

  getLoanApplication(id) {
    return this.http.get(`${this.baseUrl}/loans/application/${id}`).pipe(
      tap((results: ILoan) => {
        this.setApplicationInfo(results);
        this.setLoanAmount(Number(results.amount));
      })
    );
  }

  formatLoan(loan: IBaseLoan): ILoan {
    const isProofOfInsuranceUploaded = !!loan.documents?.find((doc) => doc.type);
    return { ...loan, isProofOfInsuranceUploaded };
  }

  update = (id, data) => this.http.put(`${this.baseUrl}/loans/${id}`, data);

  // TODO: change this to POST
  cancel = (id) => this.http.get<any>(`${this.baseUrl}/loans/${id}/cancel`);

  sendCollateral = (id, data) => this.http.post<any>(`${this.baseUrl}/loans/${id}/collateral`, data);

  uploadDocument(id: string, data: IUploadDocumentRequest) {
    return this.http.post(`${this.baseUrl}/loans/${id}/upload-document`, data);
  }

  checkUnfinishedLoans = () => this.http.get<{ hasUnfinished: boolean }>(`${this.baseUrl}/loans/has-unfinished`);
}
