<div class="content-box">
  @if (displayUser?.id && displayUser ) {
  <div class="">
    <div class="header py-3">{{displayUser?.id === listing?.userId? 'Seller':'Buyer'}}</div>
    <div class="person-info">
      <div class="d-flex align-items-center">
        <div class="position-relative d-inline-block">
          <app-profile-photo
            class="profile-photo"
            [src]="displayUser.profileImage"
            [profileName]="displayUser?.shortName"
            size="56"
          ></app-profile-photo
          >@if (!fullyVerified) {
          <img
            class="profile-icon position-absolute"
            [src]="partiallyVerified ? 'assets/verified.svg' : 'assets/not-verified.svg'"
            alt="profile verification indicator"
          />
          } @if (fullyVerified) {
          <img
            class="profile-icon position-absolute"
            src="/assets/fully-verified.svg"
            alt="profile verification indicator"
          />
          }
        </div>
        @if (chatId && isChatBtnDisplayed) {
        <a [routerLink]="['/inbox', chatId]" class="btn-outline-primary ms-2">Go to chat</a>
        }
      </div>
      <div class="name mt-2">{{displayUser.shortName}}</div>
      <div class="light-text">{{displayUser.location}}</div>
      <div class="light-text">Joined {{displayUser.date | date }}</div>
    </div>
    <div class="verifications my-4">
      @for (verification of displayUser.verifications; track verification) {
      <div class="d-flex mb-2">
        @if (!fullyVerified && verification.isBank) {
        <img class="me-2" src="/assets/fully-verified-gray.svg" alt="verification" />
        } @if (!fullyVerified && !verification.isBank) {
        <img
          class="me-2"
          [src]="!!verification.verified? '/assets/verified.svg' : '/assets/not-verified.svg'"
          alt="verification"
        />
        } @if (fullyVerified) {
        <img class="me-2" src="/assets/fully-verified.svg" alt="verification" />
        }
        <span class="verification-text">{{verification.text}}</span>
      </div>
      }
      <button class="link-button" (click)="openInfoModal()">Learn about account verification</button>
    </div>
    <hr />
  </div>
  } @else if (displayOrganization?.id && displayOrganization ) {
    <div class="">
      <div class="header py-3">{{displayOrganization?.id === listing?.organizationId? 'Seller':'Buyer'}}</div>
      <div class="person-info">
        <div class="d-flex align-items-center">
          <div class="position-relative d-inline-block">
            <app-profile-photo
              class="profile-photo"
              [src]="displayOrganization.profileImage"
              [profileName]="displayOrganization.name"
              size="56"
            ></app-profile-photo
            >@if (!fullyVerified) {
            <img
              class="profile-icon position-absolute"
              [src]="partiallyVerified ? 'assets/verified.svg' : 'assets/not-verified.svg'"
              alt="profile verification indicator"
            />
            } @if (fullyVerified) {
            <img
              class="profile-icon position-absolute"
              src="/assets/fully-verified.svg"
              alt="profile verification indicator"
            />
            }
          </div>
          @if (chatId && isChatBtnDisplayed) {
          <a [routerLink]="['/inbox', chatId]" class="btn-outline-primary ms-2">Go to chat</a>
          }
        </div>
        <div class="name mt-2">{{displayOrganization.name}}</div>
        <div class="light-text">{{displayOrganization.location}}</div>
        <div class="light-text">Joined {{displayOrganization.date | date }}</div>
      </div>
      <div class="verifications my-4">
        @for (verification of displayOrganization.verifications; track verification) {
        <div class="d-flex mb-2">
          @if (!fullyVerified && verification.isBank) {
          <img class="me-2" src="/assets/fully-verified-gray.svg" alt="verification" />
          } @if (!fullyVerified && !verification.isBank) {
          <img
            class="me-2"
            [src]="!!verification.verified? '/assets/verified.svg' : '/assets/not-verified.svg'"
            alt="verification"
          />
          } @if (fullyVerified) {
          <img class="me-2" src="/assets/fully-verified.svg" alt="verification" />
          }
          <span class="verification-text">{{verification.text}}</span>
        </div>
        }
        <button class="link-button" (click)="openInfoModal()">Learn about account verification</button>
      </div>
      <hr />
    </div>
  } @if (!loadingTestDrive && confirmedTestDrive) {
  <div>
    <div class="mt-3">
      <div class="header">Test Drive</div>
      <div class="offer-box d-flex align-items-center justify-content-between mb-4 mt-3">
        <div class="left align-items-center d-flex">
          <div class="icon"><img src="assets/test-drive-icon.svg" alt="Test drive icon" /></div>
          <div class="details ms-3">
            <div class="price">{{testDrive.scheduleDate|date:'mediumDate'}}</div>
            <div class="date">at {{testDrive.scheduleDate|date:'shortTime'}}</div>
          </div>
        </div>
        <div class="right text-center ms-2">
          <a [routerLink]="['/test-drives']" class="btn-link">View event</a>
        </div>
      </div>
    </div>
    @if (loadingOffer || !offer) {
    <hr />
    }
  </div>
  } @if (!loadingOffer && offer) {
  <div>
    <div class="mt-3">
      <div class="header">Offer</div>
      <div class="offer-box d-flex align-items-center justify-content-between mb-4 mt-3">
        <div class="left align-items-center d-flex">
          <div class="icon"><img src="assets/offer-dollar.svg" alt="Offer icon" /></div>
          <div class="details ms-3">
            <div class="price">${{offer.price}}</div>
            <div class="date">{{offer.createdAt | date: 'longDate' }}</div>
          </div>
        </div>
        <div class="right text-center ms-2">
          <a [routerLink]="['/deals', offer.deal]" class="btn-link">View deal</a>
        </div>
      </div>
    </div>
    <hr />
  </div>
  } @if (!loadingListing && displayListing) {
  <div class="mt-4">
    <div class="mt-4">
      <div class="header mb-3">{{currentUserId === displayListing?.sellerId? 'Your listing': 'Listing'}}</div>
      <div class="d-flex px-0">
        <div class="position-relative">
          @if (displayListing.status === listingStatuses.Sold) {
          <div class="sold-label position-absolute pt-1">
            <span>sold</span>
          </div>
          } @if (hasPendingDeal) {
          <div class="badge-pending float-start d-flex align-items-center justify-content-center">Pending</div>
          }
          <img
            class="listing-img w-100"
            [src]="displayListing?.image"
            alt="listing img"
            [ngClass]="{'boating-img': displayListing.vehicleType === 'Boat' || displayListing?.vehicleType === 'RV' }"
          />
        </div>
      </div>
      <div class="vh-ct py-3">
        <div class="vh-nm mt-2">{{displayListing.name}}</div>

        <div class="vh-pr light-text mb-3">
          {{displayListing.price | currency:'':'symbol':"0.0"}} • {{displayListing.mileage | currency:'':'':"0.0"}}
          {{displayListing.vehicleType === 'Boat' ? 'hours' : 'miles'}}
        </div>
        @if (displayListing.isPublic && isBrowseListingEnabled) {
        <a [routerLink]="['/listing', displayListing.slug]" class="btn-outline-primary mt-3"> View listing</a>
        }
      </div>
    </div>
  </div>
  } @if (loadingListing) {
  <div class="mt-4">
    <div class="mt-4">
      <div class="header py-3">Listing</div>
      <div class="d-flex px-0">
        <img class="listing-img" src="assets/create-listing/new-listing-car.svg" alt="listing img" />
      </div>
      <div class="vh-ct py-3">
        <div class="vh-nm mt-2">Listing no longer available</div>
      </div>
    </div>
  </div>
  } @if (!displayUser?.id && !displayListing && !loading) {
  <div>
    <div class="header py-3">{{appName}}</div>
    <app-profile-photo class="profile-photo" src="assets/logo-circle.png" size="56"></app-profile-photo>
    <div class="header mt-3 mb-2">Need help?</div>
    <a
      class="btn-outline-primary d-inline-block"
      target="_blank"
      rel="noopener"
      [href]="'https://support.'+appName+'.com/'"
      role="button"
    >
      Go to Help Center
    </a>
  </div>
  } @if (displayUser?.id && displayUser && showChatButtons) {
  <div class="pb-3">
    <hr />
    <button class="px-1" (click)="blockUser()">
      <img src="assets/block_icon.svg" alt="block user icon" /> Report User
    </button>
    <hr />
    <button (click)="archiveConversation()">
      <img class="px-1" src="assets/icons/square-chat.svg" alt="archive conversation icon" />{{channelStatus ===
      ChannelStatus.active || channelStatus === ChannelStatus.completed?"Archive Chat":"Unarchive Chat"}}
    </button>
    <hr />
    <button (click)="removeConversation()">
      <img class="trash-icon" src="assets/icons/trash.svg" alt="leave conversation icon" /> Remove Conversation
    </button>
  </div>
  }
</div>

<app-modal-report-user
  [(isOpen)]="showReportUser"
  [reporterId]="currentUserId"
  [offenderId]="displayUser?.id"
></app-modal-report-user>
<app-modal-leave-chat [(isOpen)]="showLeaveChat" [chatId]="chatId"></app-modal-leave-chat>
<app-modal-archive-chat
  [(isOpen)]="showArchiveChat"
  [chatId]="chatId"
  [channelStatus]="channelStatus"
></app-modal-archive-chat>
<app-modal-verification-info [(isOpen)]="showInfoModal"></app-modal-verification-info>
