import { Pipe, PipeTransform } from '@angular/core';
import { MapLocationModel } from '../models';

@Pipe({
  name: 'parseAddress',
})
export class ParseAddressPipe implements PipeTransform {
  transform(location: MapLocationModel, ...args: unknown[]): unknown {
    const { city, state, stateShortname } = location || {};

    if (city && state) {
      const stateText = stateShortname;

      const cityText = city
        .split(' ')
        .map((e) => {
          return e.charAt(0).toUpperCase() + e.substr(1).toLowerCase();
        })
        .join(' ');

      // TODO add zipcode - `${zipcode}, ${city}, ${state}`
      return `${cityText}, ${stateText}`;
    }
    return 'N/A';
  }
}
