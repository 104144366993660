import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { NgbPopoverModule } from '@ng-bootstrap/ng-bootstrap';
import { PopoverComponent } from './popover.component';

@NgModule({
  declarations: [PopoverComponent],
  exports: [PopoverComponent],
  imports: [CommonModule, NgbPopoverModule],
})
export class PopoverModule {}
