import { Component, Inject, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { APP_CONFIG, IAppConfig } from 'src/app/config/config';
import { IUser } from 'src/app/models';

interface VerificationListItem {
  text: string;
  icon: string;
  isDone: boolean;
}

@Component({
  selector: 'app-verification-list',
  templateUrl: './verification-list.component.html',
  styleUrls: ['./verification-list.component.scss'],
})
export class VerificationListComponent implements OnInit, OnChanges {
  @Input() user: IUser;
  list: VerificationListItem[] = [];
  appName: string

  constructor(
    @Inject(APP_CONFIG) private readonly config: IAppConfig,
  ) {
    this.appName = this.config.appName;
  }

  ngOnInit(): void {}

  ngOnChanges(changes: SimpleChanges): void {
    const { user } = changes;

    if (user?.currentValue) {
      this.list = this.getList(user.currentValue);
    }
  }

  getList(user: IUser): VerificationListItem[] {
    const { isEmailVerified, isVerified, isUserPartiallyVerified } = user.verification;
    const doneIcon = isVerified ? 'assets/fully-verified.svg' : 'assets/verified.svg';
    return [
      {
        text: 'Email verified',
        icon: isEmailVerified ? doneIcon : 'assets/verified-gray.svg',
        isDone: isEmailVerified,
      },
      {
        text: 'Phone & ID verified',
        icon: isUserPartiallyVerified ? doneIcon : 'assets/verified-gray.svg',
        isDone: isUserPartiallyVerified,
      },
      {
        text: `${this.appName} Pay verified`,
        icon: isVerified ? doneIcon : 'assets/fully-verified-gray.svg',
        isDone: isVerified,
      },
    ];
  }
}
