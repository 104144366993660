import { ISellerListing, ISellerListingWorkflowTypes } from 'src/app/models';

export const isStandardStepOneComplete = (listing: ISellerListing) => {
  const { vinNumber, licencePlate, Mileage, Zip, Price } = listing;
  return !!(vinNumber && licencePlate && (Mileage || Mileage === 0) && Zip && Price);
};

export const isAuctionStepOneComplete = (listing: ISellerListing) => {
  if (!listing.auction?.active) {
    return false;
  }
  const { vinNumber, licencePlate, Mileage, Zip, auction } = listing;
  const { biddingStartAt, biddingEndAt } = auction.active;

  return !!(vinNumber && licencePlate && (Mileage || Mileage === 0) && Zip && biddingStartAt && biddingEndAt);
};

export const defaultIsStepOneComplete = (listing: ISellerListing) => {
  if (!listing.workflow?.type || listing.workflow.type === ISellerListingWorkflowTypes.Standard) {
    return isStandardStepOneComplete(listing);
  }

  if (listing.workflow.type === ISellerListingWorkflowTypes.Auction) {
    return isAuctionStepOneComplete(listing);
  }

  return false;
};

export const defaultIsStepTwoComplete = (listing: ISellerListing) => {
  return !!listing?.uploadImages?.length;
};

export const defaultIsStepThreeComplete = (listing: ISellerListing) => {
  const {
    DriveType,
    Cylinders,
    Engine,
    Fuel,
    Transmission,
    BodyStyle,
    Doors,
    ExteriorColor,
    InteriorColor,
    vehicleDescription,
    hasDamage,
  } = listing;

  return !!(
    DriveType &&
    Cylinders &&
    Engine &&
    Fuel &&
    Transmission &&
    BodyStyle &&
    Doors &&
    ExteriorColor &&
    InteriorColor &&
    vehicleDescription &&
    hasDamage !== null
  );
};

export const defaultIsStepFourComplete = (listing: ISellerListing) => {
  const {
    availableTimings: { Monday, Tuesday, Wednesday, Thursday, Friday, Saturday, Sunday },
    testDriveLocation,
  } = listing;

  return (
    !!(
      Monday?.length ||
      Tuesday?.length ||
      Wednesday?.length ||
      Thursday?.length ||
      Friday?.length ||
      Saturday?.length ||
      Sunday?.length
    ) && !!testDriveLocation
  );
};

export const defaultIsStepFiveComplete = (listing: ISellerListing) => {
  const {
    titleAttachment,
    ownershipInfo: {
      vehicleHaveLien,
      bankName,
      phoneNumber,
      loanAccountNumber,
      estimatedLoanAmount,
      carTitle,
      coSeller,
    },
  } = listing;
  const titleAnswered = carTitle !== null;
  const lienAnswered =
    vehicleHaveLien === false ? true : !!(bankName && phoneNumber && loanAccountNumber && estimatedLoanAmount);
  const coSellerAnswered =
    coSeller?.onLoan === false ? true : !!(coSeller?.firstName && coSeller?.lastName && coSeller?.phoneNumber);
  const isTitleUploaded = !!titleAttachment?.completedDate;

  return lienAnswered && titleAnswered && coSellerAnswered && isTitleUploaded;
};

export const defaultIsStepSixComplete = (listing: ISellerListing) => {
  const {
    verifications: { verifiedFunds },
    paymentMethod: {
      privateAutoPay,
      cash,
      crypto: { btc },
    },
  } = listing.dealPreferences;

  return verifiedFunds && (privateAutoPay || cash || btc);
};
