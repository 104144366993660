export const cardBrandMapping = {
  visa: 'Visa',
  mastercard: 'Mastercard',
  elo: 'ELO',
  jcb: 'JCB',
  discover: 'Discover',
  diners: 'Diners Club',
  amex: 'American Express',
  unionpay: 'UnionPay',
};
