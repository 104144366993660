import { NgModule } from '@angular/core';
import { NGBDateToDatePipe, DateToNGBDatePipe } from './ngb.pipe';
import { CalculateTotalMonthsPipe, DurationValuesToTextPipe, MonthsToDurationPipe } from './duration.pipe';
import { AsteriskPipe } from './asterisk.pipe';
import { ParseAddressPipe } from './parse-address.pipe';
import { NumberFormatPipe } from './number.pipe';

@NgModule({
  imports: [],
  declarations: [
    NGBDateToDatePipe,
    DateToNGBDatePipe,
    DurationValuesToTextPipe,
    CalculateTotalMonthsPipe,
    MonthsToDurationPipe,
    AsteriskPipe,
    ParseAddressPipe,
    NumberFormatPipe,
  ],
  exports: [
    NGBDateToDatePipe,
    DateToNGBDatePipe,
    DurationValuesToTextPipe,
    CalculateTotalMonthsPipe,
    MonthsToDurationPipe,
    AsteriskPipe,
    ParseAddressPipe,
    NumberFormatPipe,
  ],
})
export class PipeModule {}
