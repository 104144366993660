import { Inject, Injectable, Renderer2, RendererFactory2 } from '@angular/core';
import { APP_CONFIG, IAppConfig } from 'src/app/config/config';

@Injectable({
  providedIn: 'root',
})
export class ScriptLoaderService {
  renderer: Renderer2;

  constructor(
    @Inject(APP_CONFIG) private readonly config: IAppConfig,
    private readonly rendererFactory: RendererFactory2
  ) {
    this.renderer = rendererFactory.createRenderer(null, null);
  }

  initialize() {
    this.loadRosterScript();
    this.loadGoogleMaps();
  }

  loadRosterScript() {
    const body: HTMLElement = document.body;
    const script = this.renderer.createElement('script');
    const { rosterKey, rosterIntegrity, websiteUrl } = this.config;
    const rootDomain = websiteUrl.split('//')[1];
    script.src = `https://sa.getroster.com/wooly-analytics.js?token=${rosterKey}&cookies=true&domain=${rootDomain}`;
    script.type = 'application/javascript';
    if (rosterIntegrity && rootDomain.indexOf('localhost') === -1) {
      script.integrity = rosterIntegrity;
      script.crossOrigin = 'anonymous';
    }
    script.id = 'roster-function';
    script.async = true;

    body.appendChild(script);
  }
  
  loadGoogleMaps() {
    const body: HTMLElement = document.body;
    const script = this.renderer.createElement('script');
    const { googleApiKey } = this.config;
    script.type = 'application/javascript';

    const scriptContent = `(g=>{var h,a,k,p="The Google Maps JavaScript API",c="google",l="importLibrary",q="__ib__",m=document,b=window;b=b[c]||(b[c]={});var d=b.maps||(b.maps={}),r=new Set,e=new URLSearchParams,u=()=>h||(h=new Promise(async(f,n)=>{await (a=m.createElement("script"));e.set("libraries",[...r]+"");for(k in g)e.set(k.replace(/[A-Z]/g,t=>"_"+t[0].toLowerCase()),g[k]);e.set("callback",c+".maps."+q);a.src=\`https://maps.googleapis.com/maps/api/js?\`+e;d[q]=f;a.onerror=()=>h=n(Error(p+" could not load."));a.nonce=m.querySelector("script[nonce]")?.nonce||"";m.head.append(a)}));d[l]?console.warn(p+" only loads once. Ignoring:",g):d[l]=(f,...n)=>r.add(f)&&u().then(()=>d[l](f,...n))})({
        v: "weekly",
        key: \`${googleApiKey}\`
      })`;
    script.textContent = scriptContent;
  
    body.appendChild(script);
    
  }
}
