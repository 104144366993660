import { Component, EventEmitter, Inject, Input, OnInit, Output, SimpleChanges, ViewChild } from '@angular/core';
import { ModalConfig } from '../modal/modal.model';
import { ModalComponent } from '../modal/modal.component';
import { APP_CONFIG, IAppConfig } from 'src/app/config/config';

@Component({
  selector: 'app-modal-verification-info',
  templateUrl: './modal-verification-info.component.html',
  styleUrls: ['./modal-verification-info.component.scss'],
})
export class ModalVerificationInfoComponent implements OnInit {
  @ViewChild('modal') private readonly modalComponent: ModalComponent;
  @Input() isOpen = false;
  @Output() isOpenChange = new EventEmitter<boolean>();
  modalConfig: ModalConfig = {};
  appName: string

  constructor(
    @Inject(APP_CONFIG) private readonly config: IAppConfig,
  ) {
    this.appName = this.config.appName;
  }

  ngOnInit(): void {
    this.modalConfig = {
      name: 'Verification Info Modal',
      beforeClose: async () => {
        this.isOpen = false;
        this.isOpenChange.emit(this.isOpen);
        return true;
      },
    };
  }

  ngOnChanges(changes: SimpleChanges): void {
    const { isOpen } = changes;

    if (isOpen?.currentValue) {
      this.modalComponent?.open();
    }
  }
}
