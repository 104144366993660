import { ISellerListing } from './seller-listing.model';

export enum TitleCheckOrderStatus {
  Pending = 'pending',
  Running = 'running',
  Success = 'success',
  Error = 'error',
  Recovery = 'recovery',
}

export enum OCROrderStatus {
  Pending = 'pending',
  Complete = 'complete',
  Error = 'error',
  Failed = 'failed',
}

export enum TitleCheckOutcomeStatus {
  Pending = 'pending',
  Running = 'running',
  Found = 'found',
  NotFound = 'not_found',
  Error = 'error',
  Recovery = 'recovery',
}

export interface Report {
  ownerInformation?: boolean;
  coOwner?: boolean;
  state?: boolean;
  vin?: boolean;
  yearMakeModel?: boolean;
  lien?: boolean;
  titleStatus?: string;
  titleIssueDate: string;
  vehicle: {
    year: string;
    make: string;
    model: string;
  };
}

export interface TitleCheckOrderModel {
  _id: string;
  status: TitleCheckOrderStatus;
  outcome: TitleCheckOutcomeStatus;
  listingId: string;
  userId: string;
  orderId?: string;
  report?: Report;
  createdAt: Date;
  updatedAt?: Date;
  state: string;
  listing?: ISellerListing;
  ocr?: any;
}
