import { Pipe, PipeTransform } from '@angular/core';
import { numberValueToMonthText, numberValueToYearText } from '../shared/forms';
import * as moment from 'moment';

@Pipe({ name: 'durationValuesToText', pure: true })
export class DurationValuesToTextPipe implements PipeTransform {
  transform({ years, months }: { years: string; months: string }) {
    if (!years || !months) return null;

    return `${numberValueToYearText(+years)}, ${numberValueToMonthText(+months)}`;
  }
}

@Pipe({ name: 'calculateTotalMonths', pure: true })
export class CalculateTotalMonthsPipe implements PipeTransform {
  transform({ years, months }: { years: string; months: string }) {
    return !years ? 0 : parseInt(years, 10) * 12 + parseInt(months || '0', 10);
  }
}

@Pipe({ name: 'monthsToDuration', pure: true })
export class MonthsToDurationPipe implements PipeTransform {
  transform(months: number) {
    const dur = moment.duration(months, 'months');
    return `${numberValueToYearText(dur.years())}, ${numberValueToMonthText(dur.months())}`;
  }
}
