import {
  Component,
  ElementRef,
  EventEmitter,
  Inject,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges,
  ViewChild,
} from '@angular/core';
import { Router } from '@angular/router';
import { DOCUMENT } from '@angular/common';
import { timer } from 'rxjs';
import { ModalComponent } from '../modal/modal.component';
import { ModalConfig } from '../modal/modal.model';
import { APP_CONFIG, IAppConfig } from 'src/app/config/config';

@Component({
  selector: 'app-modal-view-pricing',
  templateUrl: './modal-view-pricing.component.html',
  styleUrls: ['./modal-view-pricing.component.scss'],
})
export class ModalViewPricingComponent implements OnInit, OnChanges {
  @ViewChild('modal') private readonly modalComponent: ModalComponent;
  @Input() isOpen = false;
  @Input() scrollToClosingHeader = false;
  @Output() isOpenChange = new EventEmitter<boolean>();
  @ViewChild('closingHeader') closingHeader: ElementRef;

  @Output() onClose = new EventEmitter();

  modalConfig: ModalConfig = {};

  sellerBenefits = [
    'Free renewals',
    'Vehicle history report',
    'Vehicle value report',
    'QR code window brochure',
    'Test drive scheduler',
    'Verified buyer preferences',
    'Secure chat',
  ];

  buyerBenefits: string[];
  appName: string

  constructor(
    private readonly router: Router,
    @Inject(DOCUMENT) private readonly document: Document,
    @Inject(APP_CONFIG) private readonly config: IAppConfig,
  ) {
    this.appName = this.config.appName;
    this.buyerBenefits = [
      'Buyer & seller ID verification',
      'Offer accept/reject/counter',
      'Verified buyer funds',
      'E-sign the bill of sale',
      `${this.appName} Pay`,
      'Instant money transfer P2P',
      'Available 24/7/365',
      'No transaction fees',
    ];
  }

  ngOnInit(): void {
    this.modalConfig = {
      hideLogo: true,
      modalDialogClass: 'view-pricing-modal',
      name: 'View pricing modal',
      beforeClose: async () => {
        this.isOpen = false;
        this.isOpenChange.emit(this.isOpen);
        this.onClose.emit();
        return true;
      },
    };
  }

  ngOnChanges(changes: SimpleChanges): void {
    const { isOpen } = changes;

    if (isOpen?.currentValue) {
      this.modalComponent.open();
      if (this.scrollToClosingHeader) {
        timer(500).subscribe(() => this.closingHeader.nativeElement.scrollIntoView());
      }
    }
  }

  onSellNowClick() {
    this.router.navigate(['/listing/steps']);

    this.modalComponent.close();
  }

  onDealNowClick() {
    this.router.navigateByUrl('/deals?dealNowStart');
    this.modalComponent.close();
  }
}
