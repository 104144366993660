<div class="chip-select-input d-flex flex-column gap-1">
  <div>
    <ng-content></ng-content>
  </div>
  <div class="d-flex" style="gap: 12px">
    @for (option of options; track option; let i = $index) {
      <div class="option" (click)="selectOption(option)"
        [ngClass]="{ 'selected': option.value === value }">
        <span class="label">{{option.label}}</span>
      </div>
    }
  </div>
</div>