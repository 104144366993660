import { HttpClient, HttpResponse } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { BehaviorSubject, Observable, catchError, firstValueFrom, from, of, switchMap } from 'rxjs';
import { APP_CONFIG, IAppConfig } from 'src/app/config/config';
import { ProveBusinessVerificationModel, ProveVerificationModel } from 'src/app/models/prove-verification.model';
import { AuthenticatorBuilder } from '@prove/mobile-auth';

export interface IdentityInformation {
  firstName: string;
  lastName: string;
  address: {
    address: string;
    extendedAddress: string;
    city: string;
    region: string;
    postalCode: string;
  };
  email: string;
  birthDate: string;
  ssn: string;
  phoneNumber: string;
}

interface ModalQueue {
  id: string;
  handler: Function;
}

@Injectable()
export class OnboardingService {
  verifyModalIsVisible = new BehaviorSubject<boolean>(false);
  verifyBusinessModalIsVisible = new BehaviorSubject<boolean>(false);
  welcomeBusinessVerificationModalIsVisible = new BehaviorSubject<boolean>(false);
  businessInformationModalIsVisible = new BehaviorSubject<boolean>(false);
  inviteBusinessOwnerModalIsVisible = new BehaviorSubject<boolean>(false);

  pendingModalQueue: ModalQueue[] = [];

  constructor(private readonly http: HttpClient, @Inject(APP_CONFIG) private readonly config: IAppConfig) {}

  queuePendingHandler(id: string, handler: Function) {
    if (!this.pendingModalQueue.find((e) => e.id !== id)) {
      this.pendingModalQueue.push({ id, handler });
    }
  }

  executePendingHandler() {
    if (this.pendingModalQueue.length > 0) {
      const item = this.pendingModalQueue.shift();
      item.handler();
    }
  }

  setVerifyModalVisiblity(val: boolean) {
    this.verifyModalIsVisible.next(val);
  }

  setWelcomeBusinessVerificationModalIsVisible(val: boolean) {
    this.welcomeBusinessVerificationModalIsVisible.next(val);
  }

  setVerifyBusinessModalVisibility(val: boolean) {
    this.verifyBusinessModalIsVisible.next(val);
  }

  setBusinessInformationModalIsVisible(val: boolean) {
    this.businessInformationModalIsVisible.next(val);
  }

  setInviteBusinessOwnerModalIsVisible(val: boolean) {
    this.inviteBusinessOwnerModalIsVisible.next(val);
  }

  initializeBusinessInformation(payload: { isOwner: boolean; name: string; website: string }) {
    return this.http.post(`${this.config.apiUrl}/users/onboarding/business/initialize`, payload);
  }

  sendInviteToBusinessOwner(payload: { email: string }) {
    return this.http.post(`${this.config.apiUrl}/users/onboarding/business/invite-owner`, payload);
  }

  acceptUserInvite(token, password) {
    return this.http.post(`${this.config.apiUrl}/users/onboarding/business/accept-invite`, { token, password });
  }

  prefillBusinessVerification() {
    return this.http.post<{ success: boolean; data: ProveBusinessVerificationModel }>(
      `${this.config.apiUrl}/users/onboarding/business/prefill`,
      {}
    );
  }

  startVerification(data: { phoneNumber: string; last4Ssn: string }) {
    return this.http.post(`${this.config.apiUrl}/users/onboarding/start`, data);
  }

  getOnboardingStatus(session: string) {
    return this.http.get(`${this.config.apiUrl}/users/onboarding/status`, {
      params: { session },
      headers: {
        'X-No-Loader': '1',
      },
    });
  }

  finishVerification(data: { session: string; vfp: string }) {
    return this.http.post(`${this.config.apiUrl}/users/onboarding/finish`, data);
  }

  getIdentity(session: string, consent: string) {
    return this.http.get<{ success: boolean; data: ProveVerificationModel }>(
      `${this.config.apiUrl}/users/onboarding/identity`,
      {
        params: { session, consent },
      }
    );
  }

  bypassPlaidVerification() {
    return this.http.post(
      `${this.config.apiUrl}/users/onboarding/bypass-plaid`,
      {},
      {
        headers: {
          'X-No-Loader': '1',
        },
      }
    );
  }

  verifyNoChanges(payload: { session: string; consent: string }) {
    return this.http.post(`${this.config.apiUrl}/users/onboarding/verify-no-changes`, payload);
  }

  verify(payload) {
    return this.http.post(`${this.config.apiUrl}/users/onboarding/verify`, payload);
  }

  verifyBusinessNoChanges(payload: { currentBusinessId: string }) {
    return this.http.post(`${this.config.apiUrl}/users/onboarding/business/verify-no-changes`, payload);
  }

  verifyBusiness(payload) {
    return this.http.post(`${this.config.apiUrl}/users/onboarding/business/verify`, payload);
  }

  authenticateMobileAuth(): Observable<{ success: boolean; phoneNumber: string }> {
    let session;
    const authenticator = new AuthenticatorBuilder()
      .withFetchImplementation()
      .withStartStep({
        execute: async (input: any) => {
          const response = await firstValueFrom(
            this.http.post<{ RedirectTargetUrl: string; success: boolean; session: string }>(
              `${this.config.apiUrl}/users/onboarding/mobile/start`,
              {
                ip: input.deviceDescriptor.ip,
              }
            )
          );
          session = response.session;

          if (!response.success) {
            throw new Error('Mobile auth failed');
          }
          return { authUrl: response.RedirectTargetUrl, session: response.session };
        },
      })
      .withFinishStep({
        execute: async (input: any) => {
          const response = await firstValueFrom(
            this.http.post<{ success: boolean; phoneNumber: string }>(
              `${this.config.apiUrl}/users/onboarding/mobile/finish`,
              { vfp: input.vfp, session }
            )
          );
          if (!response.success) {
            throw new Error('Mobile auth failed');
          }
          return response;
        },
      })
      .build();

    return from(authenticator.authenticate()).pipe(
      switchMap((result: any) => {
        if (result && result.phoneNumber) {
          return of({ success: true, phoneNumber: result.phoneNumber });
        } else {
          return of({ success: false, phoneNumber: null });
        }
      }),
      catchError(() => of({ success: false, phoneNumber: null }))
    );
  }

  prefillMobileAuth(params: { last4Ssn: string }) {
    return this.http.post<{ success: boolean; data: ProveVerificationModel }>(
      `${this.config.apiUrl}/users/onboarding/mobile/prefill`,
      params
    );
  }
}
