import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { CallSupportComponent } from './call-support/call-support.component';
import { GiveFeedbackComponent } from './give-feedback/give-feedback.component';
import { HelpCenterComponent } from './help-center.component';

const routes: Routes = [
  {
    path: '',
    children: [
      {
        path: 'call-support',
        component: CallSupportComponent,
      },
      { path: 'give-feedback', component: GiveFeedbackComponent },
      { path: '', component: HelpCenterComponent },
    ],
  },
  { path: '**', redirectTo: '' },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class HelpCenterRoutingModule {}
