import {
  AfterViewInit,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnChanges,
  Output,
  Renderer2,
  ViewChild,
} from '@angular/core';
import { ControlValueAccessor } from '@angular/forms';
import { MapLocationModel } from '../../models/map-location.model';
import { getValueAccessorProvider } from '../../shared/functions';

declare let google;

@Component({
  selector: 'app-places-autocomplete-input',
  templateUrl: './places-autocomplete-input.component.html',
  styleUrls: ['./places-autocomplete-input.component.scss'],
  providers: [getValueAccessorProvider(PlacesAutocompleteInput)],
})
export class PlacesAutocompleteInput implements ControlValueAccessor, AfterViewInit, OnChanges {
  @Output() onChangeEvent = new EventEmitter<MapLocationModel>();
  @Input() inputLocation: MapLocationModel;
  @Input() elementId = 'txtSearchPlaces';

  @Input() placeholder = 'Enter a location';
  public location: MapLocationModel;
  public temporaryMapLocation: MapLocationModel;
  public isShowingProposeLocation = false;
  @ViewChild('searchInput') searchInput: ElementRef;
  private readonly renderer: Renderer2;
  value: any;
  inputRan = false;

  constructor() {}

  ngAfterViewInit(): void {
    this.initAutocomplete();
    if (this.inputLocation) {
      this.writeValue(this.inputLocation);
    } else if (this.location) {
      this.writeValue(this.location);
    }
  }

  private onChange: any = (evt) => {
    this.onChangeEvent.emit(this.location);
  };

  clearSearchInput() {
    this.searchInput.nativeElement.value = '';
  }

  private onTouch: any = () => {
    //
  };
  ngOnChanges(changes: any) {
    const { inputLocation } = changes;

    if (inputLocation.currentValue && !this.inputRan) {
      this.writeValue(inputLocation.currentValue);
      this.inputRan = true;
    }
  }

  writeValue(location: MapLocationModel): void {
    if (location) {
      this.location = location;
      if (this.searchInput && this.searchInput.nativeElement) {
        this.searchInput.nativeElement.value = location.formattedAddress || '';
      }
    }
  }

  registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: any): void {
    this.onTouch = fn;
  }

  setDisabledState?(isDisabled: boolean): void {}

  setProposeLocation(shouldSaveEventLocation = true) {
    this.isShowingProposeLocation = !this.isShowingProposeLocation;
    if (this.isShowingProposeLocation) {
      return;
    }
    if (shouldSaveEventLocation && !this.isEmptyTemporaryLocation()) {
      this.location = this.temporaryMapLocation;
      this.onChange(this.location);
    }
    this.temporaryMapLocation = new MapLocationModel();
  }

  isEmptyTemporaryLocation() {
    if (!this.temporaryMapLocation) {
      return true;
    }

    return this.isEmptyObject(this.temporaryMapLocation);
  }

  isEmptyObject(object: Object) {
    if (!object) {
      return false;
    }
    return Object.values(object).every((attributeValue) => {
      if (attributeValue && typeof attributeValue === 'object') {
        return this.isEmptyObject(attributeValue);
      }
      return attributeValue === null || attributeValue === '';
    });
  }

  cancelProposalLocation() {
    this.setProposeLocation(false);
  }

  async initAutocomplete() {
    await google.maps.importLibrary('places');

    const options = {
      componentRestrictions: { country: 'us' },
    };
    const autocomplete = new google.maps.places.Autocomplete(this.searchInput.nativeElement, options);
    autocomplete.addListener('place_changed', () => {
      this.handleMapPlaceInfo(autocomplete.getPlace());
    });
  }

  handleMapPlaceInfo(place) {
    if (place === undefined || place.geometry === undefined || place.geometry === null) {
      return;
    }
    this.temporaryMapLocation = new MapLocationModel();
    this.temporaryMapLocation.locationName = place.name;
    for (const component of place.address_components as google.maps.GeocoderAddressComponent[]) {
      this.parsePlaceInfoByAddressComponent(component);
    }
    this.temporaryMapLocation.formattedAddress = place.formatted_address;
    this.temporaryMapLocation.geometry.latitude = place.geometry.location.lat();
    this.temporaryMapLocation.geometry.longitude = place.geometry.location.lng();
    this.temporaryMapLocation.isStateLevel = this.isPlaceStateLevel(place);
    this.location = this.temporaryMapLocation;
    this.onChange(this.location);
  }

  isPlaceStateLevel(place: google.maps.places.PlaceResult): boolean {
    return ['administrative_area_level_1', 'political'].every((type) =>
      place.address_components?.[0]?.types?.includes(type)
    );
  }

  private parsePlaceInfoByAddressComponent(component: google.maps.GeocoderAddressComponent) {
    const componentType = component.types[0];
    switch (componentType) {
      case 'street_number': {
        this.temporaryMapLocation.address = `${component.long_name} ${this.temporaryMapLocation.address}`;
        break;
      }
      case 'route': {
        if (component.short_name) {
          this.temporaryMapLocation.address += ` ${component.short_name}`;
        }
        break;
      }
      case 'postal_code': {
        this.temporaryMapLocation.zipcode = `${component.long_name}${this.temporaryMapLocation.zipcode}`;
        break;
      }
      case 'postal_code_suffix': {
        this.temporaryMapLocation.zipcode = `${this.temporaryMapLocation.zipcode}-${component.long_name}`;
        break;
      }
      case 'locality':
        this.temporaryMapLocation.city = component.long_name;
        break;
      case 'administrative_area_level_1': {
        this.temporaryMapLocation.stateShortname = component.short_name;
        this.temporaryMapLocation.state = component.long_name;
        break;
      }
      default:
        break;
    }
  }
}
