<app-modal #modal [modalConfig]="modalConfig">
  <div class="header">Which vehicle?</div>
  <div class="body mt-3">
    @if (inquiredlistings.length) {
    <div class="listing-items-container w-100">
      <div class="listing-items-title">Vehicles you inquired</div>
      @for (listing of inquiredlistings; track listing; let i = $index) {
      <div
        class="listing-item d-flex align-items-center mt-3 p-2"
        [ngClass]="{selected: listing._id === selectedListingId}"
        (click)="onVehicleClick(listing._id)"
      >
        <app-listing-image
          [src]="listing.img"
          [vehicleType]="listing?.vehicleType"
          [width]="80"
          [height]="64"
        ></app-listing-image>
        <div class="listing-item-details ms-3">
          <div class="listing-item-text">{{listing.RegistrationYear}} {{listing.CarMake}}</div>
          <div class="listing-item-text">{{listing.CarModel}} {{listing.Trim}}</div>
          <div class="listing-item-text">{{listing.vin}}</div>
        </div>
      </div>
      }
    </div>
    } @if (favoritelistings.length) {
    <div class="listing-items-container w-100 mt-2">
      <div class="listing-items-title">Favorites</div>
      @for (listing of favoritelistings; track listing; let i = $index) {
      <div
        class="listing-item d-flex align-items-center mt-3 p-2"
        [ngClass]="{selected: listing._id === selectedListingId}"
        (click)="onVehicleClick(listing._id)"
      >
        <app-listing-image [src]="listing.img" [width]="80" [height]="64"></app-listing-image>
        <div class="listing-item-details ms-3">
          <div class="listing-item-text">{{listing.RegistrationYear}} {{listing.CarMake}}</div>
          <div class="listing-item-text">{{listing.CarModel}} {{listing.Trim}}</div>
          <div class="listing-item-text">{{listing.vin}}</div>
        </div>
      </div>
      }
    </div>
    } @if (usersListings.length) {
    <div class="listing-items-container w-100">
      <div class="listing-items-title">Your vehicles</div>
      @for (listing of usersListings; track listing; let i = $index) {
      <div
        class="listing-item d-flex align-items-center mt-3 p-2"
        [ngClass]="{selected: listing._id === selectedListingId}"
        (click)="onVehicleClick(listing._id)"
      >
        <app-listing-image
          [src]="listing.img"
          [vehicleType]="listing?.vehicleType"
          [width]="80"
          [height]="64"
        ></app-listing-image>
        <div class="listing-item-details ms-3">
          <div class="listing-item-text">{{listing.RegistrationYear}} {{listing.CarMake}}</div>
          <div class="listing-item-text">{{listing.CarModel}} {{listing.Trim}}</div>
          <div class="listing-item-text">{{listing.vin}}</div>
        </div>
      </div>
      }
    </div>
    }

    @if (isBrowseListingEnabled) {
      <div class="no-vehicles-found text-center mt-4 py-2">
        <div class="fw-bold">No vehicles found?</div>
        <div class="pb-1">Search for a vehicle and add it to your favorites or message a seller.</div>
        <button class="btn-primary mt-2" (click)="onStartSearchingClick()">Start searching</button>
      </div>
    }

    <button
      [disabled]="!selectedListingId"
      class="btn btn-primary w-100 mt-4 p-2 fw-bold mb-5"
      (click)="onChooseVehicleClick()"
    >
      Choose vehicle
    </button>
  </div>
</app-modal>
