import { Component, EventEmitter, Input, OnInit, Output, SimpleChanges } from '@angular/core';
import { UsersService } from 'src/app/core/services/users.service';
import { IUser } from 'src/app/models/user.model';

@Component({
  selector: 'app-widgets-modal-phone-verify',
  templateUrl: './modal-phone-verify.component.html',
  styleUrls: ['./modal-phone-verify.component.scss'],
})
export class ModalPhoneVerifyComponent implements OnInit {
  @Input() skipButton = false;
  @Input() showVerify = false;
  @Input() titleVerify: string;
  @Input() enableEditPhone = true;

  @Input() isOpen = false;
  @Output() isOpenChange = new EventEmitter<boolean>();

  @Output() onVerify = new EventEmitter<boolean>();
  @Output() onSkip = new EventEmitter<boolean>();
  @Output() onEditSuccess = new EventEmitter<boolean>();

  user: IUser;
  isUserVerified = false;
  showModalVerify = false;
  showModalEdit = false;
  editTitle = 'Edit phone number';

  constructor(private readonly usersService: UsersService) {}

  ngOnInit(): void {
    this.usersService.user.subscribe((user) => {
      this.user = user;
      this.isUserVerified = !!(user.userDetails.phoneNumber && user.verification.phone);
    });
  }

  ngOnChanges(changes: SimpleChanges): void {
    const { isOpen } = changes;
    this.editTitle = 'Edit phone number';

    if (isOpen?.currentValue) {
      this.openModal(isOpen?.currentValue);
    }
  }

  openModal(isOpen) {
    if (isOpen) {
      if (!this.user.userDetails.phoneNumber) {
        this.editTitle = 'Verify your phone';
        this.showModalEdit = true;
        return;
      }
      if (this.isUserVerified && !this.showVerify) {
        this.showModalEdit = true;
      } else {
        this.showModalVerify = true;
      }
    }
  }

  onClose() {
    if (!this.showModalVerify && !this.showModalEdit) {
      this.isOpen = false;
      this.isOpenChange.emit(false);
    }
  }

  onPhoneVerify() {
    this.onVerify.emit();
  }

  onPhoneSkip() {
    this.onSkip.emit();
  }

  handleEditSuccess() {
    this.showModalVerify = true;
  }
}
