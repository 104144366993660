import { UntypedFormArray, UntypedFormGroup, ValidatorFn } from '@angular/forms';

export function conditionalValidator(predicate: any, validator: ValidatorFn): ValidatorFn {
  return (formControl) => {
    if (!formControl.parent) return null;

    return predicate() ? validator(formControl) : null;
  };
}

export function markAllAsTouched(formGroup: UntypedFormGroup) {
  (Object as any).values(formGroup.controls).forEach((control: any) => {
    control.markAsTouched();
    if (control.controls) {
      markAllAsTouched(control);
    }
  });
}

export function markAllAsDirty(formGroup: UntypedFormGroup) {
  Object.keys(formGroup.controls).forEach((key) => {
    formGroup.get(key).markAsDirty();
  });
}

export function updateTreeValidity(group: UntypedFormGroup | UntypedFormArray): void {
  Object.keys(group.controls).forEach((key: string) => {
    const abstractControl = group.controls[key];

    if (abstractControl instanceof UntypedFormGroup || abstractControl instanceof UntypedFormArray) {
      updateTreeValidity(abstractControl);
    } else {
      abstractControl.updateValueAndValidity();
    }
  });
}

export function numberValueToYearText(value: number) {
  const num = value === 0 ? 1 : value;
  const res = `${num} year${value > 1 ? 's' : ''}`;

  const sign = (() => {
    if (value === 0) return '< ';
    if (value === 10) return '+ ';

    return '';
  })();

  return `${sign}${res}`;
}

export function numberValueToMonthText(value: number) {
  return `${value} month${value > 1 ? 's' : ''}`;
}

export function generateYearsList(amount: number): any {
  return [...Array(amount).keys()].reduce((acc, curr) => {
    return { ...acc, [curr]: numberValueToYearText(curr) };
  }, {});
}

export function generateMonthsList(amount: number): any {
  return [...Array(amount).keys()].reduce((acc, curr) => {
    if (curr === 0) return acc;
    return { ...acc, [curr]: numberValueToMonthText(curr) };
  }, {});
}
