import { Component, Inject } from '@angular/core';
import { APP_CONFIG, IAppConfig } from 'src/app/config/config';

@Component({
  selector: 'app-call-support',
  templateUrl: './call-support.component.html',
  styleUrl: './call-support.component.scss',
})
export class CallSupportComponent {
  constructor(@Inject(APP_CONFIG) readonly config: IAppConfig) {}
}
