import { NgModule } from '@angular/core';
import { FormatCurrencyInputDirective } from './format-currency-input.directive';
import { DateMaskDirective } from './date-mask.directive';
import { SSNMaskDirective } from './ssn-mask.directive';

@NgModule({
  imports: [],
  declarations: [FormatCurrencyInputDirective, DateMaskDirective, SSNMaskDirective],
  exports: [FormatCurrencyInputDirective, DateMaskDirective, SSNMaskDirective],
})
export class DirectiveModule {}
