import {
  Component,
  OnInit,
  Input,
  Output,
  EventEmitter,
  SimpleChanges,
  OnChanges,
  ViewChild,
  ViewEncapsulation,
  ElementRef,
  HostListener,
} from '@angular/core';
import { ModalComponent } from 'src/app/widgets/modal/modal.component';
import { ModalConfig } from 'src/app/widgets/modal/modal.model';
import SignaturePad from 'signature_pad';
import { NotificationService } from 'src/app/core/services/notification.service';
import { IUserSignature } from 'src/app/models/user.model';

enum Tabs {
  Type = 'type',
  Draw = 'draw',
}

@Component({
  selector: 'app-modal-signature',
  templateUrl: './modal-signature.component.html',
  styleUrls: ['./modal-signature.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class ModalSignatureComponent implements OnInit, OnChanges {
  @ViewChild('modal') private readonly modalComponent: ModalComponent;
  @ViewChild('typeSignatureCanvas') private readonly typeSignatureCanvas: ElementRef<HTMLCanvasElement>;
  @ViewChild('fullName') private readonly fullNameEl: ElementRef<HTMLInputElement>;
  @ViewChild('drawSignatureCanvas') private readonly drawSignatureCanvas: ElementRef<HTMLCanvasElement>;

  @Input() isOpen = false;
  @Output() isOpenChange = new EventEmitter<boolean>();

  @Output() onClose = new EventEmitter<any>();
  @Output() onSign = new EventEmitter<IUserSignature>();

  signaturePad: SignaturePad;
  modalConfig: ModalConfig = {};
  loading = false;
  activeTab = Tabs.Draw;
  tabs = Tabs;
  emptyCanvas: string;

  constructor(private readonly notificationService: NotificationService) {}

  @HostListener('window:resize')
  resizeCanvas() {
    [this.typeSignatureCanvas?.nativeElement, this.drawSignatureCanvas?.nativeElement].forEach((canvas) => {
      if (!canvas) {
        return;
      }

      const ctx = canvas.getContext('2d');
      const ratio = Math.max(window.devicePixelRatio || 1, 1);
      canvas.width = canvas.offsetWidth * ratio;
      canvas.height = canvas.offsetHeight * ratio;
      ctx.scale(ratio, ratio);
      ctx.clearRect(0, 0, canvas.width, canvas.height);
    });

    this.emptyCanvas =
      this.activeTab === this.tabs.Type
        ? this.typeSignatureCanvas?.nativeElement.toDataURL()
        : this.drawSignatureCanvas?.nativeElement.toDataURL();
  }

  ngOnInit(): void {
    this.modalConfig = {
      name: 'Signature Modal',
      trackevent: true,
      modalDialogClass: 'signature',
      beforeClose: async () => {
        this.isOpen = !this.isOpen;

        this.isOpenChange.emit(this.isOpen);
        this.onClose.emit();
        return true;
      },
    };
  }

  ngOnChanges(changes: SimpleChanges) {
    const { isOpen } = changes;

    if (isOpen?.currentValue) {
      this.loading = false;
      this.signaturePad?.clear();
      this.modalComponent.open();
      setTimeout(() => this.resizeCanvas(), 500);

      const nextId = this.activeTab;
      this.onNavChange({ nextId });
    }
  }

  onNavChange({ nextId }) {
    this.activeTab = nextId;

    if (nextId === Tabs.Draw && !this.signaturePad) {
      this.signaturePad = new SignaturePad(this.drawSignatureCanvas.nativeElement);
    }

    this.fullNameEl.nativeElement.value = '';

    setTimeout(() => this.resizeCanvas(), 500);
  }

  drawFullName(fullName) {
    const canvas = this.typeSignatureCanvas.nativeElement;
    const ctx = canvas.getContext('2d');
    ctx.font = this.getCanvasFont(canvas, fullName);
    ctx.textAlign = 'center';

    const { offsetWidth, offsetHeight } = canvas;
    ctx.clearRect(0, 0, offsetWidth, offsetHeight);

    const x = offsetWidth / 2;
    ctx.fillText(fullName, x, 50);
  }

  getCanvasFont(canvas: HTMLCanvasElement, text: string) {
    const ctx = canvas.getContext('2d');
    const maxWidth = canvas.offsetWidth - 20;
    const getFont = (size) => `italic 400 ${size}px "Brush Script MT"`;
    let fontSize = 46;
    ctx.font = getFont(fontSize);
    let width = ctx.measureText(text).width;

    if (width > maxWidth) {
      let decrement = 1;
      while (width > maxWidth) {
        fontSize -= decrement;
        ctx.font = getFont(fontSize);
        const currentWidth = ctx.measureText(text).width;

        if (currentWidth < maxWidth && decrement === 1) {
          decrement = 0.1;
          fontSize += 1;
        } else {
          width = currentWidth;
        }
      }
    }

    return getFont(fontSize);
  }

  onSignClick() {
    const signatureImg =
      this.activeTab === this.tabs.Type
        ? this.typeSignatureCanvas.nativeElement.toDataURL()
        : this.signaturePad.toDataURL();

    if (signatureImg === this.emptyCanvas) {
      this.notificationService.notification('warning', 'Please enter your signature.');
      return;
    }

    const signature = {
      image: signatureImg,
      type: this.activeTab,
    };

    this.onSign.emit(signature);
    this.modalComponent.close();
  }

  onClearSign() {
    this.signaturePad?.clear();
  }
}
