import { Component, EventEmitter, Input, OnInit, Output, forwardRef } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { getValueAccessorProvider } from 'src/app/shared/functions';

interface ChipSelectInputOption {
  label: string;
  value: unknown;
}
@Component({
  selector: 'app-chip-select-input',
  templateUrl: './chip-select-input.component.html',
  styleUrls: ['./chip-select-input.component.scss'],
  providers: [getValueAccessorProvider(ChipSelectInputComponent)], // eslint-disable-line
})
export class ChipSelectInputComponent implements OnInit, ControlValueAccessor {
  @Output() onChangeEvent = new EventEmitter<unknown>();

  @Input()
  options: ChipSelectInputOption[] = [];

  @Input() name: string;

  value: any;

  private onChange = (_: any) => {};

  onTouched = () => {};

  writeValue(value: any): void {
    this.value = value;
  }

  selectOption(option) {
    this.value = option.value;
    this.onChange(this.value); // Call the onChange method here
    this.onChangeEvent.emit(this.value);
    this.onTouched();
  }

  registerOnChange(fn: (value: any) => void): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: () => void): void {
    this.onTouched = fn;
  }

  ngOnInit() {}

  setDisabledState?(isDisabled: boolean): void {}
}
