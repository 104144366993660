<div class="help-page">
  <div class="d-flex justify-content-between header">
    <img src="assets/right-arrow.svg" (click)="backClick()" alt="back" />
    <div>Support</div>
    <div></div>
  </div>
  @if(!config.isDealNow){
  <div>
    <div class="mt-1 mb-3">Helpful articles</div>
    <!-- Should be 2x2 for desktop, scrollable on mobile -->
    <div class="articles">
      <div class="article-item">
        <div class="title">What is {{appName}} Pay?</div>
        <a
          href="https://support.privateauto.com/article/55-what-is-privateauto-pay"
          target="_blank"
          rel="noopener"
          class="link"
          >View article</a
        >
      </div>
      <div class="article-item">
        <div class="title">How to create a new listing</div>
        <a
          href="https://support.privateauto.com/article/27-how-to-create-a-new-listing"
          target="_blank"
          rel="noopener"
          class="link"
          >View article</a
        >
      </div>
      <div class="article-item">
        <div class="title">How to create a DealNow</div>
        <a
          href="https://support.privateauto.com/article/268-how-to-create-a-dealnow-deal"
          target="_blank"
          rel="noopener"
          class="link"
          >View article</a
        >
      </div>
      <div class="article-item">
        <div class="title">How to share documents with a buyer</div>
        <a
          href="https://support.privateauto.com/article/243-how-to-share-documents-with-a-buyer"
          target="_blank"
          rel="noopener"
          class="link"
          >View article</a
        >
      </div>
    </div>
  </div>
  }
  <div>
    @if(!config.isDealNow){
    <div class="more-title my-3">More Help</div>
    }
    <div class="my-2 help-item d-flex justify-content-between align-items-center" (click)="openBeacon('/')">
      <div><img src="assets/help-circle-black.svg" alt="help-center" />Help center</div>
      <img src="assets/left-arrow.svg" alt="go-arrow" />
    </div>
    <div
      class="my-2 help-item d-flex justify-content-between align-items-center"
      (click)="openBeacon( '/ask/message/')"
    >
      <div><img src="assets/chat-bubble-black.svg" alt="message-support" />Message Support</div>
      <img src="assets/left-arrow.svg" alt="go-arrow" />
    </div>
    <a routerLink="/help-center/call-support" class="my-2 help-item d-flex justify-content-between align-items-center">
      <div><img src="assets/phone-icon-black.svg" alt="call-support" />Call support</div>
      <img src="assets/left-arrow.svg" alt="go-arrow" />
    </a>
    <a routerLink="/help-center/give-feedback" class="my-2 help-item d-flex justify-content-between align-items-center">
      <div><img src="assets/star-black.svg" alt="feedback" />Give us feedback</div>
      <img src="assets/left-arrow.svg" alt="go-arrow" />
    </a>
  </div>
</div>
