import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class NavbarService {
  private readonly navChangeSource = new BehaviorSubject<boolean>(false);
  private readonly isMobileChangeSource = new BehaviorSubject<boolean>(false);
  visibilityChanged$ = this.navChangeSource.asObservable();

  isMobileChanged$ = this.isMobileChangeSource.asObservable();

  constructor() {
    // Empty constructor
  }

  hide() {
    this.navChangeSource.next(false);
  }

  show() {
    this.navChangeSource.next(true);
  }

  setIsMobile(arg: boolean) {
    this.isMobileChangeSource.next(arg);
  }

  hideIfMobile() {
    const isMobile = this.isMobileChangeSource.getValue();
    if (isMobile) {
      this.navChangeSource.next(false);
    }
  }
}
