import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges, ViewChild } from '@angular/core';
import { PaymentMethodResult } from '@stripe/stripe-js';
import { DealsService, IUsalliance, NotificationService, UsallianceService } from 'src/app/core/services';
import { ModalComponent } from '../modal/modal.component';
import { ModalConfig } from '../modal/modal.model';

@Component({
  selector: 'app-modal-failed-pay-later',
  templateUrl: './modal-failed-pay-later.component.html',
  styleUrls: ['./modal-failed-pay-later.component.scss'],
})
export class ModalFailedPayLaterComponent implements OnInit, OnChanges {
  @ViewChild('modal') private readonly modalComponent: ModalComponent;
  @Input() isOpen = false;
  @Output() isOpenChange = new EventEmitter<boolean>();

  @Input() unpaidListings: IUsalliance['account']['unpaidListings'];

  modalConfig: ModalConfig = {};
  items: {
    name: string;
    description: string;
    amount: number;
  }[];

  total = 0;
  stripeFormBtnTxt = '';

  constructor(
    private readonly notificationService: NotificationService,
    private readonly dealsService: DealsService,
    private readonly usaService: UsallianceService
  ) {}

  ngOnInit(): void {
    this.modalConfig = {
      name: 'Failed pay later modal',
      beforeClose: async () => {
        this.isOpen = !this.isOpen;
        this.isOpenChange.emit(this.isOpen);
        return true;
      },
    };
  }

  ngOnChanges(changes: SimpleChanges): void {
    const { isOpen, unpaidListings } = changes;

    if (isOpen?.currentValue) {
      this.modalComponent?.open();
    }

    if (unpaidListings?.currentValue) {
      this.setItems(unpaidListings.currentValue);
    }
  }

  setItems(unpaidListings: IUsalliance['account']['unpaidListings']) {
    this.items = unpaidListings.deals.map((d) => {
      const { payLater } = d.listing;
      return {
        name: 'Service fee',
        description: 'Pay Later when the vehicle sells',
        amount: (payLater.amount - payLater.discount) / 100,
      };
    });
    this.total = this.items.reduce((a, i) => a + i.amount, 0);
    this.stripeFormBtnTxt = `Pay $${this.total}`;
  }

  onStripeFormSubmit({ paymentMethod }: PaymentMethodResult) {
    this.dealsService.settleBalance(paymentMethod.id).subscribe((result) => {
      if (result.success) {
        this.notificationService.notification('success', result.message);
        this.usaService.getAccount().subscribe(() => {
          this.modalComponent.close();
        });
      }
    });
  }
}
