import { ActivatedRouteSnapshot, RouterStateSnapshot, Router } from '@angular/router';
import { Inject, Injectable } from '@angular/core';
import { Auth } from 'aws-amplify';
import { GonativeService, UsersService } from '../services';
import { APP_CONFIG, IAppConfig } from '../../config/config';

@Injectable()
export class AuthGuard  {
  constructor(
    private readonly router: Router,
    private usersService: UsersService,
    private readonly goNativeService: GonativeService,
    @Inject(APP_CONFIG) private readonly appConfig: IAppConfig
  ) {}

  private callbackTokensExist = false;
  async canActivateChild(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    return Auth.currentAuthenticatedUser()
      .then((user) => {
        return !!user;
      })
      .catch((error) => {
        if (route.routeConfig.path === ':slug') {
          const path = state.url;
          window.open(`${this.appConfig.websiteUrl}${path}`, '_self');
        }
        this.router.navigate(['auth/login']);
        return false;
      });
  }

  async canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    this.usersService.cognitoData.subscribe((cognitoData) => {
      if (cognitoData.access_token) {
        this.callbackTokensExist = true;
      }
      return true;
    });

    if (this.callbackTokensExist) {
      return true;
    }

    const path = state.url;
    const allowedRoutes = ['listing/'];
    const disallowedRoutes = ['listing/steps'];
    let routeLocation = '';
    if (allowedRoutes.some((r) => path.includes(r)) && disallowedRoutes.every((r) => !path.includes(r))) {
      if (path.includes('listing/brochure')) {
        return true;
      }
      routeLocation = this.goNativeService.detectIfNativeApp()
        ? `${this.appConfig.appUrl}/auth/login`
        : `${this.appConfig.websiteUrl}${path}`;
    }

    return Auth.currentAuthenticatedUser()
      .then((user) => {
        return !!user;
      })
      .catch((error) => {
        if (routeLocation) {
          window.location.href = routeLocation;
        } else {
          this.router.navigate(['auth/login']);
        }
        return false;
      });
  }
}
