import { Injectable } from '@angular/core';

declare let gtag: Function;
declare let rdt: Function;

@Injectable({
  providedIn: 'root',
})
export class GoogleAnalyticsService {
  public eventEmitter(
    eventName: string,
    eventCategory: string,
    eventAction: string,
    eventLabel: string = null,
    eventValue: number = null
  ) {
    gtag('event', eventName, {
      eventCategory,
      eventLabel,
      eventAction,
      eventValue,
    });
  }

  gaEvent(params: {}) {
    gtag({ ...params });
  }

  trackReditEvent(eventName, properties = {}) {
    rdt('track', eventName, properties);
  }
}
