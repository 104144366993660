import { IUser } from '../models/user.model';
import { ISellerListing } from '../models/seller-listing.model';

export enum InspectionProductTypes {
  Standard = 'standard',
  Classic = 'classic',
  Exotic = 'exotic',
}

export enum OrderTypes {
  Motorcycle = 'Motorcycle',
  Standard = 'Standard',
  Classic = 'Classic',
  Exotic = 'Exotic',
  RVMotorhomesClassB = 'RV Motorhomes -Class B',
  Commercial = 'Commercial',
  Mechanical = 'Mechanical',
  RVPreWarranty = 'RV Pre-Warranty',
  PreWarranty = 'Pre-Warranty',
}

export interface OrderTypeDetails {
  name: OrderTypes;
  retailPrice: string;
  wholesalePrice: string;
}

export enum InspectionOrderStatuses {
  Waiting = 'Waiting',
  Declined = 'Declined',
  Unpaid = 'Unpaid',
  Paid = 'Paid',
  InProgress = 'In Progress',
  Complete = 'Complete',
  Refunded = 'Refunded',
  OnHold = 'On Hold',
  Cancelled = 'Cancelled',
  Submitted = 'Submitted',
  Reviewed = 'Reviewed',
  Pending = 'Pending',
  Deleted = 'Deleted',
  Dormant = 'Dormant',
  Proposal = 'Proposal',
}

export interface InspectionAddress {
  address: string;
  city: string;
  state: string;
  zip: string;
}

export interface InspectionOrder {
  _id: string;
  product: InspectionProductTypes;
  listingId: ISellerListing;
  initiatedBy: string;
  initiator?: IUser;
  buyerId?: string;
  sellerId?: string;
  orderId: string;
  provider: string;
  status: InspectionOrderStatuses;
  inspectionAddress: InspectionAddress;
  reportPath?: string;
  reportNumber?: string;
  reportDate?: Date;
  additionalNotes?: string;
  createdAt?: string;
  updatedAt?: string;
}
