export enum IDealNowDealTypes {
  A = 'a',
  B = 'b',
  C = 'c',
}

export interface IDealNowDeal {
  _id: string;
  type: IDealNowDealTypes;
  listingId: string;
  initiatedBy: string;
  createdAt: Date;
  updatedAt: Date;
  offerPrice?: number;
  responders: { userId: string }[];
}
