import { Component, Input, forwardRef } from '@angular/core';
import { NG_VALUE_ACCESSOR, ControlValueAccessor } from '@angular/forms';
import { MapLocationModel } from 'src/app/models';
import STATES from 'src/app/statics/states-hash';

@Component({
  selector: 'app-address-form',
  templateUrl: './address-form.component.html',
  styleUrls: ['./address-form.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => AddressFormComponent),
      multi: true,
    },
  ],
})
export class AddressFormComponent implements ControlValueAccessor {
  address: any = new MapLocationModel();

  @Input()
  errors: any;

  stateOptions = STATES.map((state) => state.name);

  onTouched = () => {};
  onChange = (_: any) => {};

  writeValue(obj: any): void {
    if (obj) {
      this.address = { ...this.address, ...obj };
    }
  }

  registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }

  setDisabledState?(isDisabled: boolean): void {}

  onInputBlur(): void {
    this.onTouched();
  }

  onModelChange(property: string, value: any): void {
    delete this.errors[property];
    this.address[property] = value;
    this.onChange(this.address);
  }
}
