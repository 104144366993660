import { Component, Inject, OnInit } from '@angular/core';
import { Location } from '@angular/common';
import { APP_CONFIG, IAppConfig } from '../config/config';

declare let Beacon;

@Component({
  selector: 'app-help-center',
  templateUrl: './help-center.component.html',
  styleUrls: ['./help-center.component.scss'],
})
export class HelpCenterComponent implements OnInit {
  appName: string;
  constructor(private readonly location: Location, @Inject(APP_CONFIG) readonly config: IAppConfig) {
    this.appName = this.config.appName;
  }

  ngOnInit(): void {
    Beacon(
      'init',
      this.config.isDealNow ? 'd10730b7-ef38-4103-bd48-37ef71737dbd' : '239c3036-a89d-40a9-86c3-41b3dd085f31'
    );
  }

  openBeacon(params = '/') {
    Beacon('open');
    Beacon('navigate', params);
  }

  backClick() {
    this.location.back();
  }
}
