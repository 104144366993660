import { Component, EventEmitter, Input, Output, SimpleChanges, ViewChild } from '@angular/core';
import { ModalConfig } from '../modal/modal.model';
import { ModalComponent } from '../modal/modal.component';

@Component({
  selector: 'app-modal-chat-limit',
  templateUrl: './modal-chat-limit.component.html',
  styleUrl: './modal-chat-limit.component.scss',
})
export class ModalChatLimitComponent {
  modalConfig: ModalConfig;

  @ViewChild('modal') private readonly modalComponent: ModalComponent;
  @Input() isOpen = false;
  @Input() isVerified = false;

  @Output() isOpenChange = new EventEmitter<boolean>();

  ngOnInit(): void {
    this.modalConfig = {
      name: 'Chat Limit Modal',
      trackevent: true,
      beforeClose: async () => {
        this.isOpen = !this.isOpen;
        this.isOpenChange.emit(this.isOpen);
        return true;
      },
    };
  }

  ngOnDestroy(): void {
    // AutoUnsubscribe
  }

  ngOnChanges(changes: SimpleChanges): void {
    const { isOpen } = changes;

    if (isOpen?.currentValue) {
      this.modalComponent.open();
    }
  }
  closeModal() {
    this.modalComponent.close();
  }
}
