import * as moment from 'moment';
import {
  ISellerListing,
  ISellerListingStatuses,
  ISellerListingUploadImage,
  ISellerListingUploadVideo,
  ListingCategoryTypes,
  UploadedVideoTypes,
} from '../models';
import { ListingStepNames, getListingsStepsField } from '../config/listing-steps';
import { NotificationService } from '../core/services';

const isStepComplete = (listing: ISellerListing, stepName: ListingStepNames) => {
  const config = getListingsStepsField(listing.vehicleType);
  return config[stepName].isComplete(listing);
};

export const isStepOneComplete = (listing: ISellerListing) => {
  return isStepComplete(listing, 'stepOne');
};

export const isStepTwoComplete = (listing: ISellerListing) => {
  return isStepComplete(listing, 'stepTwo');
};

export const isStepThreeComplete = (listing: ISellerListing) => {
  return isStepComplete(listing, 'stepThree');
};

export const isStepFourComplete = (listing: ISellerListing) => {
  return isStepComplete(listing, 'stepFour');
};

export const isStepFiveComplete = (listing: ISellerListing) => {
  return isStepComplete(listing, 'stepFive');
};

export const isStepSixComplete = (listing: ISellerListing) => {
  return isStepComplete(listing, 'stepSix');
};

export const getListingMainImage = (listing) => {
  const getDefaultImage = (vehicleType) => {
    switch (vehicleType) {
      case ListingCategoryTypes.Boat:
        return 'new-listing-boat.svg';
      case ListingCategoryTypes.RV:
        return 'new-listing-rv.svg';
      default:
        return 'new-listing-car.svg';
    }
  };
  if (listing.uploadImages && listing.uploadImages[0]) {
    return listing.uploadImages[0]?.images;
  } else {
    return `assets/create-listing/${getDefaultImage(listing.vehicleType)}`;
  }
};

export const formatListing = (listing: ISellerListing): ISellerListing => {
  const paymentDate = listing.listingLiveStartDate ? listing.listingLiveStartDate : listing.payment?.date;
  const days90 = moment(paymentDate).utc(true).add(90, 'days');
  const days120 = moment(paymentDate).utc(true).add(120, 'days');
  const days75 = moment(paymentDate).utc(true).add(75, 'days');

  const diff90 = days90.diff(moment(), 'days');
  const diff120 = days120.diff(moment(), 'days');
  const diff75 = days75.diff(moment(), 'days');

  const mainImg = getListingMainImage(listing);
  const stepOneComplete = isStepOneComplete(listing);
  const stepTwoComplete = isStepTwoComplete(listing);
  const stepThreeComplete = isStepThreeComplete(listing);
  const stepFourComplete = isStepFourComplete(listing);
  const stepFiveComplete = isStepFiveComplete(listing);
  const stepSixComplete = isStepSixComplete(listing);

  return {
    ...listing,
    diff90,
    diff120,
    diff75,
    mainImg,
    stepOneComplete,
    stepTwoComplete,
    stepThreeComplete,
    stepFourComplete,
    stepFiveComplete,
    stepSixComplete,
  };
};

interface ConvertDecodedVinInfoToListingResultListing
  extends Pick<
    ISellerListing,
    | 'RegistrationYear'
    | 'CarMake'
    | 'CarModel'
    | 'mpg'
    | 'DriveType'
    | 'Cylinders'
    | 'Doors'
    | 'Transmission'
    | 'Fuel'
    | 'BodyStyle'
    | 'Engine'
  > {}

interface ConvertDecodedVinInfoToListingResult {
  listing: ConvertDecodedVinInfoToListingResultListing;
  trimOptions: string[];
}

export const convertDecodedVinInfoToListing = (info): ConvertDecodedVinInfoToListingResult => {
  const { data, infoData, trimOptions = [] } = info;
  const { year: RegistrationYear, make: CarMake, model: CarModel } = data.attributes;
  let mpg = null,
    DriveType = null,
    Cylinders = null,
    Doors = null,
    Transmission = null,
    Fuel = null,
    BodyStyle = null,
    Engine = null;

  if (infoData) {
    ({
      mpg = null,
      DriveType = null,
      Cylinders = null,
      Doors = null,
      Transmission = null,
      FuelType: Fuel = null,
      BodyStyle = null,
      EngineSize: Engine = null,
    } = infoData);
  }
  const listing: ConvertDecodedVinInfoToListingResultListing = {
    RegistrationYear,
    CarMake,
    CarModel,
    mpg,
    DriveType,
    Cylinders,
    Doors,
    Transmission,
    Fuel,
    BodyStyle,
    Engine,
  };

  return {
    listing,
    trimOptions,
  };
};

export interface SortMediaOrderItemImage {
  type: 'image';
  order: number | null;
  data: ISellerListingUploadImage;
}

export interface SortMediaOrderItemVideo {
  type: 'video';
  order: number | null;
  data: ISellerListingUploadVideo;
}

export type SortMediaOrderItem = SortMediaOrderItemImage | SortMediaOrderItemVideo;

export const SortMediaOrder = (uploadImages = [], uploadVideos = []) => {
  const images = uploadImages.map((image) => ({ type: 'image', data: image }));
  const videos = uploadVideos.map((video) => ({ type: 'video', data: video }));

  const combined: SortMediaOrderItem[] = [...images, ...videos].map((item) => {
    const order = item.data.order ?? null;
    const type = item.type as SortMediaOrderItem['type'];
    return { ...item, order, type };
  });

  combined.sort((a, b) => {
    if (a.order === null && b.order === null) {
      return 0;
    } else if (a.order === null) {
      return 1;
    } else if (b.order === null) {
      return -1;
    } else {
      return a.order - b.order;
    }
  });

  return combined;
};

export const parseSortedMediaList = (
  list: SortMediaOrderItem[],
  listing: ISellerListing
): Pick<ISellerListing, 'uploadImages' | 'uploadVideos'> => {
  const uploadImages = list
    .filter((item) => item.type === 'image')
    .map((item) => {
      const { images, primaryStatus } = item.data as ISellerListingUploadImage;
      const order = list.indexOf(item);
      return { images, primaryStatus, order };
    });

  const customVideos = list
    .filter((item) => item.type === 'video')
    .map((item) => {
      const data = item.data as ISellerListingUploadVideo;
      const order = list.indexOf(item);
      return { ...data, order };
    });

  const otherVideos = listing.uploadVideos.filter((video) => video.type !== UploadedVideoTypes.Custom);
  const uploadVideos = [...customVideos, ...otherVideos];

  return { uploadImages, uploadVideos };
};

export const removeItemFromSortedList = (
  item: SortMediaOrderItem,
  list: SortMediaOrderItem[],
  listing: ISellerListing,
  notificationService: NotificationService
): SortMediaOrderItem[] => {
  const { status } = listing;
  const { uploadImages } = parseSortedMediaList(list, listing);

  if (item.type === 'image' && uploadImages.length === 1 && status === ISellerListingStatuses.Live) {
    notificationService.notification('warning', 'Unable to remove the remaining photo.');
    return;
  }

  let updatedList = list.filter((listItem) => listItem !== item);

  // If updatedList's first element is a video, find the next image and set it as cover
  if (updatedList[0] && updatedList[0].type === 'video') {
    const newCoverImage = updatedList.find((listItem) => listItem.type === 'image');

    if (newCoverImage) {
      updatedList = updatedList.filter((listItem) => listItem !== newCoverImage);
      updatedList.unshift(newCoverImage);
      notificationService.notification('info', 'The next image was assigned as the cover.');
    }
  }

  return updatedList;
};
