import { Injectable, Inject } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { APP_CONFIG, IAppConfig } from 'src/app/config/config';
import { DOCUMENT } from '@angular/common';
import { map } from 'rxjs/operators';
import { IListingDocumentBasic } from 'src/app/deals/documents.models';
import { ISellerListing } from 'src/app/models';

export enum DocumentUserTypes {
  Listings = 'listings',
  TitleFront = 'titleFront',
  TitleBack = 'titleBack',
  Deals = 'deals',
  Loans = 'loans',
  ManualReviews = 'manualReview',
}

export interface DocumentsUsersItem {
  _id: string;
  key: string;
  name: string;
  filename: string;
  mimetype: string;
  type: DocumentUserTypes;
  sharedDeals: string[];
  sharedChats: string[];
}

interface CreateDocumentsRequest {
  documents: Pick<IListingDocumentBasic, 'name' | 'filename' | 'key' | 'sharedDeals'>[];
}

export enum ShareableTypes {
  Deal = 'deal',
  Chat = 'chat',
}

export interface DocumentUsersShareableItem {
  _id: string;
  user?: {
    name: string;
    img: string;
  };
  price?: number;
  type: ShareableTypes;
  listing?: ISellerListing;
}

interface GetShareablesResponse {
  data: DocumentUsersShareableItem[];
}

@Injectable({
  providedIn: 'root',
})
export class DocumentsUsersService {
  private baseUrl = '';
  constructor(
    @Inject(APP_CONFIG) private readonly config: IAppConfig,
    @Inject(DOCUMENT) private readonly document: Document,
    private readonly http: HttpClient
  ) {
    this.baseUrl = `${this.config.apiUrl}/users/documents`;
  }

  createDocuments({ documents }: CreateDocumentsRequest) {
    return this.http.post<{ success: true; message: string }>(`${this.baseUrl}`, { documents });
  }

  getAllDocs() {
    return this.http.get<{ docs: DocumentsUsersItem[] }>(`${this.baseUrl}`).pipe(map((res) => res.docs));
  }

  getShareables() {
    return this.http.get<GetShareablesResponse>(`${this.baseUrl}/shareables`).pipe(map((res) => res.data));
  }

  updateName(id: string, name: string, type: DocumentUserTypes) {
    return this.http.put<{ success: true; message: string }>(`${this.baseUrl}/${id}`, { name, type });
  }

  updateShareable(id: string, type: DocumentUserTypes, sharedDeals: string[], sharedChats: string[]) {
    return this.http.put<{ success: true; message: string }>(`${this.baseUrl}/${id}/shareables`, {
      type,
      sharedDeals,
      sharedChats,
    });
  }

  deleteDoc(id: string, type: DocumentUserTypes) {
    return this.http.post<{ success: true; message: string }>(`${this.baseUrl}/${id}`, { type });
  }
}
