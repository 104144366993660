<app-modal #modal [modalConfig]="modalConfig">
  <div class="limit-modal-body">
    <img src="/assets/chat-denied.svg" alt="chat-denied" />
    <div class="limit-header mb-4 mt-2">New chat limit reached</div>
    @if(isVerified){
    <div class="body mb-3">
      You've reached the limit for starting new chats. To protect our users from scammers, please contact support to
      discuss your account.
    </div>
    } @else {
    <div class="body mb-3">
      You've reached the limit for starting new chats. To protect our users from scammers, please complete verification
      before more messages can be sent.
    </div>
    } @if(isVerified){<button class="btn-primary btn mb-3" routerLink="/help-center" (click)="closeModal()">
      Contact support</button
    >}@else{<button class="btn-primary btn mb-3" routerLink="/account" (click)="closeModal()">Get verified</button>}
    <a
      class="text-decoration-none learn-link"
      href="https://support.privateauto.com/article/266-chat-safety"
      target="_blank"
      rel="noopener"
      >Learn more</a
    >
  </div>
</app-modal>
