import { HttpClient } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { APP_CONFIG, IAppConfig } from 'src/app/config/config';
import { AuthService } from './auth.service';
import { LoadingService } from './loading.service';

declare class median {
  static onesignal: any;
  static share: any;
  static deviceInfo: any;
  static plaid: any;
  static android: { screen: any };
  static auth: {
    save: any;
    status: any;
    get: any;
  };

  static socialLogin: {
    google: any;
    apple: any;
  };
}

@Injectable({
  providedIn: 'root',
})
export class GonativeService {
  baseUrl: string;
  private static usernameSubject = new BehaviorSubject<string>('');
  private static passwordSubject = new BehaviorSubject<string>('');

  constructor(
    private readonly http: HttpClient,
    @Inject(APP_CONFIG) private readonly config: IAppConfig,
    private readonly authService: AuthService,
    private readonly loadingService: LoadingService
  ) {
    this.baseUrl = `${this.config.apiUrl}/onesignal`;
  }

  detectIfNativeApp() {
    return navigator.userAgent.indexOf('gonative') > -1;
  }

  detectIfAndroidApp() {
    return navigator.userAgent.indexOf('GoNativeAndroid') > -1;
  }

  detectIfIosApp() {
    return navigator.userAgent.indexOf('GoNativeIOS') > -1;
  }

  async getDeviceInfo() {
    return await median.deviceInfo();
  }

  promptNotifications() {
    if (this.detectIfNativeApp()) {
      median.onesignal.register();
    }
  }

  grantPrivacy() {
    median.onesignal.userPrivacyConsent.grant();
  }

  setFullscreen(enabled: boolean) {
    if (!this.detectIfAndroidApp()) {
      return;
    }
    if (enabled) {
      median.android.screen.fullScreen();
    } else {
      median.android.screen.normal();
    }
  }

  async registerId() {
    const onesignalInfo = await median.onesignal.onesignalInfo();
    const playerId = onesignalInfo.oneSignalUserId;
    return this.http.post(`${this.baseUrl}/setId`, { playerId });
  }

  async detectNotificationsEnabled(): Promise<boolean> {
    const oneSignalInfo = await median.onesignal.onesignalInfo();
    return oneSignalInfo.oneSignalSubscribed;
  }

  downloadFile(url: string) {
    median.share.downloadFile({ url });
  }

  getPassword() {
    return GonativeService.passwordSubject.asObservable();
  }

  getUsername() {
    return GonativeService.usernameSubject.asObservable();
  }

  checkLogin() {
    window.location.href = 'gonative://auth/status?callbackFunction=checkLoginCallbackScript';
  }

  recordLogin(username: string, password: string) {
    GonativeService.usernameSubject.next(username);
    GonativeService.passwordSubject.next(password);
    window.location.href = 'gonative://auth/status?callbackFunction=recordLoginCallbackScript';
  }

  deleteLogin() {
    window.location.href = 'gonative://auth/delete';
  }

  resetLogin() {
    GonativeService.usernameSubject.next('');
    GonativeService.passwordSubject.next('');
  }

  checkLoginCallback(data) {
    if (data && data.hasSecret && data.hasTouchId) {
      let promptString = '';
      if (data.biometryType) {
        promptString = `&prompt=${data.biometryType === 'faceId' ? 'FaceID' : 'TouchID'}`;
      }
      window.location.href = `gonative://auth/get?callbackFunction=getLoginCallback${promptString}`;
    } else {
      alert('No login to retrieve, please login to enable biometrics');
    }
  }

  getLoginCallback(data) {
    if (data && data.success && data.secret) {
      const credentials = JSON.parse(data.secret);
      GonativeService.usernameSubject.next(credentials.username);
      GonativeService.passwordSubject.next(credentials.password);
    }
  }

  recordLoginCallback(data) {
    if (data && data.hasTouchId) {
      const secret = {
        username: GonativeService.usernameSubject.value,
        password: GonativeService.passwordSubject.value,
      };
      window.location.href = `gonative://auth/save?secret=${encodeURIComponent(JSON.stringify(secret))}`;
    }
  }

  verifyIdentity(
    linkToken: string,
    callback: (response: { success: string; linkSessionId: string; error: string }) => void
  ): boolean {
    if (median.plaid) {
      median.plaid?.verifyIdentity({ linkToken, callback });
    }
    return !!median.plaid;
  }

  loginWithGoogle() {
    if (median.socialLogin.google) {
      this.loadingService.setLoading(true);
      median.socialLogin.google.login({ callback: (params) => this.authService.handleProviderCallback(params) });
    }
  }

  loginWithApple() {
    if (median.socialLogin.apple) {
      this.loadingService.setLoading(true);
      median.socialLogin.apple.login({
        scope: 'openid email',
        callback: (params) => this.authService.handleProviderCallback(params),
      });
    }
  }
}
