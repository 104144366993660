import { Component, EventEmitter, Inject, Input, Output, SimpleChanges, ViewChild } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';

import { APP_CONFIG, IAppConfig } from 'src/app/config/config';
import { InspectionService, NotificationService } from 'src/app/core/services';

import { ModalComponent } from 'src/app/widgets/modal/modal.component';
import { ModalConfig } from 'src/app/widgets/modal/modal.model';

@Component({
  selector: 'app-modal-edit-inspection-address',
  templateUrl: './modal-edit-inspection-address.component.html',
  styleUrl: './modal-edit-inspection-address.component.scss',
})
export class ModalEditInspectionAddressComponent {
  @ViewChild('modal') private readonly modalComponent: ModalComponent;
  @Input() isOpen = false;
  @Output() isOpenChange = new EventEmitter<boolean>();
  @Output() onClose = new EventEmitter<any>();
  @Output() reloadInspection = new EventEmitter<any>();

  @Input() inspectionId: string;
  @Input() inspectionAddress: any;

  form: UntypedFormGroup;
  modalConfig: ModalConfig = {};
  showAddressBar = false;

  constructor(
    @Inject(APP_CONFIG) private readonly config: IAppConfig,
    private readonly inspectionService: InspectionService,
    private readonly fb: UntypedFormBuilder,
    private readonly notificationService: NotificationService
  ) {
    this.form = this.fb.group({
      inspectionAddress: [this.inspectionAddress, [Validators.required]],
    });
  }

  async ngOnInit(): Promise<void> {
    this.modalConfig = {
      name: '',
      trackevent: true,
      headerBg: '#2e313b',
      headerLogo: 'assets/lemonsquad/lemonsquadLightLogo.svg',
      headerRounded: true,
      modalDialogClass: 'order-shipping-dialog runbuggy',

      beforeClose: async () => {
        this.isOpen = !this.isOpen;
        this.isOpenChange.emit(this.isOpen);
        this.onClose.emit();
        return true;
      },
    };
  }

  ngOnChanges(changes: SimpleChanges): void {
    const { isOpen, inspectionAddress } = changes;

    if (isOpen?.currentValue) {
      this.modalComponent.open();
    }
    if (inspectionAddress?.currentValue) {
      const { address, city, state, zip } = inspectionAddress.currentValue;
      const formattedAddress = `${address} ${city} ${state}, ${zip}`;

      this.form.patchValue({ inspectionAddress: formattedAddress });

      this.showAddressBar = true;
    }
  }

  updateAddress() {
    const { inspectionAddress } = this.form.value;
    if (!inspectionAddress.formattedAddress) {
      this.notificationService.notification('error', 'Please select address from the dropdown.');
      return;
    }
    this.inspectionService.updateInspectionAddress(this.inspectionId, inspectionAddress).subscribe(() => {
      this.modalComponent.close();
      this.isOpen = false;
      this.reloadInspection.emit(this.inspectionId);
      this.isOpenChange.emit(this.isOpen);
      this.onClose.emit();
    });
  }
}
