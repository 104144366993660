<div class="email-input-container">
  <div class="email-input-wrapper">
    @for (email of emails; track email) {
      <div class="email-pill">
        {{ email }}
        <button 
          type="button" 
          class="remove-email" 
          (click)="removeEmail(email)"
          [disabled]="disabled"
        >&times;</button>
      </div>
    }
    <input
      type="email"
      class="form-control"
      [(ngModel)]="currentEmail"
      (input)="onEmailInput($event)"
      (keydown)="onEmailKeydown($event)"
      [ngClass]="{ 'is-invalid': isInvalid }"
      [placeholder]="placeholder"
      [disabled]="disabled"
    />
  </div>
</div>
@if (isInvalid) {
  <div class="invalid-feedback">
    {{ invalidFeedback }}
  </div>
}
