export async function getTheme() {
  return {
    black: '#000000',
    'black-1': '#0d0e0f',
    'black-2': '#333333',
    'black-3': '#232323',
    'black-4': '#354360',
    'black-5': '#2e313b',
    'black-6': '#606060',
    darkest: '#212529',
    'gray-slider': '#b3b3b3',
    gray: '#f7f9fc',
    'gray-1': '#f5f5f5',
    'gray-2': '#d3d3d3',
    'gray-3': '#828282',
    'gray-4': '#eff0f6',
    'gray-5': '#727a82',
    'gray-6': '#f7f7f7',
    'gray-7': '#cacaca',
    'gray-8': '#4f4f4f',
    'gray-9': '#e9e9e9',
    'gray-10': '#c4c4c4',
    'gray-11': '#f8f8f8',
    'gray-12': '#efefef',
    'gray-13': '#eaecef',
    'gray-14': 'rgb(177, 176, 176)',
    'gray-15': '#393939',
    'gray-16': '#e5e5e5',
    'gray-dark': '#808080',
    'gray-17': '#f9f9f9',
    'gray-18': '#c8c8c8',
    'gray-19': '#cecece',
    'gray-20': 'rgba(70, 70, 70, 0.14)',
    'gray-21': '#2e3b542b',
    'gray-22': '#fcf9ff',
    'gray-23': '#f2f2f2',
    'gray-24': '#eeeeee',
    'gray-25': '#f4f8ff',
    'gray-26': '#4f4f4f',
    'gray-27': '#d0d0d0',
    'gray-28': '#5b5b5b',
    'gray-29': '#B6B6B6',
    'gray-30': '#e7e7e7',
    'gray-31': '#ddd',
    'gray-32': '#F4F5F5',
    'gray-33': '#ffecdb',
    'gray-34': '#d1d1d1',
    'gray-35': '#f9fbff',
    'gray-hover': '#2e3b541c',
    'gray-1-darkened': '#e0e0e0',
    white: '#ffffff',
    green: 'rgb(11, 151, 9)',
    'green-1': '#53e8bb',
    'green-2': '#27ae60',
    'green-3': '#DEF3E7',
    'green-4': '#2FBD93',
    red: '#d80808',
    'red-1': '#eb5757',
    'red-2': '#fceaea',
    'red-3': '#fdf2f2',
    'red-4': '#D82828',
    'orange-1': '#f2994a',
    'orange-2': '#fdf3e9',
    'orange-3': '#ffefe0',
    'orange-4': '#d76b0a',
    blue: '#2e3b54',
    'blue-1': '#00a0c7',
    'blue-2': '#063829',
    'blue-3': '#c6eef7',
    'blue-4': '#d9f4fa',
    'blue-5': '#def2f7',
    'blue-6': '#80bdff',
    'blue-7': '#24a5ca',
    'blue-8': '#ebf9fc',
    'blue-9': '#45577d',
    'blue-10': '#0c2357',
    'blue-11': '#2e3b54',
    'blue-12': '#ececec',
    'blue-13': '#1C2332',
    'blue-14': '#EAF8FF',
    'blue-15': '#e8f8ff',
    'blue-16': '#F0FAFF',
    'blue-17': '#10829D',
    'primary-blue': '#00b3de',
    'dealnow-blue': '#42b4ca',
    'primary-blue-light': '#00b3de14',
    'soft-blue': '#51a7e8',
    'link-color': '#00b3de',
    yellow: '#fbbc05',
    'yellow-pending': '#f2c94c',
    'yellow-external': '#ffedde',
    'gray-linear': 'linear-gradient(180deg, #eef2f5 0%, #eeeef5 100%)',
    'blue-linear': 'linear-gradient(180deg, #53cce8 0%, #53d9e8 100%)',
    'green-linear': 'linear-gradient(117.3deg, #53e8bb 0%, #8cddf0 100%)',
  };
}
export function injectThemeIntoRoot(theme: Record<string, string>) {
  const style = document.createElement('style');

  // Generate the CSS variables dynamically
  const cssVariables = Object.keys(theme)
    .map((key) => `--${key}: ${theme[key]};`)
    .join('\n');

  // Add your :root CSS to the style element
  style.innerHTML = `
    :root {
      ${cssVariables}
    }
  `;

  document.head.appendChild(style);
}
