import { Component, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';

@Component({
  selector: 'app-checkbox',
  templateUrl: './checkbox.component.html',
  styleUrls: ['./checkbox.component.scss'],
})
export class CheckboxComponent implements OnInit, OnChanges {
  @Input() name!: string; // formControlName
  @Input() formGroup!: UntypedFormGroup;
  @Input() text = '';
  @Input() class = '';
  @Input() label = '';
  @Input() disabled = false;
  @Input() textClass = '';

  constructor() {}

  ngOnInit(): void {}

  ngOnChanges(changes: SimpleChanges): void {
    const { disabled } = changes;

    if (disabled?.currentValue !== undefined) {
      if (disabled?.currentValue) {
        this.formGroup.controls[this.name]?.disable();
      } else {
        this.formGroup.controls[this.name]?.enable();
      }
    }
  }
}
