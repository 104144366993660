import { Component, Input, OnChanges, SimpleChanges } from '@angular/core';
import { ListingCategoryTypes } from 'src/app/models';

const DEFAULT_AUTO_IMG = 'assets/create-listing/new-listing-car.svg';
const DEFAULT_BOAT_IMG = 'assets/create-listing/new-listing-boat.svg';
const DEFAULT_RV_IMG = 'assets/create-listing/new-listing-rv.svg';
const DEFAULT_ATVOHV_IMG = 'assets/create-listing/new-listing-atv.svg';
const DEFAULT_MOTORCYCLE_IMG = 'assets/create-listing/new-listing-motorcycle.svg';
const DEFAULT_TRAILER_IMG = 'assets/create-listing/new-listing-trailer.svg';
@Component({
  selector: 'app-listing-image',
  templateUrl: './listing-image.component.html',
  styleUrls: ['./listing-image.component.scss'],
})
export class ListingImageComponent implements OnChanges {
  @Input() vehicleType: ListingCategoryTypes = ListingCategoryTypes.Auto;
  @Input() src: string;

  @Input() set width(val: number) {
    this._width = `${val || 80}px`;
  }

  @Input() set height(val: number) {
    this._height = `${val || 64}px`;
  }

  defaultImg = DEFAULT_AUTO_IMG;
  listingCategoryTypes = ListingCategoryTypes;

  _src: string;
  _width: string;
  _height: string;

  ngOnChanges(changes: SimpleChanges) {
    this.updateImageSource();
  }

  private updateImageSource() {
    if (this.src) {
      this._src = this.src;
      return;
    }

    if (this.vehicleType === this.listingCategoryTypes.Auto) {
      this._src = DEFAULT_AUTO_IMG;
    } else if (this.vehicleType === this.listingCategoryTypes.Boat) {
      this._src = DEFAULT_BOAT_IMG;
    } else if (this.vehicleType === this.listingCategoryTypes.ATVOHV) {
      this._src = DEFAULT_ATVOHV_IMG;
    } else if (this.vehicleType === this.listingCategoryTypes.Motorcycle) {
      this._src = DEFAULT_MOTORCYCLE_IMG;
    } else if (this.vehicleType === this.listingCategoryTypes.Trailer) {
      this._src = DEFAULT_TRAILER_IMG;
    } else if (this.vehicleType === this.listingCategoryTypes.RV) {
      this._src = DEFAULT_RV_IMG;
    } else {
      this._src = DEFAULT_AUTO_IMG;
    }
  }
}
