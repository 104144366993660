<app-popover [el]="ref" class="change-bg-color" [(isOpen)]="_isOpen" (onClose)="onPopoverClosed()" [masked]="true">
  <div title class="popover-get-fully-verified-title">Get your account fully verified.</div>
  <div body class="popover-get-fully-verified-body">
    <div class="description mb-2">Gain trust and transact safely.</div>
    <app-progress-bar-steps [steps]="steps"></app-progress-bar-steps>
    <app-verification-list [user]="user"></app-verification-list>

    <div class="mt-3">
      <button class="btn-outline-primary btn w-100" [routerLink]="['/account']">Finish verification</button>
    </div>
  </div>
</app-popover>
