<pagination-template
  #p="paginationApi"
  [id]="id"
  [maxSize]="maxSize"
  (pageChange)="pageChange.emit($event)"
  (pageBoundsCorrection)="pageBoundsCorrection.emit($event)"
>
  <nav role="navigation" [attr.aria-label]="screenReaderPaginationLabel">
    @if (!(autoHide && p.pages.length <= 1)) {
    <ul class="ngx-pagination" [class.responsive]="responsive">
      @if (directionLinks) {
      <li class="pagination-previous" [class.disabled]="p.isFirstPage()">
        <a tabindex="0" (keyup.enter)="!p.isFirstPage() && p.previous()" (click)="!p.isFirstPage() && p.previous()">
          <img [src]="p.isFirstPage() ? 'assets/chevron-left-disabled.svg' : 'assets/chevron-left.svg'" />
        </a>
      </li>
      } @for (page of p.pages; track trackByIndex($index)) {
      <li
        class="item mx-1"
        [class.current]="p.getCurrent() === page.value"
        [class.ellipsis]="page.label === '...'"
        [class.clickable]="page.label !== '...' && p.getCurrent() !== page.value && !isPageLabelEqualsLastPage(page.label)"
      >
        <div class="page-wrapper">
          @if (p.getCurrent() !== page.value && !isPageLabelEqualsLastPage(page.label) && page.label !== '...') {
          <a tabindex="0" (keyup.enter)="p.setCurrent(page.value)" (click)="p.setCurrent(page.value)">
            <span class="page-label">{{ (page.label === '...') ? page.label : (page.label | number:'') }}</span>
          </a>
          } @if (p.getCurrent() === page.value || isPageLabelEqualsLastPage(page.label) || page.label === '...') {
          <span class="page-label" aria-live="polite">
            {{ (page.label === '...') ? '···' : (page.label | number:'') }}
          </span>
          }
        </div>
      </li>
      } @if (directionLinks) {
      <li class="pagination-next" [class.disabled]="p.isLastPage()">
        <a tabindex="0" (keyup.enter)="!p.isLastPage() && p.next()" (click)="!p.isLastPage() && p.next()">
          <img [src]="p.isLastPage() ? 'assets/chevron-right-disabled.svg' : 'assets/chevron-right.svg'" />
        </a>
      </li>
      }
    </ul>
    }
  </nav>
</pagination-template>
