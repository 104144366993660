import { Component, EventEmitter, Input, OnInit, Output, SimpleChanges, ViewChild } from '@angular/core';
import { ModalComponent } from '../modal/modal.component';
import { ModalConfig } from '../modal/modal.model';
import { ModalVehicleAddedListing } from '../modal-vehicle-added/modal-vehicle-added.component';

type OptionName = 'Yes, I am' | 'No, someone else is';

interface OptionItem {
  name: OptionName;
  value: boolean;
}

@Component({
  selector: 'app-modal-vehicle-confirm-seller',
  templateUrl: './modal-vehicle-confirm-seller.component.html',
  styleUrls: ['./modal-vehicle-confirm-seller.component.scss'],
})
export class ModalVehicleConfirmSellerComponent implements OnInit {
  @ViewChild('modal') private readonly modalComponent: ModalComponent;

  @Input() isOpen = false;

  @Output() isOpenChange = new EventEmitter<boolean>();
  @Output() onNext = new EventEmitter<boolean>();
  @Input() vehicleAddedListing: ModalVehicleAddedListing;

  @Output() onClose = new EventEmitter<boolean>();

  modalConfig: ModalConfig = {};

  options: OptionItem[] = [
    {
      name: 'Yes, I am',
      value: true,
    },
    {
      name: 'No, someone else is',
      value: false,
    },
  ];

  selectedOption: boolean = null;
  allowClose = false;

  constructor() {}

  ngOnInit(): void {
    this.modalConfig = {
      name: 'Confirm vehicle modal',
      hideClose: true,
      beforeClose: async (info) => {
        if (!this.allowClose) {
          return false;
        }
        this.isOpen = false;
        this.onClose.emit();
        return true;
      },
    };
  }

  ngOnChanges(changes: SimpleChanges): void {
    const { isOpen } = changes;

    if (isOpen?.currentValue) {
      this.modalComponent.open();
    }
  }

  onOptionClick(option: OptionItem) {
    this.selectedOption = this.selectedOption !== option.value ? option.value : null;
  }

  onNextClick() {
    this.onNext.emit(this.selectedOption);
    this.allowClose = true;
    this.modalComponent.close();
  }
}
