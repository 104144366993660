import { Directive, ElementRef, HostListener } from '@angular/core';

export function formatSSN(value: string): string {
  if(!value) {
    return ''
  }
  const part1 = value.slice(0, 3);
  const part2 = value.slice(3, 5);
  const part3 = value.slice(5, 9);

  let formattedValue = part1;
  if (part2) {
    formattedValue += `-${part2}`;
  }
  if (part3) {
    formattedValue += `-${part3}`;
  }

  return formattedValue;
}
@Directive({
  selector: '[ssnMask]',
})
export class SSNMaskDirective {
  constructor(private el: ElementRef) {
    this.formatInitialValue();
  }

  @HostListener('input', ['$event']) onInput(event: any) {
    const value = event.target.value.replace(/[^0-9*]/g, ''); // Remove all non-digit and non-* characters
    event.target.value = formatSSN(value);
  }

  private formatInitialValue() {
    const value = this.el.nativeElement.value.replace(/[^0-9*]/g, ''); // Remove all non-digit and non-* characters
    this.el.nativeElement.value = formatSSN(value);
  }
}
