import { Component, Output, EventEmitter, Input, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';

@Component({
  selector: 'app-beneficial-owners-verification',
  templateUrl: './beneficial-owners-modal.component.html',
})
export class BeneficialOwnersVerificationComponent implements OnInit {
  @Output() onNext = new EventEmitter<boolean>();
  
  chipOptions = [
    { label: 'Yes', value: true },
    { label: 'No', value: false },
  ];
  
  @Input() businessName: string;
  form: FormGroup;

  constructor(private fb: FormBuilder) {}

  ngOnInit() {
    this.form = this.fb.group({
      hasBeneficialOwners: [true, Validators.required],
      certifyInformation: [false, Validators.requiredTrue]
    });
  }

  onContinue(): void {
    this.onNext.emit(this.form.get('hasBeneficialOwners').value);
  }

  isDisabled() {
    if(!this.form.get('hasBeneficialOwners').value) {
      return !this.form.get('certifyInformation').value
    }
    return false
  }
}