export interface IState {
  name: string;
  abbreviation: string;
  requirementLink?: string;
  transferTitleLink?: string;
}

const STATES: IState[] = [
  {
    name: 'Alabama',
    abbreviation: 'AL',
    transferTitleLink: 'https://support.privateauto.com/article/71-how-to-transfer-a-title-in-alabama',
  },
  {
    name: 'Alaska',
    abbreviation: 'AK',
    transferTitleLink: 'https://support.privateauto.com/article/120-alaska-how-to-transfer-a-title',
  },
  {
    name: 'American Samoa',
    abbreviation: 'AS',
  },
  {
    name: 'Arizona',
    abbreviation: 'AZ',
    transferTitleLink: 'https://support.privateauto.com/article/121-arizona-how-to-transfer-a-title',
  },
  {
    name: 'Arkansas',
    abbreviation: 'AR',
    transferTitleLink: 'https://support.privateauto.com/article/122-arkansas-how-to-transfer-a-title',
  },
  {
    name: 'California',
    abbreviation: 'CA',
    transferTitleLink: 'https://support.privateauto.com/article/123-california-how-to-transfer-a-title',
  },
  {
    name: 'Colorado',
    abbreviation: 'CO',
    transferTitleLink: 'https://support.privateauto.com/article/124-colorado-how-to-transfer-a-title',
  },
  {
    name: 'Connecticut',
    abbreviation: 'CT',
    transferTitleLink: 'https://support.privateauto.com/article/125-connecticut-how-to-transfer-a-title',
  },
  {
    name: 'Delaware',
    transferTitleLink: 'https://support.privateauto.com/article/144-delaware-how-to-transfer-a-title',
    abbreviation: 'DE',
  },
  {
    name: 'District Of Columbia',
    abbreviation: 'DC',
  },
  {
    name: 'Federated States Of Micronesia',
    abbreviation: 'FM',
  },
  {
    name: 'Florida',
    abbreviation: 'FL',
    transferTitleLink: 'https://support.privateauto.com/article/126-florida-how-to-transfer-a-title',
  },
  {
    name: 'Georgia',
    abbreviation: 'GA',
    transferTitleLink: 'https://support.privateauto.com/article/127-georgia-how-to-transfer-a-title',
  },
  {
    name: 'Guam',
    abbreviation: 'GU',
  },
  {
    name: 'Hawaii',
    abbreviation: 'HI',
    transferTitleLink: 'https://support.privateauto.com/article/143-hawaii-how-to-transfer-a-title',
  },
  {
    name: 'Idaho',
    abbreviation: 'ID',
    transferTitleLink: 'https://support.privateauto.com/article/142-idaho-how-to-transfer-a-title',
  },
  {
    name: 'Illinois',
    abbreviation: 'IL',
    transferTitleLink: 'https://support.privateauto.com/article/145-how-to-transfer-a-title-illinois',
  },
  {
    name: 'Indiana',
    abbreviation: 'IN',
    transferTitleLink: 'https://support.privateauto.com/article/150-indiana-how-to-transfer-a-title',
  },
  {
    name: 'Iowa',
    abbreviation: 'IA',
    transferTitleLink: 'https://support.privateauto.com/article/148-iowa-how-to-transfer-a-title',
  },
  {
    name: 'Kansas',
    abbreviation: 'KS',
    transferTitleLink: 'https://support.privateauto.com/article/146-kansas-how-to-transfer-a-title',
  },
  {
    name: 'Kentucky',
    abbreviation: 'KY',
    transferTitleLink: 'https://support.privateauto.com/article/149-kentucky-how-to-transfer-a-title',
  },
  {
    name: 'Louisiana',
    abbreviation: 'LA',
    transferTitleLink: 'https://support.privateauto.com/article/147-louisiana-how-to-transfer-a-title',
  },
  {
    name: 'Maine',
    abbreviation: 'ME',
  },
  {
    name: 'Marshall Islands',
    abbreviation: 'MH',
  },
  {
    name: 'Maryland',
    abbreviation: 'MD',
  },
  {
    name: 'Massachusetts',
    abbreviation: 'MA',
  },
  {
    name: 'Michigan',
    abbreviation: 'MI',
    transferTitleLink: 'https://support.privateauto.com/article/128-michigan-how-to-transfer-a-title',
  },
  {
    name: 'Minnesota',
    abbreviation: 'MN',
  },
  {
    name: 'Mississippi',
    abbreviation: 'MS',
  },
  {
    name: 'Missouri',
    abbreviation: 'MO',
  },
  {
    name: 'Montana',
    abbreviation: 'MT',
    transferTitleLink: 'https://support.privateauto.com/article/129-montana-how-to-transfer-a-title',
  },
  {
    name: 'Nebraska',
    abbreviation: 'NE',
  },
  {
    name: 'Nevada',
    abbreviation: 'NV',
    transferTitleLink: 'https://support.privateauto.com/article/130-nevada-how-to-transfer-a-title',
  },
  {
    name: 'New Hampshire',
    abbreviation: 'NH',
    transferTitleLink: 'https://support.privateauto.com/article/131-new-hampshire-how-to-transfer-a-title',
  },
  {
    name: 'New Jersey',
    abbreviation: 'NJ',
  },
  {
    name: 'New Mexico',
    abbreviation: 'NM',
    transferTitleLink: 'https://support.privateauto.com/article/132-new-mexico-how-to-transfer-a-title',
  },
  {
    name: 'New York',
    abbreviation: 'NY',
    transferTitleLink: 'https://support.privateauto.com/article/133-new-york-how-to-transfer-a-title',
  },
  {
    name: 'North Carolina',
    abbreviation: 'NC',
  },
  {
    name: 'North Dakota',
    abbreviation: 'ND',
  },
  {
    name: 'Northern Mariana Islands',
    abbreviation: 'MP',
  },
  {
    name: 'Ohio',
    abbreviation: 'OH',
  },
  {
    name: 'Oklahoma',
    abbreviation: 'OK',
  },
  {
    name: 'Oregon',
    abbreviation: 'OR',
    transferTitleLink: 'https://support.privateauto.com/article/134-oregon-how-to-transfer-a-title',
  },
  {
    name: 'Palau',
    abbreviation: 'PW',
  },
  {
    name: 'Pennsylvania',
    abbreviation: 'PA',
    transferTitleLink: 'https://support.privateauto.com/article/135-pennsylvania-how-to-transfer-a-title',
  },
  {
    name: 'Puerto Rico',
    abbreviation: 'PR',
  },
  {
    name: 'Rhode Island',
    abbreviation: 'RI',
  },
  {
    name: 'South Carolina',
    transferTitleLink: 'https://support.privateauto.com/article/136-south-carolina-how-to-transfer-a-title',
    abbreviation: 'SC',
  },
  {
    name: 'South Dakota',
    abbreviation: 'SD',
  },
  {
    name: 'Tennessee',
    abbreviation: 'TN',
    transferTitleLink: 'https://support.privateauto.com/article/137-tennessee-how-to-transfer-a-title',
  },
  {
    name: 'Texas',
    abbreviation: 'TX',
    transferTitleLink: 'https://support.privateauto.com/article/138-texas-how-to-transfer-a-title',
  },
  {
    name: 'Utah',
    abbreviation: 'UT',
    transferTitleLink: 'https://support.privateauto.com/article/18-how-to-transfer-a-title-in-utah',
    requirementLink: 'https://support.privateauto.com/category/15-utah',
  },
  {
    name: 'Vermont',
    abbreviation: 'VT',
  },
  {
    name: 'Virgin Islands',
    abbreviation: 'VI',
  },
  {
    name: 'Virginia',
    abbreviation: 'VA',
    transferTitleLink: 'https://support.privateauto.com/article/140-virginia-how-to-transfer-a-title',
  },
  {
    name: 'Washington',
    abbreviation: 'WA',
  },
  {
    name: 'West Virginia',
    abbreviation: 'WV',
  },
  {
    name: 'Wisconsin',
    abbreviation: 'WI',
  },
  {
    name: 'Wyoming',
    abbreviation: 'WY',
  },
];

export default STATES;
