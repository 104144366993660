import { Component, EventEmitter, Input, OnDestroy, OnInit, Output, SimpleChanges } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { NgbDateParserFormatter } from '@ng-bootstrap/ng-bootstrap';
import { AutoUnsubscribe } from 'ngx-auto-unsubscribe';
import { OnboardingService } from 'src/app/core/services';
import { CustomDateParserFormatter } from 'src/app/core/services/ngb-datepicker-custom.service';
import { BusinessIdentity } from 'src/app/models/prove-verification.model';

@AutoUnsubscribe()
@Component({
  selector: 'app-review-business-information',
  templateUrl: './review-business-information.component.html',
  providers: [{ provide: NgbDateParserFormatter, useClass: CustomDateParserFormatter }],
  styleUrls: ['../prove-verification-modal/prove-verification-modal.component.scss'],
})
export class ReviewBusinessInformationComponent implements OnInit, OnDestroy {
  @Output() onContinue = new EventEmitter();
  @Output() onEdit = new EventEmitter();

  @Input() businessIdentities: BusinessIdentity[];
  @Input() form: FormGroup;

  @Input() selectedBusiness: BusinessIdentity;

  constructor(private readonly fb: FormBuilder, private readonly onboardingService: OnboardingService) {}

  ngOnInit(): void {
  }

  ngOnDestroy(): void {}

  ngOnChanges(changes: SimpleChanges): void {}

  onVerify() {
    this.onContinue.emit();
  }

  get businessFullAddress() {
    if (this.selectedBusiness) {
      const { address, city, region, postalCode } = this.selectedBusiness.businessAddress;
      return [address, city, `${region} ${postalCode}`].filter(Boolean).join(', ');
    }
    return '-';
  }

  get registrationFullAddress() {
    if (this.selectedBusiness?.registrationFiling?.registrationAddress) {
      const { address, city, region, postalCode } = this.selectedBusiness.registrationFiling.registrationAddress;
      return [address, city, `${region} ${postalCode}`].filter(Boolean).join(', ');
    }
    return '-';
  }
}
