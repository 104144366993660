import { Component, EventEmitter, Input, OnDestroy, OnInit, Output, SimpleChanges } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { NgbDateParserFormatter } from '@ng-bootstrap/ng-bootstrap';
import { AutoUnsubscribe } from 'ngx-auto-unsubscribe';
import { CustomDateParserFormatter } from 'src/app/core/services/ngb-datepicker-custom.service';
import { OnboardingService } from 'src/app/core/services';
import { isValidDate } from 'src/app/core/validators/date';
import { ProveVerificationModel } from 'src/app/models/prove-verification.model';

@AutoUnsubscribe()
@Component({
  selector: 'app-start-verification',
  templateUrl: './start-verification.component.html',
  providers: [{ provide: NgbDateParserFormatter, useClass: CustomDateParserFormatter }],
  styleUrls: ['../prove-verification-modal/prove-verification-modal.component.scss'],
})
export class StartVerification implements OnInit, OnDestroy {
  @Output() onSuccess = new EventEmitter();
  @Output() onSkipToManualEntry = new EventEmitter();
  @Output() onClose = new EventEmitter();
  @Input() form: FormGroup;
  @Input() isMobileAuthSuccess: boolean;
  @Output() onMobileAuthPrefillSuccess = new EventEmitter<{ success: boolean; data: ProveVerificationModel }>();

  submitted = false;

  get f() {
    return this.form.controls;
  }

  constructor(private readonly onboardingService: OnboardingService) {}

  ngOnInit(): void {}

  ngOnDestroy(): void {}

  ngOnChanges(changes: SimpleChanges): void {}

  onSubmit() {
    // if (typeof this.form.value.birthDate === 'string') {
    //   const parser = new CustomDateParserFormatter();
    //   const dobValue = parser.parse(this.form.value.birthDate);
    //   this.form.get('birthDate').setValue(dobValue, { emitEvent: false });
    //   if (!isValidDate(dobValue)) {
    //     this.form.get('birthDate').setErrors({ message: 'Invalid date format' }, { emitEvent: false });
    //   }
    // } else if (this.form.value.birthDate.year.toString().length !== 4) {
    //   this.form.get('birthDate').setErrors({ message: 'Year must be 4 digits' }, { emitEvent: false });
    // }
    this.submitted = true;
    if (this.form.invalid) {
      return;
    }
    const { last4Ssn } = this.form.value;
    // const birthDate = [
    //   this.form.value.birthDate.year,
    //   this.form.value.birthDate.month.toString().padStart(2, '0'),
    //   this.form.value.birthDate.day.toString().padStart(2, '0'),
    // ].join('-');
    if (this.isMobileAuthSuccess) {
      this.onboardingService
        .prefillMobileAuth({
          last4Ssn,
        })
        .subscribe((data) => {
          this.onMobileAuthPrefillSuccess.emit(data);
        });
    } else {
      this.onboardingService
        .startVerification({
          phoneNumber: this.form.value.phoneNumber,
          last4Ssn,
        })
        .subscribe((data: any) => {
          this.onSuccess.emit(data.session);
        });
    }
  }
}
