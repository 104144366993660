<div class="verification-failed">
  <div class="heading-1">Verification Attempt Failed</div>
  <div class="description-1 mt-2 mb-3">We are not able to verify your identity</div>
  <div class="d-flex justify-content-center w-100">
    <svg-icon src="/assets/prove-verification-failed.svg"></svg-icon>
  </div>
  <div class="description-1 mt-3">
    You can still list or purchase cars, but {{appName}} Pay will be unavailable unless you’re approved. 
  </div>
  <div class="accept-terms">
    For questions or concerns you can contact  <a class="terms" href="mailto: support@privateauto.com">support&#64;privateauto.com</a>
  </div>
</div>
