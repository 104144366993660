@if (showError) {
<div class="d-flex justify-content-center align-items-center pt-5 px-4">
  <div>
    <div class="img-cnt">
      <div class="mb-4">
        <img src="/assets/logo-name.png" width="400" alt="Logo" />
      </div>
      <div class="d-flex justify-content-center">
        <div>
          <h4>Something is not right. Please reload the page</h4>
          <button (click)="reload()" class="btn btn-primary ms-2">Reload</button>
        </div>
      </div>
    </div>
  </div>
</div>
} @else {
<app-nav (onLogout)="onLogout()">
  <router-outlet></router-outlet>
</app-nav>
}

<ng-template #app>
  <app-nav (onLogout)="onLogout()">
    <router-outlet></router-outlet>
  </app-nav>
</ng-template>

<app-modal-session-timeout [(isOpen)]="showSessionTimeout"></app-modal-session-timeout>
