import { ISellerListing } from './seller-listing.model';

export interface PhotographyOrderResponseModel {
  listing: ISellerListing;
  _id: string;
  createdAt: Date;
  payment: {
    date: Date;
  };
  details: {
    location: {
      address: string;
      state: string;
      zip: string;
      city: string;
    };
    shootDate: Date;
  };
  status: string;
}
export interface PhotographyOrderModel {
  completedAt: Date;
  status: string;
  slug: string;
  image: string;
  vin: string;
  date: Date;
  address: string;
  name: string;
  currentStep: number;
  listingId: string;
}
