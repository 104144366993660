import { HttpClient, HttpHeaders } from '@angular/common/http';
import { EventEmitter, Inject, Injectable, Output } from '@angular/core';
import { map } from 'rxjs/operators';
import { APP_CONFIG, IAppConfig } from 'src/app/config/config';
import { ListingCategoryTypes } from 'src/app/models';
import { convertDecodedVinInfoToListing } from 'src/app/shared/utils-listing';

interface SearchModelsByYearAndMakeResult {
  result: {
    uvc: string;
    name: string;
  }[];
}

@Injectable({
  providedIn: 'root',
})
export class SellerService {
  @Output() onViewTask: EventEmitter<null> = new EventEmitter();
  constructor(private readonly http: HttpClient, @Inject(APP_CONFIG) private readonly config: IAppConfig) {}
  getInfoByVIN(vin, vtype: ListingCategoryTypes, hideHeaderLoader = false) {
    let headers = new HttpHeaders();
    headers = hideHeaderLoader ? headers.set('X-No-Loader', '1') : headers;

    return this.http.post<{ data: any; message: string; infoData: any; trimOptions?: string[] }>(
      `${this.config.apiUrl}/vehicles/decode-vin/${vin}`,
      { vtype },
      { headers }
    );
  }

  getDecodedInfoByVin(vinNumber: string, vehicleType: ListingCategoryTypes) {
    return this.getInfoByVIN(vinNumber, vehicleType).pipe(
      map((res) => convertDecodedVinInfoToListing(res)),
      map((res) => {
        const {
          RegistrationYear,
          CarMake,
          CarModel,
          mpg,
          DriveType,
          Cylinders,
          Doors,
          Transmission,
          Fuel,
          BodyStyle,
          Engine,
        } = res.listing;

        return {
          vehicleType,
          vinNumber,
          RegistrationYear,
          CarMake,
          CarModel,
          mpg,
          DriveType,
          Cylinders,
          Doors,
          Transmission,
          Fuel,
          BodyStyle,
          Engine,

          disableUserId: true,
        };
      })
    );
  }

  getInfoByPlate(licencePlate, state, hideHeaderLoader = false) {
    let headers = new HttpHeaders();
    headers = hideHeaderLoader ? headers.set('X-No-Loader', '1') : headers;

    return this.http.post(`${this.config.apiUrl}/vehicles/decode-licence-plate`, { state, licencePlate }, { headers });
  }

  getSellInquiry(id: string) {
    return this.http.get(`${this.config.apiUrl}/vehicles/sell-inquiry/${id}`);
  }

  searchModelsByYearAndMake(year: number, make: string) {
    return this.http.post<SearchModelsByYearAndMakeResult>(`${this.config.apiUrl}/vehicles/drilldown`, {
      year,
      make,
    });
  }
}
