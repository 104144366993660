import { Injectable } from '@angular/core';
import { HttpRequest, HttpHandler, HttpEvent, HttpInterceptor } from '@angular/common/http';
import { from, Observable } from 'rxjs';
import { Auth } from 'aws-amplify';
import { switchMap } from 'rxjs/operators';
import { UsersService } from '../services';

/**
 * This will append jwt token for the http requests.
 *
 * @export
 * @class JwtInterceptor
 * @implements {HttpInterceptor}
 */
@Injectable()
export class TokenInterceptor implements HttpInterceptor {
  constructor(private usersService: UsersService) {}

  intercept(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
    return this.usersService.cognitoData.pipe(
      switchMap((cognitoData) => {
        if (cognitoData.id_token) {
          const withAuthRequest = request.clone({
            setHeaders: {
              Authorization: `Bearer ${cognitoData.access_token}`,
            },
          });
          return next.handle(withAuthRequest);
        }
        return from(Auth.currentSession().catch(() => null)).pipe(
          switchMap((auth) => {
            if (request.headers.has('X-No-Authorization')) {
              const cleanRequest = request.clone({
                headers: request.headers.delete('X-No-Authorization'),
              });
              return next.handle(cleanRequest);
            }
            // unauthenticated api call
            if (!auth) {
              return next.handle(request);
            }
            const jwt = auth.getAccessToken().getJwtToken();
            const withAuthRequest = request.clone({
              setHeaders: {
                Authorization: `Bearer ${jwt}`,
              },
            });
            return next.handle(withAuthRequest);
          })
        );
      })
    );
  }
}
