import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ProgressBarStepsComponent } from './progress-bar-steps.component';

@NgModule({
  declarations: [ProgressBarStepsComponent],
  exports: [ProgressBarStepsComponent],
  imports: [CommonModule],
})
export class ProgressBarStepsModule {}
