import { Component, EventEmitter, Input, OnDestroy, OnInit, Output, SimpleChanges } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { NgbDateParserFormatter } from '@ng-bootstrap/ng-bootstrap';
import { AutoUnsubscribe } from 'ngx-auto-unsubscribe';
import { OnboardingService } from 'src/app/core/services';
import { CustomDateParserFormatter } from 'src/app/core/services/ngb-datepicker-custom.service';

@AutoUnsubscribe()
@Component({
  selector: 'app-review-information',
  templateUrl: './review-information.component.html',
  providers: [{ provide: NgbDateParserFormatter, useClass: CustomDateParserFormatter }],
  styleUrls: ['../prove-verification-modal/prove-verification-modal.component.scss'],
})
export class ReviewInformationComponent implements OnInit, OnDestroy {
  @Output() onContinue = new EventEmitter();
  @Output() onEdit = new EventEmitter();
  @Input() form: FormGroup;
  isEditing = false;
  constructor(private readonly fb: FormBuilder, private readonly onboardingService: OnboardingService) {}

  ngOnInit(): void {}

  ngOnDestroy(): void {}

  ngOnChanges(changes: SimpleChanges): void {}

  onVerify() {
    this.onContinue.emit();
  }
}
