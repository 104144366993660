import { Inject, Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { APP_CONFIG, IAppConfig } from 'src/app/config/config';
import { filter, pluck, switchMap, tap } from 'rxjs/operators';
import { BehaviorSubject, Observable, of } from 'rxjs';
import * as moment from 'moment-timezone';
import { ISellerListing } from 'src/app/models';

export enum UsallianceAccountStatuses {
  ACTIVE = 'Active',
  APPROVED = 'Approved',
  Closed = 'Closed',
}

export enum UsallianceLoanStatuses {
  PENDING = 'pending',
  APPROVED = 'approved',
  FUNDED = 'funded',
  DECLINED = 'declined',
  CANCELED = 'canceled',
  CANCELED_APPROVED = 'canceled-approved',
  SAVED = 'saved',
}

export enum UsallianceLoanDocumentTypes {
  LEASE = 'Lease',
  UTILITY = 'Utility',
  TITLE = 'Title',
  SIGNED_BILL_OF_SALE = 'Signed Bill of Sale',
  PROOF_OF_INSURANCE = 'Proof of Insurance',
}

export enum UsallianceTransactionsTypes {
  DEPOSIT_LOAN = 'depositLoan',
  DEPOSIT_BANK = 'depositBank',
  DEPOSIT_USER = 'depositUser',
  WITHDRAW_BANK = 'withdrawBank',
  WITHDRAW_USER = 'withdrawUser',
}

export enum UsallianceTransactionsStatuses {
  PENDING = 'pending',
  APPROVED = 'approved',
  DECLINED = 'declined',
  CANCELED = 'canceled',
  CANCELED_APPROVED = 'canceled-approved',
  // Used by loans
  FUNDED = 'funded',
}

export enum USallianceTransactionHoldTypes {
  /**
   * Mon-Friday until 1:59pm EST
   * A - 2-3 days
   * B - 5-6 days
   */
  A_SUCCESSFUL = 'A',
  B_UNSUCCESSFUL = 'B',
  /**
   * Friday 2pm EST onwards - Sunday
   * C - 4-6 days
   */
  C_SUCCESSFUL_UNSUCCESSFUL = 'C',
}

/**
 * Numbers are in days
 * this is used for showing the message in pending ach banner
 */
export const USATransactionHoldTimes = {
  [USallianceTransactionHoldTypes.A_SUCCESSFUL]: {
    min: 2,
    max: 3,
  },
  [USallianceTransactionHoldTypes.B_UNSUCCESSFUL]: {
    min: 5,
    max: 6,
  },
  [USallianceTransactionHoldTypes.C_SUCCESSFUL_UNSUCCESSFUL]: {
    min: 4,
    max: 6,
  },
};

export interface UsallianceTransactions {
  userId: string;
  transactionId: string;
  createdAt: string;
  amount: number;
  status: UsallianceTransactionsStatuses;
  description: string;
  type: UsallianceTransactionsTypes;
  hold: {
    type: USallianceTransactionHoldTypes;
    usa?: any;
    newHoldDate?: Date;
    readonly isApplicable: boolean;
  };
}

export interface IUsalliance {
  account: {
    account_available_balance: number;
    account_balance: number;
    account_description: string;
    account_flags: unknown[];
    account_lockouts: unknown[];
    account_number: number;
    account_scheduled_transfers: {
      additional_description: string;
      amount: string;
      description: string;
      external_account_name: string;
      external_account_number: string;
      external_routing_number: string;
      scheduled_date: Date;
      transaction_type: string;
    }[];
    account_status: UsallianceAccountStatuses;
    account_transactions: {
      transaction_datetime: Date;
      transaction_description: string;
      transaction_amount: number;
      transaction_status: string;
    }[];
    account_type: string;

    // Custom properties
    transactions: UsallianceTransactions[];
    privateAutoPay: {
      fundsVerified: boolean;
      depositDate: Date;
      accountNumber: number;
    };
    last_transaction_date: string;
    healthcheck: boolean;

    unpaidListings: {
      deals: {
        _id: string;
        sellerId: string;
        buyerId: string;
        listing: Pick<ISellerListing, 'payLater'>;
      }[];
      // Stripe amount
      total: number;
    };
  };

  loan: {
    amount_approved: null;
    amount_pre_approved: null;
    booked_to_core: boolean;
    exact_monthly_payment: null;
    first_payment_date: null;
    funding_apr: null;
    funding_date: null;
    funding_status: null;
    last_modified_date: Date;
    loan_decision_date: null | Date;
    loan_entry_date: Date;
    loan_notes: string;
    loan_number: string;
    loan_status: typeof UsallianceLoanStatuses;
    loan_type: string;
  };

  loanRates: {
    last_updated: string;
    loan_type: string;
    rate_apr: string;
    rate_name: string;
  }[];
}

export interface OriginateExternalTransferPayload {
  account_id: string;
  accountType: string;
  accountMask: string;
  amount: number;
  institution: string;
  institutionId: string;
  full_name_on_account: string;
  money_direction: 'out' | 'in';
  funds_verified?: boolean;
  plaid?: any;
}

const HEADER_HIDE_LOADER = 'X-No-Loader';

@Injectable({ providedIn: 'root' })
export class UsallianceService {
  private readonly baseUrl: string;

  private readonly accountSubject = new BehaviorSubject<IUsalliance['account']>(null);
  get account() {
    return this.accountSubject.asObservable().pipe(filter(Boolean)) as Observable<IUsalliance['account']>;
  }

  private readonly loanRatesSubject = new BehaviorSubject<IUsalliance['loanRates']>(null);

  private readonly transactionsSubject = new BehaviorSubject<UsallianceTransactions[]>(null);
  get transactions() {
    return this.transactionsSubject.asObservable().pipe(filter(Boolean)) as Observable<UsallianceTransactions[]>;
  }

  constructor(private readonly http: HttpClient, @Inject(APP_CONFIG) private readonly config: IAppConfig) {
    this.baseUrl = `${this.config.apiUrl}/bank/usalliance`;
  }

  private readonly accountRecentlyDeletedSubject = new BehaviorSubject<boolean>(false);
  get accountRecentlyDeleted() {
    return this.accountRecentlyDeletedSubject.asObservable().pipe(filter(Boolean)) as Observable<boolean>;
  }

  setRecentlyDeleted(accountDeleted: boolean) {
    this.accountRecentlyDeletedSubject.next(accountDeleted);
  }

  savingsAccount(data, hideLoaderInterceptor = false) {
    let headers = new HttpHeaders();
    headers = hideLoaderInterceptor ? headers.set(HEADER_HIDE_LOADER, '1') : headers;
    return this.http.post(`${this.baseUrl}/savings-account`, data, { headers });
  }

  getAccount(hideLoaderInterceptor = false) {
    let headers = new HttpHeaders();
    headers = hideLoaderInterceptor ? headers.set(HEADER_HIDE_LOADER, '1') : headers;

    return this.http.get<IUsalliance['account']>(`${this.baseUrl}/account`, { headers }).pipe(
      tap((account) => {
        this.accountSubject.next(account);
      })
    );
  }

  getTransactionsHistory(reload = true) {
    if (!reload) {
      const transactions = this.transactionsSubject.getValue();
      if (transactions?.length) {
        return of(transactions);
      }
    }

    return this.http
      .get<UsallianceTransactions[]>(`${this.baseUrl}/transactions-history`, {
        headers: { HEADER_HIDE_LOADER: '1' },
      })
      .pipe(
        pluck('transactions'),
        tap((transactions: UsallianceTransactions[]) => {
          this.transactionsSubject.next(transactions);
        })
      );
  }

  sendWireMsg() {
    return this.http.get(`${this.baseUrl}/wiretransfer`);
  }

  statements = () => this.http.get(`${this.baseUrl}/statements`);

  statement = (data) => this.http.post(`${this.baseUrl}/statement`, data);

  updateLoan = (data) => this.http.put(`${this.baseUrl}/loan/`, data);

  getLoanRates(type = 'vehicle') {
    return this.loanRatesSubject.pipe(
      switchMap((loanRates) => {
        return loanRates
          ? of(loanRates)
          : this.http
              .get<IUsalliance['loanRates']>(`${this.baseUrl}/loan/rates`, {
                params: { type },
              })
              .pipe(
                tap((data) => {
                  this.loanRatesSubject.next(data);
                })
              );
      })
    );
  }

  originateExternalTransfer(data: OriginateExternalTransferPayload) {
    return this.http.post(`${this.baseUrl}/originate-external-transfer`, data);
  }

  transfer(data) {
    return this.http.post(`${this.baseUrl}/transfer`, data);
  }

  deleteAccount = () => this.http.delete(`${this.baseUrl}/account`);

  /**
   * check if a deposit transaction is still being processed
   * applicable only to deposits that are affected by fund hold (latest)
   * @param transaction
   * @returns
   */
  isDepositInProcess(transaction: UsallianceTransactions): boolean {
    const isDeposit = transaction.type === UsallianceTransactionsTypes.DEPOSIT_BANK;
    if (!isDeposit) {
      return false;
    }

    const { APPROVED, PENDING } = UsallianceTransactionsStatuses;
    if (![APPROVED, PENDING].includes(transaction.status)) {
      return false;
    }

    const { newHoldDate: rawHoldDate, type: holdType, isApplicable } = transaction.hold;
    if (!holdType || (!isApplicable && transaction.status === APPROVED)) {
      return false;
    }

    const newHoldDate = moment.tz(rawHoldDate, 'America/New_York');
    const isHoldDateNotPassed = moment(newHoldDate).isAfter();
    return isHoldDateNotPassed || !rawHoldDate;
  }

  getAvailableToWithdraw(account: IUsalliance['account']): number {
    const { account_available_balance, account_scheduled_transfers } = account;
    const scheduledWithdrawAmount = account_scheduled_transfers
      .filter((transfer) => transfer.transaction_type === 'EXT DEP')
      .reduce((amount, transfer) => {
        return amount + +transfer.amount;
      }, 0);

    return account_available_balance - scheduledWithdrawAmount;
  }
}
