import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

@Injectable({ providedIn: 'root' })
export class FileService {
  constructor(private readonly http: HttpClient) {}

  getFile = (url: string) => {
    return this.http.get(url, {
      responseType: 'arraybuffer',
      headers: { 'X-No-Authorization': '' },
    });
  };
}
