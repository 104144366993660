/* eslint-disable no-underscore-dangle */
import { Inject, Injectable, Renderer2, RendererFactory2 } from '@angular/core';
import { DOCUMENT } from '@angular/common';
import * as JSZip from 'jszip';
import { APP_CONFIG, IAppConfig } from '../config/config';

const _URL = window.URL;

export interface ImageProperties {
  width: number;
  height: number;
}

@Injectable({
  providedIn: 'root',
})
export class HelperService {
  private _renderer: Renderer2;

  constructor(
    @Inject(DOCUMENT) private _document: Document,
    private readonly _rendererFactory: RendererFactory2,
    // private readonly notificationService: NotificationService,
    @Inject(APP_CONFIG) private config: IAppConfig
  ) {
    this._renderer = this._rendererFactory.createRenderer(null, null);
  }

  public pdfScreenshot(file: Blob, worker: boolean) {
    return new Promise((resolve) => {
      if (file.type !== 'application/pdf') {
        resolve(null);
      }
      const pdfjsLib = window['pdfjs-dist/build/pdf'];
      pdfjsLib.GlobalWorkerOptions.workerSrc = 'https://mozilla.github.io/pdf.js/build/pdf.worker.js';
      pdfjsLib.disableWorker = worker;
      if (!pdfjsLib) {
        return setTimeout(() => this.pdfScreenshot(file, worker), 2000);
      }
      const localUrl = URL.createObjectURL(file);
      const canvas = this._renderer.createElement('canvas');
      const loadingTask = pdfjsLib.getDocument(localUrl);
      return loadingTask.promise.then(
        (pdf) => {
          pdf.getPage(1).then((page) => {
            const viewport = page.getViewport({ scale: 1.5 });
            const context = canvas.getContext('2d');
            canvas.height = viewport.height;
            canvas.width = viewport.width;
            const renderContext = {
              canvasContext: context,
              viewport,
            };
            page.render(renderContext).promise.then(() => {
              resolve(canvas.toDataURL());
            });
          });
        },
        (reason) => {
          resolve(null);
        }
      );
    });
  }

  public randomString(): string {
    return Math.random().toString(36).substring(2, 15) + Math.random().toString(36).substring(2, 15);
  }

  public async zipFiles(files: Blob[]) {
    if (!files.length) {
      return null;
    }

    const zip: JSZip = new JSZip();
    JSZip.support.nodebuffer = false;
    while (files.length) {
      const file: any = files.shift();
      const fileName: string = file.name || this.randomString();
      zip.file(fileName, file.fileData, { binary: true });
    }
    return zip.generateAsync({ type: 'blob' });
  }

  public saveAs(file: Blob, name: string = this.randomString()) {
    const a: HTMLElement = this._renderer.createElement('a');
    this._renderer.setAttribute(a, 'href', _URL.createObjectURL(file));
    this._renderer.setAttribute(a, 'download', name);
    a.click();
  }

  public time_12Hr(time: any): string {
    if (!time) {
      return '';
    }
    const timeArr = time.split(':');
    if (timeArr.length === 1) {
      time.push('00');
    }
    timeArr[2] = +timeArr[0] < 12 ? 'AM' : 'PM';
    timeArr[0] = +timeArr[0] % 12 || 12;
    return `${timeArr[0]}:${timeArr[1]}${timeArr[2]}`;
  }

  public setCookie(
    name: string,
    value: string,
    expires: Date | string = 'Session',
    sameSite: 'lax' | 'strict' = null,
    domain: string = this.config.parentUrl,
    path: string = '/',
    secure: boolean = false
  ) {
    let cookieContent = `${name}=${value}; expires=${expires}; domain=${domain}; SameSite=${sameSite}; path=${path};`;
    if (secure) {
      cookieContent = ` secure;`;
    }
    this._document.cookie = cookieContent;
  }

  public getCookie(value: string) {
    const cookieValue: string = this._document.cookie.split('; ').find((row) => row.startsWith(value));
    return cookieValue ? cookieValue.split('=')[1] : null;
  }

  public deleteCookies() {
    const cookies = this._document.cookie.split('; ');
    cookies.forEach((cookie) => {
      const eqPos = cookie.indexOf('=');
      const name = eqPos > -1 ? cookie.substr(0, eqPos) : cookie;
      this._document.cookie = `${name}=;expires=Thu, 01 Jan 1970 00:00:00 GMT`;
      this._document.cookie = `${name}=;expires=Thu, 01 Jan 1970 00:00:00 GMT; domain=${this.config.parentUrl}`;
    });
  }

  public getImageDimensions(file: File): Promise<ImageProperties> {
    return new Promise((resolve) => {
      if (!file) resolve(null);
      const img = new Image();
      img.src = _URL.createObjectURL(file);
      img.onload = function () {
        resolve({
          width: this['width'], // eslint-disable-line
          height: this['height'], // eslint-disable-line
        });
      };
    });
  }

  copyTextToClipboard(copyText) {
    const selBox = document.createElement('textarea');
    selBox.style.position = 'fixed';
    selBox.style.left = '0';
    selBox.style.top = '0';
    selBox.style.opacity = '0';
    selBox.value = copyText;
    document.body.appendChild(selBox);
    selBox.focus();
    selBox.select();
    document.execCommand('copy');
    document.body.removeChild(selBox);
  }
}
