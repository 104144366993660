import { ListingCategoryTypes } from 'src/app/models';
import { AUTO_FIELDS_CONFIG } from './auto';
import { ListingStepsConfig } from './model';
import { MOTORCYCLE_FIELDS_CONFIG } from './motorcycle';
import { ATV_FIELDS_CONFIG } from './atv';

export const getListingsStepsField = (type: ListingCategoryTypes) => {
  let fieldsConfig: ListingStepsConfig = AUTO_FIELDS_CONFIG;

  switch (type) {
    case ListingCategoryTypes.Motorcycle:
      fieldsConfig = MOTORCYCLE_FIELDS_CONFIG;
      break;
    case ListingCategoryTypes.ATVOHV:
      fieldsConfig = ATV_FIELDS_CONFIG;
      break;
    default:
      break;
  }

  return fieldsConfig;
};

export * from './auto';
export * from './motorcycle';
export * from './atv';
export * from './model';
