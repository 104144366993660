<app-modal #modal [modalConfig]="modalConfig">
  @if(!manualEntry && !reviewDetails){
  <div class="header">Before jumping into the deal, let's verify your {{isBoatConfirm? 'vessel': 'vehicle'}}</div>

  } @if( reviewDetails){
  <div class="header">Perfect this is the {{isBoatConfirm ? 'vessel': 'vehicle'}} we found.</div>

  } @if(manualEntry && !reviewDetails){
  <div class="header">{{isBoatConfirm?'Edit vessel':'Confirm vehicle'}} details</div>
  }
  <form [formGroup]="form">
    @if(!isBoatConfirm){ @if(!reviewDetails && !manualEntry){
    <div class="my-4 w-100">
      <app-options-slide
        [(selected)]="selectedOption"
        [optionItemLight]="true"
        [options]="inputOptions"
      ></app-options-slide>
    </div>
    <div>
      @if (selectedOption.value === 'vin') {
      <div class="mt-2 pb-1">
        <div class="input-label">VIN</div>
        <input class="form-control mt-1" type="text" placeholder="Enter VIN" formControlName="vin" autocomplete="off" />
      </div>
      } @if (selectedOption.value === 'licensePlate') {
      <div class="d-flex align-items-center mt-2 pb-1 w-100">
        <div class="col-6 ps-0">
          <div class="input-label">License late</div>
          <input
            class="form-control mt-1"
            type="text"
            placeholder="Enter  plate"
            formControlName="licensePlate"
            autocomplete="off"
          />
        </div>
        <div class="col-6 ps-2">
          <div class="input-label">State</div>
          <div class="d-flex">
            <select formControlName="stateRegistered" class="form-control form-control-select">
              <option [ngValue]="null" disabled selected>Select state</option>
              @for (state of states; track state) {
              <option [value]="state.name">{{state.name}}</option>
              }
            </select>
          </div>
        </div>
      </div>
      }
    </div>
    <div class="py-3 w-100">
      <button class="btn-primary w-100" (click)="lookupVehicle()">Verify my vehicle</button>
    </div>
    } @if(manualEntry){

    <div class="mt-2 pb-1">
      <div class="input-label">VIN</div>
      <input class="form-control mt-1" type="text" formControlName="vin" autocomplete="off" />
    </div>
    <div class="position-relative mt-3 pb-1">
      <div class="input-label">Year</div>
      <input
        mask="0000"
        class="form-control mt-1"
        type="text"
        formControlName="yearField"
        autocomplete="off"
        #yearField="ngbTypeahead"
        [ngbTypeahead]="search"
        (focus)="focusYear$.next($any($event).target.value)"
        (click)="clickYear$.next($any($event).target.value)"
      />
    </div>
    <div>
      <div class="mt-2 pb-1">
        <div class="input-label">Make</div>
        <input class="form-control mt-1" type="text" formControlName="make" autocomplete="off" />
      </div>
      <div class="mt-2">
        <div class="input-label">Model</div>
        <input class="form-control mt-1" type="text" formControlName="model" autocomplete="off" />
      </div>
    </div>

    <div>
      <div class="mt-2 pb-1">
        <div class="input-label">State vehicle is registered</div>
        <select formControlName="stateRegistered" class="state form-control form-control-select mt-1">
          <option [ngValue]="null" [disabled]="true" [hidden]="true">Select State</option>
          @for (option of stateOptions; track option) {
          <option [value]="option">{{option}}</option>
          }
        </select>
      </div>
    </div>

    <div class="py-3 w-100">
      <button class="btn-primary w-100" (click)="confirm()">Save</button>
    </div>
    }} @else{ @if(!reviewDetails && !manualEntry){
    <!-- Full Boat Confirmation -->
    <div class="d-flex">
      <app-listing-image
        [src]="dealNow.listing.uploadImages[0]?.images"
        [width]="85"
        [height]="85"
        [vehicleType]="dealNow.listing.vehicleType"
      ></app-listing-image>
      <div class="d-flex flex-column p-3">
        <div class="fw-bolder">
          {{dealNow.listing.RegistrationYear}} {{dealNow.listing.CarMake}} {{dealNow.listing.CarModel}}
        </div>
        Hin:
        <a class="add-image" (click)="fileUpload.click()">Add image (optional)</a>
        <input
          type="file"
          accept="image/*,.heic,.heif,.jfif"
          class="d-none"
          (change)="onFileSelect($event)"
          multiple
          #fileUpload
        />
      </div>
    </div>
    <div class="mt-2 pb-1">
      <div class="input-label">HIN</div>
      <input class="form-control mt-1" type="text" placeholder="Enter HIN" formControlName="vin" autocomplete="off" />
    </div>
    <div class="pt-3 w-100">
      <button class="btn-primary w-100" (click)="confirm()">Verify my vessel</button>
    </div>
    <div class="pb-3 w-100 pt-2">
      <button class="btn-gray w-100" (click)="boatManual(true)">Edit vessel info</button>
    </div>
    } @else if(!reviewDetails && manualEntry){
    <div class="mt-2 pb-1">
      <div class="input-label">HIN</div>
      <input class="form-control mt-1" type="text" formControlName="vin" autocomplete="off" />
    </div>
    <div class="position-relative mt-3 pb-1">
      <div class="input-label">Year</div>
      <input
        mask="0000"
        class="form-control mt-1"
        type="text"
        formControlName="yearField"
        autocomplete="off"
        #yearField="ngbTypeahead"
        [ngbTypeahead]="search"
        (focus)="focusYear$.next($any($event).target.value)"
        (click)="clickYear$.next($any($event).target.value)"
      />
    </div>
    <div>
      <div class="mt-2 pb-1">
        <div class="input-label">Make</div>
        <input class="form-control mt-1" type="text" formControlName="make" autocomplete="off" />
      </div>
      <div class="mt-2">
        <div class="input-label">Model</div>
        <input class="form-control mt-1" type="text" formControlName="model" autocomplete="off" />
      </div>
    </div>
    <div class="pt-3 w-100">
      <button class="btn-primary w-100" (click)="confirm()">Save</button>
    </div>
    } }
  </form>

  @if(reviewDetails && !notNeedReview){
  <div class="listing-details-header d-flex align-items-center">
    <div class="listing-image position-relative">
      <app-listing-image
        [src]="f.mainImg.value"
        [vehicleType]="dealNow.listing?.vehicleType"
        [width]="65.75"
        [height]="52"
      ></app-listing-image>
    </div>
    <div class="listing-details">
      <div class="listing-main-text">{{f.yearField.value}} {{f.make.value}} {{f.model.value}}</div>
      <div class="listing-sub-text">
        @if (f.vin.value) {
        <div class="d-inline-block">{{dealNow.listing?.vehicleType === 'Boat' ? 'HIN': 'VIN'}}: {{ f.vin.value }}</div>
        }
      </div>
      <a class="add-image" (click)="fileUpload.click()">Add image (optional)</a>
      <input
        type="file"
        accept="image/*,.heic,.heif,.jfif"
        class="d-none"
        (change)="onFileSelect($event)"
        multiple
        #fileUpload
      />
    </div>
  </div>
  <button class="btn-primary w-100 mt-4" (click)="confirm()">Yes, that's my vehicle</button>
  <button class="btn-gray w-100 mt-4" (click)="edit()">Edit vehicle info</button>
  }
</app-modal>

<app-modal-vintage-vehicle
  [vin]="f.vin.value"
  [(isOpen)]="showModalVintageVehicle"
  (onContinue)="onVintageVehicleSave($event)"
  [skipAddInfo]="true"
></app-modal-vintage-vehicle>
