import { HttpClient } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { APP_CONFIG, IAppConfig } from 'src/app/config/config';
import {
  InspectionOrder,
  InspectionOrderStatuses,
  InspectionProductTypes,
} from 'src/app/models/inspection-order.model';

export interface InspectionPriceConfig {
  classicPrice?: number;
  standardPrice?: number;
  exoticPrice?: number;
}

@Injectable({
  providedIn: 'root',
})
export class InspectionService {
  constructor(private readonly http: HttpClient, @Inject(APP_CONFIG) private readonly config: IAppConfig) {}

  private ordersSubject = new BehaviorSubject<InspectionOrder[]>([]);
  public orders$ = this.ordersSubject.asObservable();

  getDealOptions() {
    return this.http.get(`${this.config.apiUrl}/addons/inspection/deals`);
  }

  getPendingDealOptions() {
    return this.http.get(`${this.config.apiUrl}/addons/inspection/pending-deals`);
  }

  getOrders() {
    return this.http
      .get<{ data: InspectionOrder[] }>(`${this.config.apiUrl}/addons/inspection/orders`)
      .subscribe((response) => {
        this.ordersSubject.next(response.data);
      });
  }

  saveOrder(orderInfo) {
    return this.http.post(`${this.config.apiUrl}/addons/inspection/orders`, orderInfo);
  }

  getFavoriteOptions() {
    return this.http.get(`${this.config.apiUrl}/addons/inspection/favorites`);
  }

  getOrderNumber(product: InspectionProductTypes) {
    if (product === InspectionProductTypes.Standard) {
      return 2;
    }
    if (product === InspectionProductTypes.Classic) {
      return 3;
    }
    if (product === InspectionProductTypes.Exotic) {
      return 4;
    }
    return 2;
  }

  private readonly inspectionPricesConfigSubject = new BehaviorSubject<InspectionPriceConfig>({});
  inspectionPricesConfig = this.inspectionPricesConfigSubject.asObservable();

  getInspectionQuote(listingId: string) {
    return this.http.post<{ fee: number; type: number }>(`${this.config.apiUrl}/addons/inspection/prices`, {
      listingId,
    });
  }

  getOrderByListing(listingId: string) {
    return this.http.get<InspectionOrder>(`${this.config.apiUrl}/addons/inspection/${listingId}/listing`);
  }

  getOrdersByListing(listingId: string) {
    return this.http.get<InspectionOrder[]>(`${this.config.apiUrl}/addons/inspection/${listingId}/listings`);
  }

  getOrderById(orderId: string) {
    return this.http.get<InspectionOrder>(`${this.config.apiUrl}/addons/inspection/${orderId}`);
  }

  updateInspectionAddress(orderId: string, inspectionAddress: any) {
    return this.http.put<InspectionOrder>(`${this.config.apiUrl}/addons/inspection/${orderId}`, {
      inspectionAddress,
    });
  }

  declineOrder(orderId: string) {
    return this.http.post(`${this.config.apiUrl}/addons/inspection/decline`, { orderId });
  }

  acceptOrder(orderId: string) {
    return this.http.post(`${this.config.apiUrl}/addons/inspection/update`, { orderId });
  }

  cancelOrder(orderId: string) {
    return this.http.delete(`${this.config.apiUrl}/addons/inspection/${orderId}/delete`);
  }
}
