<app-modal #modal [modalConfig]="modalConfig">
  @if(!showPaymentElement){
  <div class="d-flex flex-column justify-content-center align-items-center">
    <img alt="title transfer icon" src="assets/icons/title-transfer-lg-icon.svg" />
    <div class="py-4 w-100 transfer-how text-left">Title transfer, how it works:</div>
    <div class="items">
      <div class="pt-3">
        <img alt="check mark" src="assets/verified.svg" class="pe-2" />Digital titling across the nation
      </div>
      <div class="pt-3">
        <img alt="check mark" src="assets/verified.svg" class="pe-2" />Receive a new title in your name within 3 days
      </div>
      <div class="pt-3"><img alt="check mark" src="assets/verified.svg" class="pe-2" />We do all the work for you</div>
    </div>
    <button class="btn btn-primary modalConfig mt-4" (click)="getService()">Get this service</button>
  </div>
  } @if(showPaymentElement){
  <div>
    <div class="header pb-3">Title transfer service</div>
    @if (selectedVehicle) {
    <div class="vehicle pb-3">
      <app-listing-image [src]="selectedVehicle?.mainImg" [width]="80" [height]="64"></app-listing-image>
      <div class="">
        <p class="">{{selectedVehicle?.RegistrationYear}} {{selectedVehicle?.CarMake}} {{selectedVehicle?.CarModel}}</p>
        <p class="">{{selectedVehicle.vinNumber}}</p>
      </div>
    </div>
    }
    <app-payment-element
      [paymentIntentConfig]="paymentIntentConfig"
      [googleAnalyticsEvent]="googleAnalyticsEvent"
      [paymentElementConfig]="paymentElementConfig"
      [isImmediatePayment]="true"
      (onNextEvent)="onPaymentSuccess()"
    ></app-payment-element>
  </div>
  }
</app-modal>
