import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { NgbPopoverModule } from '@ng-bootstrap/ng-bootstrap';
import { RouterModule } from '@angular/router';
import { PopoverGetFullyVerifiedComponent } from './popover-get-fully-verified.component';
import { PopoverModule } from '../popover/popover.module';
import { ProgressBarStepsModule } from '../progress-bar-steps/progress-bar-steps.module';
import { VerificationListModule } from '../verification-list/verification-list.module';

@NgModule({
  declarations: [PopoverGetFullyVerifiedComponent],
  exports: [PopoverGetFullyVerifiedComponent],
  imports: [
    RouterModule,
    CommonModule,
    PopoverModule,
    ProgressBarStepsModule,
    VerificationListModule,
    NgbPopoverModule,
  ],
})
export class PopoverGetFullyVerifiedModule {}
