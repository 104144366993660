import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { OptionsSlideComponent } from './options-slide.component';

@NgModule({
  declarations: [OptionsSlideComponent],
  exports: [OptionsSlideComponent],
  imports: [CommonModule],
})
export class OptionsSlideModule {}
