import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges, ViewChild } from '@angular/core';
import { ModalConfig } from '../modal/modal.model';
import { ModalComponent } from '../modal/modal.component';
import {
  GoogleAnalyticsEventConfig,
  IPaymentType,
  PaymentElementConfig,
  PaymentIntentConfig,
} from '../payment-element/payment-element.component';
import { ISellerListing } from 'src/app/models';
import { IDeal } from 'src/app/deals/deals.models';

@Component({
  selector: 'app-modal-purchase-loan-payoff',
  templateUrl: './modal-purchase-loan-payoff.component.html',
  styleUrl: './modal-purchase-loan-payoff.component.scss',
})
export class ModalPurchaseLoanPayoffComponent implements OnInit, OnChanges {
  @ViewChild('modal') private readonly modalComponent: ModalComponent;
  @Output() onSuccess = new EventEmitter();
  @Input() isOpen = false;
  @Output() isOpenChange = new EventEmitter<boolean>();
  @Output() onClose = new EventEmitter();
  @Input() deal: IDeal
  @Input() selectedVehicle: ISellerListing;

  modalConfig: ModalConfig = {};
  paymentIntentConfig: PaymentIntentConfig;
  googleAnalyticsEvent: GoogleAnalyticsEventConfig;
  showPaymentElement: boolean = false;
  paymentElementConfig: PaymentElementConfig;

  ngOnInit(): void {
    this.paymentElementConfig = {
      productTitle: 'Loan payoff service',
      buttonText: 'Purchase loan payoff',
    };
    this.modalConfig = {
      name: 'Title Transfer',
      hideClose: false,
      headerBg: '#E8F8FF',
      headerText: 'Loan payoff service',
      hideLogo: true,
      beforeClose: async () => {
        this.isOpen = false;
        this.showPaymentElement = false;
        this.isOpenChange.emit(this.isOpen);
        this.onClose.emit();
        return true;
      },
    };

    this.paymentIntentConfig = { metadata: { dealId: this.deal._id}, listingId: this.selectedVehicle._id, type: IPaymentType.LOAN_PAYOFF };
  }

  ngOnChanges(changes: SimpleChanges): void {
    const { isOpen } = changes;

    if (isOpen?.currentValue) {
      this.modalComponent?.open();
    }
  }

  getService() {
    this.showPaymentElement = true;
  }

  onPaymentSuccess(): void {
    this.modalComponent.close()
    this.onSuccess.emit()
  }
}
