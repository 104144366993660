import { Component, Input, OnChanges, SimpleChanges } from '@angular/core';

@Component({
  selector: 'app-profile-photo',
  templateUrl: './profile-photo.component.html',
  styleUrls: ['./profile-photo.component.scss'],
})
export class ProfilePhotoComponent implements OnChanges {
  @Input('organizationSrc') organizationSrc: string;
  @Input('organizationName') organizationName: string;
  @Input('src') src: string;
  @Input('profileName') profileName: string;
  @Input('size') size: string;

  defaultImg = '/assets/defaultImg.png';
  img: string;
  fallbackSize = 28;
  useDefaultBackground = false;

  bgColor = null;
  fontColor = '#FFFFFF';
  fontSize = `12px`;
  imgValid = false;
  shortName = '';

  ngOnChanges(changes: SimpleChanges) {
    const { profileName, src, size, organizationName, organizationSrc } = changes;

    if(organizationName?.currentValue) {
      this.configureProfileName(organizationName.currentValue);
    } else if (profileName?.currentValue) {
      this.configureProfileName(profileName.currentValue);
    }

    if(organizationSrc?.currentValue){
      this.configureImages(organizationSrc.currentValue);
    } else if (src?.currentValue) {
      this.configureImages(src.currentValue);
    }

    if (size?.currentValue) {
      this.configureFontSize(size.currentValue);
    }
  }

  configureFontSize(size: number) {
    this.fontSize = `${size/3}px`;
  }

  configureImages(src: string) {
    this.imgValid = true;
    this.img = src;
  }

  configureProfileName(profileName: string) {
    if (!profileName) {
      this.configureImages(this.defaultImg);
      return;
    }

    const { bgColor, fontColor, } = this.getColorsByFirstLetter(profileName);
    this.bgColor = bgColor; 
    this.fontColor = fontColor;
    const words = profileName.split(' ');
    const firstLetter = words[0]?.[0] ?? '';
    const secondLetter = words[1]?.[0] ?? '';
    this.shortName = `${firstLetter}${secondLetter}`;
  }

  getColorsByFirstLetter(profileName: string): { bgColor: string; fontColor: string; } {
    const firstLetter = profileName.charAt(0).toLowerCase();
    const colors = {
      a: { bgColor: '#87A8BD', fontColor: '#FFFFFF' },
      b: { bgColor: '#71A171', fontColor: '#FFFFFF' },
      c: { bgColor: '#DF8C6A', fontColor: '#000000' },
      d: { bgColor: '#D3AF73', fontColor: '#000000' },
      e: { bgColor: '#445E62', fontColor: '#FFFFFF' },
      f: { bgColor: '#B4A783', fontColor: '#000000' },
      g: { bgColor: '#C391A5', fontColor: '#000000' },
      h: { bgColor: '#8E8E8E', fontColor: '#000000' },
      i: { bgColor: '#E79BB9', fontColor: '#000000' },
      j: { bgColor: '#74BDBE', fontColor: '#000000' },
      k: { bgColor: '#DA758A', fontColor: '#000000' },
      l: { bgColor: '#84629A', fontColor: '#FFFFFF' },
      m: { bgColor: '#699ACD', fontColor: '#FFFFFF' },
      n: { bgColor: '#CDC077', fontColor: '#000000' },
      o: { bgColor: '#CF6055', fontColor: '#000000' },
      p: { bgColor: '#5A585B', fontColor: '#FFFFFF' },
      q: { bgColor: '#8AA48A', fontColor: '#FFFFFF' },
      r: { bgColor: '#D8A34E', fontColor: '#000000' },
      s: { bgColor: '#7D7D7D', fontColor: '#000000' },
      t: { bgColor: '#B79EA3', fontColor: '#000000' },
      u: { bgColor: '#C6A598', fontColor: '#000000' },
      v: { bgColor: '#DEAA85', fontColor: '#000000' },
      w: { bgColor: '#666396', fontColor: '#FFFFFF' },
      x: { bgColor: '#6D8783', fontColor: '#FFFFFF' },
      y: { bgColor: '#4C939B', fontColor: '#FFFFFF' },
      z: { bgColor: '#B74949', fontColor: '#000000' }
    };
    return colors[firstLetter] || colors['a'];
  }
}
