import {
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnChanges,
  Output,
  QueryList,
  SimpleChanges,
  ViewChild,
  ViewChildren,
} from '@angular/core';

export interface OptionsSlideItem {
  text: string;
  value: any;
}

/**
 * Fill selected property upon showing for more accurate left positioning
 * text is used as key identifier
 */
@Component({
  selector: 'app-options-slide',
  templateUrl: './options-slide.component.html',
  styleUrls: ['./options-slide.component.scss'],
})
export class OptionsSlideComponent implements OnChanges {
  @ViewChild('selectedOptionEl') selectedEl: ElementRef;
  @ViewChild('parentEl') parentEl: ElementRef;
  @ViewChildren('item') items: QueryList<ElementRef>;

  @Input() selected: OptionsSlideItem;
  @Input() optionItemLight: boolean;

  @Output() selectedChange = new EventEmitter<OptionsSlideItem>();

  @Input() options: OptionsSlideItem[] = [];

  overlayStyle = {};

  ngOnChanges(changes: SimpleChanges): void {
    const { selected } = changes;

    if (selected?.currentValue) {
      setTimeout(() => this.positionOverlay());
    }
  }

  getTargetLeftPos(itemEl: HTMLElement): number {
    const targetPos = itemEl.getBoundingClientRect();
    const parentPos = this.parentEl.nativeElement.getBoundingClientRect();
    return targetPos.left - parentPos.left;
  }

  setOverlayStyle(left: number, hasAnimation = true) {
    this.overlayStyle = {
      left: `${left}px`,
      ...(hasAnimation && { transition: 'left 100ms ease-out' }),
    };
  }

  // Position to initial selected value
  positionOverlay() {
    this.items.forEach((itemRef) => {
      if (this.selected.text === itemRef.nativeElement.innerText.trim()) {
        const left = this.getTargetLeftPos(itemRef.nativeElement);
        this.setOverlayStyle(left);
      }
    });
  }

  onOptionClick(option: OptionsSlideItem) {
    this.selected = option;
    this.selectedChange.emit(option);
  }
}
