import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { ControlValueAccessor } from '@angular/forms';
import { BankService } from 'src/app/core/services';
import { PaymentMethod } from 'src/app/models';
import { getValueAccessorProvider } from 'src/app/shared/functions';
import { cardBrandMapping } from '../const';

@Component({
  selector: 'app-payment-method-selector',
  templateUrl: './payment-method-selector.component.html',
  styleUrls: ['./payment-method-selector.component.scss'],
  providers: [getValueAccessorProvider(PaymentMethodSelectorComponent)], // eslint-disable-line
})
export class PaymentMethodSelectorComponent implements OnInit, ControlValueAccessor {
  @Input()
  paymentMethods: PaymentMethod[];

  isAddNewCardVisible = false;

  @Input() name: string;

  value: any;

  onChange = (value: any) => {
    this.value = value;
  };

  onTouched = () => {};

  @Output() onSelect = new EventEmitter<PaymentMethod>();

  @Output() onAddSuccess = new EventEmitter<PaymentMethod>();

  constructor(private readonly bankService: BankService) {}

  writeValue(value: any): void {
    this.value = value;
  }

  registerOnChange(fn: (value: any) => void): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: () => void): void {
    this.onTouched = fn;
  }

  getBrandDisplayName(brand: string) {
    return cardBrandMapping[brand];
  }

  formatMonth(month: number) {
    return month.toString().padStart(2, '0');
  }

  formatYear(year: number) {
    return year.toString().slice(-2);
  }

  formatNameDisplay(paymentMethod: PaymentMethod) {
    return [
      paymentMethod.name,
      `${this.formatMonth(paymentMethod.expiryMonth)} / ${this.formatYear(paymentMethod.expiryYear)}`,
    ]
      .filter(Boolean)
      .join(' • ');
  }

  onAddNewCard() {
    this.isAddNewCardVisible = true;
  }

  onAddPaymentMethodSuccess(paymentMethod: PaymentMethod) {
    this.onAddSuccess.emit(paymentMethod);
  }

  ngOnInit(): void {}

  setDisabledState(isDisabled: boolean) {}
}
