export interface PaymentMethod {
  id: string;
  expiryMonth: number;
  expiryYear: number;
  last4: string;
  brand: string;
  default: boolean;
  name: string;
  postalCode: string;
}
