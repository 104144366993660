import { SafeResourceUrl } from '@angular/platform-browser';
import {
  ATVCategories,
  ATVDriveTypes,
  ATVStarters,
  MotorcycleCategory,
  MotorcycleDriveTypes,
} from '../config/listing-steps';
import { MapLocationModel } from './map-location.model';
import { IUser } from './user.model';

interface IAvailableTimingSlot {
  start: Date;
  end: Date;
}

interface IAvailableTimings {
  Monday?: IAvailableTimingSlot[];
  Tuesday?: IAvailableTimingSlot[];
  Wednesday?: IAvailableTimingSlot[];
  Thursday?: IAvailableTimingSlot[];
  Friday?: IAvailableTimingSlot[];
  Saturday?: IAvailableTimingSlot[];
  Sunday?: IAvailableTimingSlot[];
}

interface PayLaterData {
  enabled: boolean;
  dealNowPayer?: string;
  amount: number;
  discount: number;
  customer: string;
  paymentMethod?: string;
}

export enum UploadedVideoTypes {
  YouTube = 'youtube',
  Custom = 'custom',
}

export enum OwnershipTypes {
  Owned = 'owned',
  Financed = 'financed',
  Leased = 'leased',
}

export enum TitlePossessions {
  Lost = 'lost',
  Held = 'held',
  External = 'external',
}

export enum ISellerListingStatuses {
  Live = 'live',
  Pending = 'pending',
  Sold = 'sold',
  Removed = 'removed',
  Purchased = 'purchased',
  Deactivated = 'deactivated',
  Expired = 'expired',
  ExpiredGrace = 'expiredGrace',
}

export enum ListingCategoryTypes {
  Auto = 'Auto',
  ATVOHV = 'ATV/OHV',
  Motorcycle = 'Motorcycle',
  Boat = 'Boat',
  RV = 'RV',
  Trailer = 'Trailer',
}

export enum ISellerListingDisplayTypes {
  Public = 'public',
  Private = 'private',
}

export interface ISellerListingTitleAttachmentFile {
  _id: string;
  key: string;
  mimetype: string;
  filename: string;
  size: number;
  sharedDeals: string[];
  sharedChats: string[];
}

export interface ISellerListingTitleAttachments {
  completedDate: Date;
  front: ISellerListingTitleAttachmentFile;
  back: ISellerListingTitleAttachmentFile;
}

export enum ISellerListingWorkflowTypes {
  Standard = 'standard',
  Auction = 'auction',
}

export interface ISellerListingWorkflowHistory {
  type: ISellerListingWorkflowTypes;
  createdAt: Date;
  updatedAt: Date;
}

export interface ISellerListingWorkflow {
  type: ISellerListingWorkflowTypes;
  history: ISellerListingWorkflowHistory[];
}

export enum ISellerListingAuctionTypes {
  Pending = 'pending',
  Live = 'live',
  Expired = 'expired',
}

export interface ISellerListingAuction {
  status: ISellerListingAuctionTypes;
  biddingStartAt: Date;
  biddingEndAt: Date;
  duration: number;
  reserve: number;
  dealId: string;
  createdAt: Date;
  updatedAt: Date;
}

export type ISellerListingCondition = 'excellent' | 'good' | 'fair';
export type IListingStepsNames = 'step-one' | 'step-two' | 'step-three' | 'step-four' | 'step-five' | 'step-six';

export interface ISellerListingUploadImage {
  primaryStatus: boolean;
  images: string;
  order?: number;
}

export interface ISellerListingUploadVideo {
  type: UploadedVideoTypes;
  url: SafeResourceUrl;
  createdAt: Date;
  updatedAt: Date;
  meta?: any;
  order?: number;
}

interface ISellerListing {
  listingNo: string;
  _id: string;
  slug: string;
  status: ISellerListingStatuses;
  dateStatusLastChanged: Date;
  userId: string;
  isVintageVehicle: boolean;
  CarMake: string;
  CarModel: string;
  RegistrationYear: number;
  BodyStyle: string;
  Transmission: string;
  Trim: string;
  ExteriorColor: string;
  InteriorColor: string;
  DriveType: string;
  Doors: string;
  stateRegistered: string;
  vehicleType: ListingCategoryTypes;
  Liters: string;
  Cylinders: string;
  hasDamage: boolean;
  description: string;
  Mileage: number;
  Price: number;
  Fuel: string;
  TitleType: string;
  licencePlate: string;
  vinNumber: string;
  sellerDisclosure: string;
  Engine: string;
  listingLiveStartDate?: Date;
  uploadImages: ISellerListingUploadImage[];
  condition: ISellerListingCondition;
  referralPartner?: string;

  uploadVideos?: ISellerListingUploadVideo[];
  uploadFiles: any;
  hasPendingDeal?: boolean;
  createdAt: Date;
  listingLocation?: MapLocationModel;
  listingAnalytics: {
    listingViews: number;
    favoriteCount: number;
    offerCount: number;
    testDriveCount: number;
  };
  testDriveLocation?: MapLocationModel;
  documentsToSign: any;
  Zip: string;
  dealPreferences: {
    isChanged: boolean;
    verifications: {
      fullVerification: boolean;
      verifiedFunds: boolean;
    };

    paymentMethod: {
      privateAutoPay: boolean;
      cash: boolean;
      crypto: {
        btc: boolean;
      };
    };
  };

  high_value_features: any[];
  installed_options: any[];
  availableTimings: IAvailableTimings;
  ownershipInfo: {
    vehicleHaveLien: boolean;
    ownershipType: OwnershipTypes;
    titlePossession: TitlePossessions;
    carTitle: boolean;
    bankName: string;
    phoneNumber: string;
    loanAccountNumber: string;
    estimatedLoanAmount: string;
    coSeller: {
      onLoan: boolean;
      firstName: string;
      lastName: string;
      phoneNumber: string;
    };
    entity: {
      isOwnedByEntity: boolean;
      entityName: string;
      isAuthorizedSigner: boolean;
      signerTitle: string;
    };
  };

  originalSourceInfo?: {
    sourceSite: string;
    source_vdp: string;
    last_seen: Date;
    dealer_id: string;
  };
  vehicleDescription: string;
  payment: { date: string; id: string };
  soldAt?: string;
  // Set in the front-end only

  markedAsSold?: boolean;
  markedAsSoldInfo?: { soldPrice?: number; paymentMethods?: string[] };
  diff90?: number;
  diff120?: number;
  diff75?: number;
  mainImg: string;

  stepOneComplete?: boolean;
  stepTwoComplete?: boolean;
  stepThreeComplete?: boolean;
  stepFourComplete?: boolean;
  stepFiveComplete?: boolean;
  stepSixComplete?: boolean;
  mpg?: string;

  payLater?: PayLaterData;
  dealNowPayLater?: PayLaterData;

  titleAttachment: ISellerListingTitleAttachments;
  displayType?: ISellerListingDisplayTypes;
  originalSource?: string;
  additionalDetails: {
    motorcycle?: {
      driveType: MotorcycleDriveTypes;
      category: MotorcycleCategory;
      primaryColor: string;
    };
    atv?: {
      driveType: ATVDriveTypes;
      category: ATVCategories;
      starter: ATVStarters;
      weight: string;
      primaryColor: string;
    };
    boat?: {
      hours?: number;
      registrationNumber: string;
    };
  };

  workflow: ISellerListingWorkflow;

  auction: {
    active: ISellerListingAuction;
    history: ISellerListingAuction[];
  };

  contact?: {
    phone: boolean;
    location: boolean;
  };
  organizationId?: string
}

export interface ISellerListingWithUser extends Omit<ISellerListing, 'userId'> {
  user: IUser;
}

interface IFavItem {
  createdAt: string;
  listingId: ISellerListingWithUser;
  _id?: string;
}

interface ISelectableVehicle {
  image: string;
  _id: string;
  vin: string;
  name: string;
  userId: string;
  user: IUser;
  vehicleType?: ListingCategoryTypes;
  listingLocation: MapLocationModel;
  dealPreferences: {
    verifications: {
      fullVerification: boolean;
      verifiedFunds: boolean;
    };
  };
}

export { ISellerListing, IAvailableTimingSlot, IAvailableTimings, IFavItem, ISelectableVehicle };
