<div>
  <div class="subtitle">Check your phone</div>
  <div class="description mt-2 mb-3">We need to verify your mobile carrier information</div>
  <div class="d-flex justify-content-center w-100">
    <svg-icon src="/assets/verification/phone.svg"></svg-icon>
  </div>
  <div class="description mt-3">A text message with a link was just sent</div>
  <div class="description">to the phone ending in {{phoneEnding}}</div>
  <div class="d-flex justify-content-center w-100 mt-4">
    <a (click)="onSkipToManualEntry.emit()" class="manual-flow-link">I didn't receive a text</a>
  </div>
</div>