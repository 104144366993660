import { Injectable, Inject } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { APP_CONFIG, IAppConfig } from 'src/app/config/config';

@Injectable({
  providedIn: 'root',
})
export class PdfService {
  constructor(private readonly http: HttpClient, @Inject(APP_CONFIG) private readonly config: IAppConfig) {}

  getPdf(html, filename) {
    return this.http.post(
      `${this.config.pdfProxyUrl}?filename=${filename}`,
      { html },
      { observe: 'response', responseType: 'blob', headers: { 'content-length': html.length } }
    );
  }

  getPdfByUrl(url, filename) {
    return this.http.get(`${this.config.pdfProxyUrl}?url=${encodeURIComponent(url)}&filename=${filename}`, {
      observe: 'response',
      responseType: 'blob',
    });
  }
}
